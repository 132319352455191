export const actions = {
  SET_SELECTED_FOLDER: 'SET_SELECTED_FOLDER',
  SET_PROJECTS: 'SET_PROJECTS',
  ADD_PROJECT: 'ADD_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
};

export const setSelectedFolder = (data) => ({
  type: actions.SET_SELECTED_FOLDER,
  data,
});

export const setProjects = (data) => ({
  type: actions.SET_PROJECTS,
  data,
});

export const addProject = (data) => ({
  type: actions.ADD_PROJECT,
  data,
});

export const deleteProject = (data) => ({
  type: actions.DELETE_PROJECT,
  data,
});
