import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'next-themes';
import CustomButton from '@components/Button/Button';
import {
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@components/Modal/Modal';

function ConfirmationModal({
  title = 'Confirmation',
  bodyText,
  bodyComponent,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  close,
  confirmButtonColor,
  confirmButtonLoading,
  cancelButtonProps,
  confirmButtonProps,
}) {
  const { theme } = useTheme();
  const formRef = React.useRef(null);

  useEffect(() => {
    // setTimeout(() => {
    //   if (formRef.current) {
    //     const event = new Event('submit', { bubbles: true, cancelable: true });
    //     formRef.current.dispatchEvent(event);
    //   }
    // }, 500);
  }, []);

  return (
    <Modal
      bodyStyle={{
        padding: '0px',
        paddingBottom: '8px',
      }}
      paperStyle={{
        width: '416px',
      }}
      open
      setOpen={close}
      titleHeading={title}
    >
      <form
        id='confirmation-modal-form'
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          if (onConfirm) {
            onConfirm();
          }
        }}
      >
        <div className='text-custom-font-color dark:text-white flex align-center justify-center flex-col'>
          {bodyComponent || (
            <div style={{ marginBottom: '12px' }}>{bodyText}</div>
          )}

          <div className='flex align-center justify-end gap-4 mt-[16px]'>
            {(cancelText || onCancel) && (
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  if (onCancel) {
                    onCancel();
                  }

                  close();

                  // placeCursor();
                }}
                className='h-[42px] font-semibold'
                style={{
                  width: '86px',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: "'Montserrat', sans-serif",
                  color: '#2499E7',
                  textTransform: 'capitalize',
                  ...cancelButtonProps?.style,
                }}
                variant='outlined'
                {...cancelButtonProps}
              >
                {cancelText || 'Cancel'}
              </CustomButton>
            )}
            {(confirmText || onConfirm) && (
              <CustomButton
                className='h-[42px] font-semibold'
                style={{
                  minWidth: '86px',
                  color: '#fff',
                  background: confirmButtonColor || '#2499E7',
                  padding: '0 16px',
                  width: '100%',
                  ...confirmButtonProps?.style,
                }}
                wrapperClasses='w-full'
                type='submit'
                {...confirmButtonProps}
              >
                {confirmButtonLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  confirmText || 'Confirm'
                )}
              </CustomButton>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  bodyText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  close: PropTypes.func,
  bodyComponent: PropTypes.node,
  confirmButtonColor: PropTypes.string,
  confirmButtonLoading: PropTypes.bool,
  confirmButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
};
