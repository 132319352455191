import { actions } from '@redux/actions/editor';

const initialState = {
  editorDoc: null,
  isColorblind: false,
  isDyslexia: false,
  docLanguage: '',
  isGrammarlyEnabled: false,
  showAiWriterTutorial: false,
  showAiSeoTutorial: false,
  isMainSidebarOpen: false,
};

const editorReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_EDITOR:
      return { ...state, module: data };
    case actions.REMOVE_EDITOR:
      return { ...state, module: null };
    case actions.SET_EDITOR_DOC:
      return { ...state, editorDoc: data };
    case actions.SET_IS_COLORBLIND:
      return { ...state, isColorblind: data };
    case actions.SET_IS_DYSLEXIA:
      return { ...state, isDyslexia: data };
    case actions.SET_DOC_LANGUAGE:
      return { ...state, docLanguage: data };
    case actions.ENABLE_GRAMMARLY:
      return {
        ...state,
        isGrammarlyEnabled:
          data === undefined ? !state.isGrammarlyEnabled : data,
      };
    case actions.SET_SHOW_AI_WRITER_TUTORIAL:
      return { ...state, showAiWriterTutorial: data };
    case actions.SET_SHOW_AI_SEO_TUTORIAL:
      return { ...state, showAiSeoTutorial: data };
    case actions.SET_IS_MAIN_SIDEBAR_OPEN:
      return {
        ...state,
        isMainSidebarOpen: data === undefined ? !state.isMainSidebarOpen : data,
      };
    default:
      return state;
  }
};

export default editorReducer;
