import { request } from '@shared/verb';
// import apiService from '@config/api.service';
import {
  setAuthStatus,
  setSelectedAvatarOption,
  setLoggedUser,
  setTeam,
  setUserStats,
  setUserAccounts,
  setTeamMembers,
} from '@redux/actions/user';
import { getUserTeamDetail, getUserTeams } from '@redux/services/teams';
import { connectMqtt, subscribeMqtt } from '@redux/services/mqtt';

import {
  setDataInLocalStorage,
  removeDataFromLocalStorage,
  getDataFromLocalStorage,
} from '@helpers/browser_storage';
import {
  identifyUser,
  identifyTeam,
  initializePosthog,
} from '@services/analytics';
import {
  setContentPlannerPricing,
  setDataFetchedFrom,
  setAdditionalSeatsAndKWPricing,
} from '@redux/actions/general';
import { identifyUserHubspot, removeQueryParams } from '@helpers/utils';
import { setLoading } from '@redux/actions/paymentMethod';
import { getStorageKeys } from '@editor/components/InkAddon/helper';
import { setSelectedFolder } from '@redux/actions/project';
import { getAllPaymentMethods } from './paymentMethod';
import {
  setPricingForContentPlanner,
  getAdditionalSeatsAndKWPricing,
} from './general';
// import { getAllPaymentMethods } from './paymentMethod';
// import { getPlans } from './general';

const setUserStateForLogs = (user, selectedOption) => {
  if (typeof window === `undefined`) return;
  identifyUser(user);
  if (!['personal'].includes(selectedOption?.accountType)) {
    identifyTeam(selectedOption?._id);
  }
};

export const logout =
  (params = { inputFields: true, selectedTool: true }) =>
  (dispatch) => {
    removeDataFromLocalStorage('idToken');
    removeDataFromLocalStorage('accessToken');
    removeDataFromLocalStorage('refreshToken');
    removeDataFromLocalStorage('user');
    removeDataFromLocalStorage('userAccounts');
    removeDataFromLocalStorage('avatarSelectedData');
    removeDataFromLocalStorage('showExtPopup');
    dispatch(setSelectedFolder(''));
    getStorageKeys()
      .then((keys) => {
        for (const key in keys) {
          removeDataFromLocalStorage(keys?.[key]);
        }
      })
      .finally(() => {
        for (const key in params) {
          if (params?.[key]) {
            removeDataFromLocalStorage(key);
          }
        }
        dispatch({ type: 'LOGOUT' });
      });
  };

export const setLocalStorageTokens = (authTokens) => {
  setDataInLocalStorage('refreshToken', authTokens.refresh_token);
  setDataInLocalStorage('idToken', authTokens.id_token);
  setDataInLocalStorage('accessToken', authTokens.access_token);
  setDataInLocalStorage(
    'tokenExpiresAt',
    Date.now() + authTokens.expires_in * 1000
  );
};

export const authenticate =
  (code, sessionId = false) =>
  (dispatch) =>
    request(
      `/v2.0/auth/token?code=${code}${
        sessionId ? `&sessionId=${sessionId}` : ''
      }`,
      null,
      'get',
      true,
      null
    )
      .then((res) => {
        const { authTokens = '' } = res?.data?.data || {};

        if (authTokens) {
          setLocalStorageTokens(authTokens);
        }

        return res?.data;
      })
      .catch(() => {
        dispatch(logout());
        dispatch(setAuthStatus('undecided'));
      });

export const verifyMe = () => (dispatch) =>
  request('/v2.0/users/me', null, 'get', null, null).then(
    ({ data }) => {
      if (data) {
        if (Object.keys(data?.data || {}).length) {
          dispatch(setLoggedUser(data.data));
          // dispatch(setAuthStatus('authenticated'));
          localStorage.setItem('user', JSON.stringify(data.data));
        }
      }
      return data;
    },
    (err) => {
      dispatch(logout());
      dispatch(setAuthStatus('undecided'));
      return err;
    }
  );

const handleMqttSubscribe = (data) => {
  const { _id: id, accountType } = data;
  if (accountType === 'personal') {
    subscribeMqtt(`userUpdate_${id}`);
  } else {
    subscribeMqtt(`inkTeam_${id}`);
  }
};

const setupData = (data) => {
  // handleMqttSubscribe(data);
};

export const getStats = (payload) => async (dispatch) => {
  const response = await request(
    `/v2.0/users/dashboard-stats`,
    payload,
    'post'
  );
  dispatch(setUserStats(response.data.data));
  return response;
};
export const updateStripeCustomer = async (data) => {
  const response = await request(
    `/v2.0/users/update-stripe-customer`,
    data,
    'put'
  );
  return response;
};

export const setPricing = async (dispatch) => {
  const pricing = await setPricingForContentPlanner();
  const additionalSeatsAndKWPrice = await getAdditionalSeatsAndKWPricing();
  dispatch(setContentPlannerPricing(pricing));
  dispatch(setAdditionalSeatsAndKWPricing(additionalSeatsAndKWPrice));
};

export const getUserDetails =
  (getDataFromLC = false) =>
  async (dispatch) => {
    console.log('getUserDetails');
    let success = false;
    let userInfo = {};
    const LCUser = getDataFromLocalStorage('user');
    const LCSelectedAvatar = getDataFromLocalStorage('avatarSelectedData');
    const LCUserAccounts = getDataFromLocalStorage('userAccounts');
    if (getDataFromLC && LCUser && LCSelectedAvatar && LCUserAccounts) {
      userInfo = { ...LCUser };
      success = true;
      setUserStateForLogs(userInfo, LCSelectedAvatar);
      dispatch(setLoggedUser({ ...userInfo }));
      dispatch(setSelectedAvatarOption({ ...LCSelectedAvatar }));
      dispatch(setUserAccounts(LCUserAccounts));
      dispatch(setAuthStatus('authenticated'));
      dispatch(setDataFetchedFrom('LC'));
    }
    try {
      const response = await dispatch(verifyMe());
      success = response?.success;
      userInfo = response?.data;

      if (!userInfo || !success) {
        return;
      }
      if (success) {
        const userData = { ...userInfo };
        if (userData['custom:newSignup'] === '1') {
          userData['custom:newSignup'] = '0';
        }
        // dispatch(setLoggedUser(userInfo));

        const { mqttUrl } = userInfo;
        connectMqtt(mqttUrl);
        // if (getDataFromLocalStorage('generateAttempts')) {
        //   setGenerateAttempts(getDataFromLocalStorage('generateAttempts'));
        // }
        // dispatch(getAllPaymentMethods());
        // dispatch(getPlans());
        removeQueryParams('?token');
        dispatch(setAuthStatus('authenticated'));
        setPricing(dispatch);
        initializePosthog(userInfo);
        identifyUserHubspot(userInfo);
        const { status, data: teamResponse } = await getUserTeams(
          'wallet,plan,userRole,subscription'
        );
        // await dispatch(getUserStats(userInfo?._id,'all-time'));
        if (status) {
          // const data = getDataFromLocalStorage('avatarSelectedData');
          // if (data) {
          //   if (teamResponse?.length > 0) {
          //     let currentSelectedAvatarOption = teamResponse?.find(
          //       o => o._id === data._id,
          //     );
          //     if (!currentSelectedAvatarOption) {
          //       currentSelectedAvatarOption = userInfo;
          //     }
          //     data.wallet = currentSelectedAvatarOption.wallet;
          //   }
          //   // handleMqttSubscribe(data);
          //   dispatch(setSelectedAvatarOption(data));
          // }
          let accountData;
          // const userWallet = getDataFromLocalStorage('avatarSelectedData');
          if (teamResponse.length) {
            const teamOwner = teamResponse?.find(
              (o) => o.owner._id === userInfo._id
            );
            if (teamOwner) {
              accountData = {
                accountType: 'personal-team',
                preference: 1,
                ...teamOwner,
              };
              setupData(accountData);
            } else {
              accountData = {
                accountType: 'personal',
                title: userInfo.name,
                userPlan: userInfo.userPlan,
                _id: userInfo._id,
                preference: 3,
                // wallet: userWallet?.wallet ?? userInfo.wallet,
                topupDetails: userInfo?.topupDetails,
                wallet: userInfo.wallet,
                picture: userInfo.picture,
                createdAt: userInfo?.registeredOn,
              };
              setupData(accountData);
            }
          } else {
            accountData = {
              accountType: 'personal',
              title: userInfo.name,
              userPlan: userInfo.userPlan,
              _id: userInfo._id,
              preference: 3,
              // wallet: userWallet?.wallet ?? userInfo.wallet,
              topupDetails: userInfo?.topupDetails,
              wallet: userInfo.wallet,
              picture: userInfo.picture,
              createdAt: userInfo?.registeredOn,
            };
            setupData(accountData);
          }
          const teams = teamResponse?.map((team) => {
            if (
              userInfo?._id !== team?.owner._id &&
              team?.role !== 'administrator'
            ) {
              return { accountType: 'team', preference: 2, ...team };
            }
            return {
              accountType: 'personal-team',
              preference: 1,
              ...team,
            };
          });
          setUserStateForLogs(userInfo, accountData);

          // if (['personal-team', 'team'].includes(accountData.accountType)) {
          //   await dispatch(getStats({ teamId: accountData._id }));
          // } else {
          //   await dispatch(getStats({ userId: accountData._id }));
          // }
          dispatch(setTeam(teams));
          let userAccounts;
          const selectedOptionExists = teams.find(
            (team) =>
              // return team.title === accountData.title;
              team.owner._id === userInfo._id
          );
          if (selectedOptionExists) {
            dispatch(setUserAccounts(teams));
            userAccounts = teams;
            setDataInLocalStorage('userAccounts', teams);
          } else {
            dispatch(setUserAccounts([accountData, ...teams]));
            userAccounts = [accountData, ...teams];
            setDataInLocalStorage('userAccounts', [accountData, ...teams]);
          }
          const sortedAccounts = userAccounts?.sort(
            (a, b) => a.preference - b.preference
          );
          const teamCode = new URLSearchParams(window.location.search).get(
            'teamCode'
          );
          let selectedAccount;
          if (teamCode) {
            const newTeam = teams?.find(({ _id }) => _id === teamCode);
            if (newTeam) {
              selectedAccount = newTeam;
              dispatch(setSelectedAvatarOption(newTeam));
              setDataInLocalStorage('avatarSelectedData', newTeam);
            }
            removeQueryParams('?teamCode');
          } else if (LCSelectedAvatar) {
            const matchedAccount = userAccounts?.find(
              ({ _id }) => _id === LCSelectedAvatar?._id
            );
            if (matchedAccount) {
              selectedAccount = matchedAccount;
              dispatch(setSelectedAvatarOption(matchedAccount));
            } else {
              selectedAccount = sortedAccounts?.[0];
              dispatch(setSelectedAvatarOption(sortedAccounts?.[0]));
              setDataInLocalStorage('avatarSelectedData', sortedAccounts?.[0]);
            }
          } else {
            selectedAccount = sortedAccounts?.[0];
            dispatch(setSelectedAvatarOption(sortedAccounts?.[0]));
            setDataInLocalStorage('avatarSelectedData', sortedAccounts?.[0]);
          }
          if (selectedAccount?.accountType !== 'personal') {
            const {
              data: { data },
            } = await getUserTeamDetail(selectedAccount?._id);
            const members = [
              ...data?.members?.map((member) => ({
                id: member?.user?._id,
                name: member?.user?.name,
                mine: userInfo?._id === member?.user?._id,
              })),
              {
                id: data?.owner?._id,
                name: data?.owner?.name,
                mine: userInfo?._id === data?.owner?._id,
              },
            ];
            dispatch(setTeamMembers(members));
          }

          dispatch(setDataFetchedFrom('API'));
          dispatch(getAllPaymentMethods()).then(() =>
            dispatch(setLoading(false))
          );
        }
      } else {
        dispatch(setAuthStatus('undecided'));
        // setloginModalVisible(true);
      }
      return response;
    } catch (err) {
      console.log('auth catch', err);
      if (
        err.message === `Cannot read properties of undefined (reading 'push')`
      ) {
        // console.log('this is a hubspot window issue');
      } else {
        // console.log('this is a hubspot window issue');
        dispatch(setAuthStatus('undecided'));
      }
      // setloginModalVisible(true);
    }
  };

export const getUserStats = (userId, filterBy) => (dispatch) =>
  request(`/v2.0/user-stats/${userId}?filterBy=${filterBy}`, null, 'get').then(
    (res) => {
      dispatch(setUserStats(res.data.data));
      return res;
    }
  );

export const presignedUrl = () =>
  request('/users/presigned-url', null, 'get', null, null).then(
    ({ data }) => data,
    (err) => err
  );

export const postAuthSessionData = (header) =>
  request('/v2.0/auth/session/publish-data', header, 'post').then(
    ({ data }) => data,
    (err) => err
  );

export const verifyMePaymentGateway = (loggedUser) => (dispatch) =>
  request('/v2.0/users/me', null, 'get', null, null, false).then(
    ({ data }) => {
      dispatch(setLoggedUser({ ...loggedUser, ...data.data }));
      return data;
    },
    (err) => err
  );

export const callProratedAmountAPI = (subId, planId) =>
  request(
    `/subscriptions/${subId}/prorate?planId=${planId}`,
    null,
    'get',
    null,
    null,
    false
  ).then(
    ({ data }) => data,
    (err) => err
  );
