import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { isEditorPage } from '@editor/helpers/';

export default function LoadingScreen({
  color = '#e45c96',
  size = 60,
  thickness = 4,
}) {
  const isColorblind = useSelector((state) => state.editor?.isColorblind);

  const boxStyles = {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (isEditorPage() && isColorblind) {
    boxStyles.backgroundColor = 'var(--page-bg)';
  }

  return (
    <Box sx={{ ...boxStyles }}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant='determinate'
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={size}
          thickness={thickness}
          value={100}
        />
        <CircularProgress
          variant='indeterminate'
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === 'light' ? color : `${color}1A`,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={thickness}
        />
      </Box>
    </Box>
  );
}

LoadingScreen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
};
