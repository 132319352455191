import { combineReducers } from 'redux';
import user from './user';
import paymentMethod from './paymentMethod';
import paymentHistory from './paymentHistory';
import general from './general';
import editor from './editor';
import inkAddon from './inkAddon';
import keywords from './keywords';
import project from './project';

// combining multiple reducers to create one rootReducer
const reducers = combineReducers({
  inkAddon,
  general,
  user,
  paymentMethod,
  paymentHistory,
  editor,
  keywords,
  project,
});

export default reducers;
