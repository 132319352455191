import React, { useEffect, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setCustomAlert } from '@redux/actions/general';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line react/display-name
const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

function AlertBar() {
  const { snackbar } = useSelector((gState) => gState.general);
  const dispatch = useDispatch();
  const positionPlacement = snackbar.position.split('-');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setCustomAlert({ ...snackbar, state: false }));
  };

  useEffect(() => {
    let ref;
    if (snackbar.state === true) {
      ref = setTimeout(() => {
        dispatch(setCustomAlert({ ...snackbar, state: false }));
      }, snackbar.timeInterval || 3000);
    }
    return () => clearTimeout(ref);
  }, [snackbar.state]); // eslint-disable-line

  return (
    <Snackbar
      anchorOrigin={{
        vertical: positionPlacement[0],
        horizontal: positionPlacement[1],
      }}
      open={snackbar.state}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.type}
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}

export default AlertBar;
