/*
	AUTHOR: SARMED RIZVI
   SUMMARY: USE HOOK USE FOR CALLING API.
*/

import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react';
import { request } from '@shared/verb';
import { setCustomAlert } from '@redux/actions/general';

const useMutate = (path, options) => {
  const {
    type = 'POST',
    request: customRequest,
    v2 = false,
    onSuccess: onPropSuccess,
    onError: onPropError,
  } = options || {};
  // WHEN API GOT SOME ERROR
  const [error, setError] = React.useState(false);
  const [response, setResponse] = useState();

  //* THE LATEST REF PATTERN (to avoid infinite loop because callbacks are being recreated on every render)
  /**
   The problem is that that callback is recreated on every render, so the effect is run on every render 
   (for example when you use to "mutate" in the useEffect, it will run on every render)
   . This is why you need to use the useRef hook to store the callback and only update it when the callback changes 
   (useRef doesn't cause a re-render)
   This pattern is called the latest ref pattern and it's used in the useCallback hook as well
   */
  const onPropSuccessRef = React.useRef(onPropSuccess);
  const onPropErrorRef = React.useRef(onPropError);
  const requestRef = React.useRef(customRequest);
  useLayoutEffect(() => {
    onPropSuccessRef.current = onPropSuccess;
    onPropErrorRef.current = onPropError;
    requestRef.current = customRequest;
  }, [onPropSuccess, onPropError, customRequest]);

  // ISLOADING PARAMETER FOR API
  const [loading, setLoading] = React.useState(false);

  const mutate = useCallback(
    async (values, props) => {
      console.log('values', values);
      const { onSuccess, onError } = props || {};
      setLoading(true);
      const pathReq = requestRef.current || request;
      try {
        const data = await pathReq(path, values, type, '', '', '', '', '', v2);
        if (data.data) setResponse(data.data);
        if (onPropSuccessRef.current) onPropSuccessRef.current(data.data);
        if (onSuccess) {
          onSuccess(data.data);
        }
        return data;
      } catch (error) {
        console.log('HAS ERROR');
        setError(true);
        if (onError) onError(error);
        setResponse(error);
        if (onPropErrorRef.current) onPropErrorRef.current(error);

        return error;
      } finally {
        setLoading(false);
      }
    },
    [path, type, v2]
  );

  return {
    mutate,
    isLoading: loading,
    isError: error,
    response,
  };
};

export default useMutate;
