import { actions } from '@redux/actions/user';

const initialState = {
  authStatus: 'deciding',
  loggedUser: null,
  avatarOptions: null,
  selectedAvatarOption: null,
  team: [],
  teamMembers: [],
  userStats: null,
  userAccounts: [],
  savedResults: [],
  copiedResults: [],
  copiedSavedResults: [],
  customPlanAssigned: [],
  authRefreshNonce: '',
};

const user = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_AUTH_STATUS:
      return { ...state, authStatus: data };
    case actions.SET_LOGGED_USER:
      return { ...state, loggedUser: data };
    case actions.SET_AVATAR_OPTIONS:
      return { ...state, avatarOptions: data };
    case actions.SET_SELECTED_AVATAR_OPTION:
      return { ...state, selectedAvatarOption: data };
    case actions.SET_USER_STATS:
      return { ...state, userStats: data };
    case actions.SET_TEAM:
      return { ...state, team: data };
    case actions.SET_TEAM_MEMBERS:
      return { ...state, teamMembers: data };
    case actions.SET_USER_ACCOUNTS:
      return { ...state, userAccounts: data };
    case actions.SET_SAVED_RESULTS:
      return { ...state, savedResults: data };
    case actions.SET_COPIED_RESULTS:
      return { ...state, copiedResults: data };
    case actions.SET_COPIED_SAVED_RESULTS:
      return { ...state, copiedSavedResults: data };
    case actions.SET_CUSTOM_PLAN_ASSIGNED:
      return { ...state, customPlanAssigned: data };
    case actions.SET_AUTH_REFRESH_NONCE:
      return { ...state, authRefreshNonce: data };
    case 'LOGOUT':
      return { ...initialState, authStatus: 'undecided' };
    default:
      return state;
  }
};

export default user;
