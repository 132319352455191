import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from 'next-themes';
import { IsMobileWidth } from '@components/util/MediaQueryUtils';

function BtnNewBig({ label, handleClick = () => {} }) {
  const { theme } = useTheme();
  const isMobileWidth = IsMobileWidth();

  return (
    <Box
      sx={{
        width: isMobileWidth ? 'calc(100% - 16px)' : '156px',
        height: isMobileWidth ? '175px' : '100%',
        background: `${theme === 'dark' ? '#252530' : '#ffffff'}`,
        border: `1px solid ${theme === 'dark' ? '#31313F' : '#EBEBEF'}`,
        borderRadius: '5px',
        marginLeft: isMobileWidth ? '16px' : '0',
        marginBottom: isMobileWidth ? '16px' : '0',
      }}
    >
      <Button
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            background: `${theme === 'dark' ? '#253142' : '#E9F5FD'}`,
            width: '64px',
            height: '64px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        >
          <img src='/assets/plus.svg' alt='new' />
        </Box>

        <Box
          sx={{
            display: 'block',
          }}
        >
          <Typography
            sx={{
              color: `${theme === 'dark' ? '#F5F5F5' : '#505876'}`,
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '17px',
            }}
          >
            {label}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}
BtnNewBig.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
};
export default BtnNewBig;
