export const actions = {
  SET_EDITOR: 'SET_EDITOR',
  REMOVE_EDITOR: 'REMOVE_EDITOR',
  SET_EDITOR_DOC: 'SET_EDITOR_DOC',
  SET_EDITOR_META: 'SET_EDITOR_META',
  SET_IS_COLORBLIND: 'SET_IS_COLORBLIND',
  SET_IS_DYSLEXIA: 'SET_IS_DYSLEXIA',
  SET_DOC_LANGUAGE: 'SET_LANGUAGE',
  ENABLE_GRAMMARLY: 'ENABLE_GRAMMARLY',
  SET_SHOW_AI_WRITER_TUTORIAL: 'SET_SHOW_AI_WRITER_TUTORIAL',
  SET_SHOW_AI_SEO_TUTORIAL: 'SET_SHOW_AI_SEO_TUTORIAL',
  SET_IS_MAIN_SIDEBAR_OPEN: 'SET_IS_MAIN_SIDEBAR_OPEN',
};

export const setEditorDoc = (data) => ({
  type: actions.SET_EDITOR_DOC,
  data,
});

export const setEditor = (data) => ({
  type: actions.SET_EDITOR,
  data,
});

export const setEditorMeta = (data) => ({
  type: actions.SET_EDITOR_META,
  data,
});

export const setIsColorblind = (data) => ({
  type: actions.SET_IS_COLORBLIND,
  data,
});

export const setIsDyslexia = (data) => ({
  type: actions.SET_IS_DYSLEXIA,
  data,
});

export const setDocLanguage = (data) => ({
  type: actions.SET_DOC_LANGUAGE,
  data,
});

export const enableGrammarly = (data) => ({
  type: actions.ENABLE_GRAMMARLY,
  data,
});
export const setShowAiWriterTutorial = (data) => ({
  type: actions.SET_SHOW_AI_WRITER_TUTORIAL,
  data,
});

export const setShowAiSeoTutorial = (data) => ({
  type: actions.SET_SHOW_AI_SEO_TUTORIAL,
  data,
});

export const setIsMainSidebarOpen = (data) => ({
  type: actions.SET_IS_MAIN_SIDEBAR_OPEN,
  data,
});
