export const actions = {
  SET_FILE_IMPORTED: 'SET_FILE_IMPORTED',
  SET_AI_WRITING_STATUS: 'SET_AI_WRITING_STATUS',
  SET_AI_WORDS_MODAL_STATUS: 'SET_AI_WORDS_MODAL_STATUS',
  SET_AI_HAS_LAST_ACTION: 'SET_AI_HAS_LAST_ACTION',
  SET_REWRITE_STATUS: 'SET_REWRITE_STATUS',
  SET_REWRITE_OPTION_SWITCHING: 'SET_REWRITE_OPTION_SWITCHING',
  SET_METADATA_CHANGED: 'SET_METADATA_CHANGED',
  SET_DOC_SAVED: 'SET_FILE_SAVE',
  SET_CONTENT_SHIELD_STATUS: 'SET_CONTENT_SHIELD_STATUS',

  SET_AI_TOOL_SIDEBAR_STATUS: 'SET_AI_TOOL_SIDEBAR_STATUS',
  RESET_COMPOSER_TEXT: 'RESET_COMPOSER_TEXT',
  SET_SEO_ADDON_DATA: 'SET_SEO_ADDON_DATA',
  SET_KEYPHRASE_VIEW_READY: 'SET_KEYPHRASE_VIEW_READY',

  SET_LEFT_SIDEBAR_OPEN: 'SET_LEFT_SIDEBAR_OPEN',
  SET_LEFT_INK_ADDON_READY: 'SET_LEFT_INK_ADDON_READY',
  SET_LEFT_INK_ADDON_FAILED: 'SET_LEFT_INK_ADDON_FAILED',
  SET_LEFT_INK_ADDON_FRAME: 'SET_LEFT_INK_ADDON_FRAME',
  SET_LEFT_ACTIVE_SIDEBAR: 'SET_LEFT_ACTIVE_SIDEBAR',

  SET_RIGHT_SIDEBAR_OPEN: 'SET_RIGHT_SIDEBAR_OPEN',
  SET_RIGHT_INK_ADDON_READY: 'SET_RIGHT_INK_ADDON_READY',
  SET_RIGHT_INK_ADDON_FAILED: 'SET_RIGHT_INK_ADDON_FAILED',
  SET_RIGHT_INK_ADDON_FRAME: 'SET_RIGHT_INK_ADDON_FRAME',
  SET_RIGHT_ACTIVE_SIDEBAR: 'SET_RIGHT_ACTIVE_SIDEBAR',
};

export const setRewriteStatus = (data) => ({
  type: actions.SET_REWRITE_STATUS,
  data,
});

export const setRewriteOptionSwitching = (data) => ({
  type: actions.SET_REWRITE_OPTION_SWITCHING,
  data,
});

export const setAiToolSidebarStatus = (data) => ({
  type: actions.SET_AI_TOOL_SIDEBAR_STATUS,
  data,
});

export const clearComposerText = (data) => ({
  type: actions.RESET_COMPOSER_TEXT,
  data,
});

export const setFileImported = (data) => ({
  type: actions.SET_FILE_IMPORTED,
  data,
});

export const setAiWritingStatus = (
  isAiWriting = false,
  hasLastAction = false,
  aiShieldValue = { aiShieldValue: 100, remaining: 0 }
) => ({
  type: actions.SET_AI_WRITING_STATUS,
  data: {
    isAiWriting,
    hasLastAction,
    aiShieldValue,
  },
});

export const setAiWordsModalStatus = (status = false) => ({
  type: actions.SET_AI_WORDS_MODAL_STATUS,
  data: status,
});

export const setAIHasLastActionStatus = (status = false) => ({
  type: actions.SET_AI_HAS_LAST_ACTION,
  data: status,
});

export const setMetadataChanged = (data) => ({
  type: actions.SET_METADATA_CHANGED,
  data,
});

export const setDocSaved = (data) => ({
  type: actions.SET_DOC_SAVED,
  data,
});

export const setContentShieldStatus = (data) => ({
  type: actions.SET_CONTENT_SHIELD_STATUS,
  data,
});

export const setSeoAddonData = (data) => ({
  type: actions.SET_SEO_ADDON_DATA,
  data,
});

export const setKeyphraseViewReady = (data) => ({
  type: actions.SET_KEYPHRASE_VIEW_READY,
  data,
});

// Left sidebar
export const setLeftSidebarOpen = (data) => ({
  type: actions.SET_LEFT_SIDEBAR_OPEN,
  data,
});

export const setLeftInkAddonReady = (data) => ({
  type: actions.SET_LEFT_INK_ADDON_READY,
  data,
});
export const setLeftInkAddonFailed = (data) => ({
  type: actions.SET_LEFT_INK_ADDON_FAILED,
  data,
});

export const setLeftInkAddonFrame = (data) => ({
  type: actions.SET_LEFT_INK_ADDON_FRAME,
  data,
});

export const setLeftActiveSidebar = (data) => ({
  type: actions.SET_LEFT_ACTIVE_SIDEBAR,
  data,
});

// Right Sidebar
export const setRightSidebarOpen = (data) => ({
  type: actions.SET_RIGHT_SIDEBAR_OPEN,
  data,
});

export const setRightInkAddonReady = (data) => ({
  type: actions.SET_RIGHT_INK_ADDON_READY,
  data,
});
export const setRightInkAddonFailed = (data) => ({
  type: actions.SET_RIGHT_INK_ADDON_FAILED,
  data,
});

export const setRightInkAddonFrame = (data) => ({
  type: actions.SET_RIGHT_INK_ADDON_FRAME,
  data,
});

export const setRightActiveSidebar = (data) => ({
  type: actions.SET_RIGHT_ACTIVE_SIDEBAR,
  data,
});
