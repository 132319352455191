import { request } from '@shared/verb';

export const getUserTeams = (queryparams) => {
  let url;
  if (queryparams) {
    url = `/v2.0/users/me/teams?scope=${queryparams}`;
  } else {
    url = '/v2.0/users/me/teams';
  }
  return request(url, null, 'get', null, null).then(
    ({ data }) => data,
    (err) => {
      console.error(err);
      return err;
    }
  );
};

export const invitationCheck = (data, teamID) => {
  const { email, count } = data;
  return request(
    `/v2.0/invitations/check`,
    { email, count },
    'post',
    null,
    null,
    teamID
  ).then((res) => res);
};

export const getUserTeamDetail = (id) =>
  request(
    `/v2.0/ink-teams/${id}?scope=members,invitations,plan`,
    null,
    'get'
  ).then((res) => res);

export const sendEmailInvite = (data, teamID) =>
  request(
    `/v2.0/invitations/team/multiple`,
    data,
    'post',
    null,
    null,
    teamID
  ).then((res) => res);

export const sendEmailInviteToOneUser = (data) =>
  request(`/v2.0/invitations/team`, data, 'post').then((res) => res);

export const resendEmailInvite = (data, teamId) =>
  request(
    `/v2.0/invitations/${data}/resend?teamid=${teamId}`,
    null,
    'get'
  ).then((res) => res);

export const deleteMembers = (data, teamId) =>
  request(`/v2.0/ink-teams/${teamId}/members/${data}`, null, 'delete').then(
    (res) => res
  );

export const deleteInvite = (data, teamId) =>
  request(`/v2.0/invitations/${data}?teamid=${teamId}`, null, 'delete').then(
    (res) => res
  );

export const updateTeamName = (data, teamId) =>
  request(
    `/v2.0/ink-teams/${teamId}`,
    { title: data },
    'put',
    null,
    null,
    teamId
  ).then((res) => res);

export const additionalSeats = (data, teamId) =>
  request(`/v2.0/ink-teams/${teamId}/add-additional-seats`, data, 'post').then(
    (res) => res
  );
export const removeAdditionalSeats = (data, teamId) =>
  request(
    `/v2.0/ink-teams/${teamId}/remove-additional-seats`,
    data,
    'post'
  ).then((res) => res);

export const assignRole = (data, teamId, memberId) =>
  request(
    `/v2.0/ink-teams/${teamId}/member/${memberId}/role`,
    data,
    'put'
  ).then((res) => res);

export const createNewTeam = (data) =>
  request('/v2.0/ink-teams', { title: data }, 'post').then((res) => res);
