const mqtt = require('mqtt');

let client = null;

const options = {
  // Clean session
  clean: true,
};

export const connectMqtt = (url) => {
  client = mqtt.connect(url, options);
};

export const subscribeMqtt = (topic) => {
  client.subscribe(topic, (err) => {
    if (!err) {
      client.publish('presence', 'Hello mqtt');
    }
    console.log('err occurred', err);
  });
};

export const getClient = () => client;
