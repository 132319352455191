export const actions = {
  SET_ADD_PAYMENT_MODAL: 'SET_ADD_PAYMENT_MODAL',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  SET_LOADING: 'SET_LOADING',
};

export const setAddPaymentModal = (data) => ({
  type: 'SET_ADD_PAYMENT_MODAL',
  data,
});

export const setAllPaymentMethods = (data) => ({
  type: 'SET_PAYMENT_METHODS',
  data,
});

export const setLoading = (data) => ({
  type: 'SET_LOADING',
  data,
});
