export const setDataInLocalStorage = (key, data, primitive = false) => {
  if (!primitive) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
};

export const getDataFromLocalStorage = (key, primitive = false) => {
  if (!primitive) {
    return JSON.parse(localStorage.getItem(key));
  }
  return localStorage.getItem(key);
};

export const removeDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const setDataInSessionStorage = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromSessionStorage = (key) =>
  JSON.parse(sessionStorage.getItem(key));

export const removeDataFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};
