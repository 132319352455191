import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'next-themes';

import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';

import { SectionTitle } from '@library/components/Atoms';
import CustomButton from '@components/Button/Button';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';

function CustomDataGrid({
  title = '',
  rows = [],
  columns = [],
  checkboxSelection = false,
  loading = false,
  processing = false,
  rowCount = 20,
  noRowsMessage = '',
  btnActions,
  setOpen,
  onRowClick = () => {},
  onPageChange = () => {},
  onPageSizeChange = () => {},
}) {
  const [pageSize, setPageSize] = useState(20);
  const [savedDocuments, setSavedDocuments] = useState(20);
  const [sortModel, setSortModel] = useState([
    { field: 'volume', sort: 'desc' },
  ]);
  const [paginating, setPaginating] = useState(false);
  const { push } = useRouter();
  const {
    user: { team },
  } = useSelector((state) => state);

  const rowHeight = 56;

  const { theme } = useTheme();

  const useStyles = makeStyles({
    buttonOutlinedSm: {
      border: theme === 'dark' ? ' 1px solid #31313f' : '1px solid #EBEBEF',
      fontWeight: 'bold',
      borderRadius: '5px',
      padding: '8px 21px !important',
      background: theme === 'dark' ? '#2b2b37' : 'white',
      '&:hover': {
        color: theme === 'dark' ? 'white !important' : '#505876 !important',
        background:
          theme === 'dark' ? '#31313f !important' : '#EBEBEF !important',
      },
    },
    buttonWrapper: {
      margin: '0px 6px',
    },
    btnIcon: {
      // display: 'inline-block',
      height: '52px !important',
      width: '20px !important',
      color: 'white',
      // padding: 0,
      minHeight: 0,
      minWidth: 0,
      marginLeft: '10px',
    },
  });

  let otherComponents = {};

  if (noRowsMessage) {
    otherComponents = {
      NoRowsOverlay: () => (
        <Stack
          height='100%'
          alignItems='center'
          justifyContent='center'
          sx={{ padding: '20px' }}
        >
          {noRowsMessage}
        </Stack>
      ),
    };
  }
  const classes = useStyles();

  const LoadingSkeleton = () =>
    [...Array(5)].map(() => (
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          margin: '10px auto',
          width: 'calc(100% - 20px)',
        }}
      >
        {columns.map((column) => (
          <Skeleton
            variant='text'
            sx={{
              flexGrow: column.flex,
              height: `${rowHeight}px`,
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
        ))}
      </Box>
    ));

  return (
    <>
      {/* 
      // ? DISABLED TO EXCLUDE FROM PROD
      */}
      {/* <Box className='flex justify-between items-center mb-3 flex-wrap'>
        <SectionTitle title={title} />
        {btnActions && (
          <Box className='flex items-center justify-center'>
            <CustomButton
              btnColor='outlined'
              className={classes.buttonOutlinedSm}
              wrapperClasses={classes.buttonWrapper}
              // disabled={!savedDocuments.length}
              onClick={() => push('/ink-documents/trash')}
            >
              Trash
            </CustomButton>
            <CustomButton
              btnColor='outlined'
              className={classes.buttonOutlinedSm}
              wrapperClasses={classes.buttonWrapper}
              // disabled={!savedDocuments.length}
            >
              Delete
            </CustomButton>
            
            <CustomButton
              btnColor='blue'
              className={(classes.buttonOutlinedSm, { color: 'white' })}
              wrapperClasses={classes.buttonWrapper}
              disabled={!savedDocuments.length || !team[0]?.role === 'owner'}
              onClick={() => setOpen(true)}
            >
              Share
            </CustomButton>
          </Box>
        )}
      </Box> */}

      <Box
        sx={{
          width: '100%',
          marginBottom: '12%',
          backgroundColor: theme === 'dark' ? '#252530' : 'white',
        }}
      >
        <DataGrid
          loading={loading}
          columns={columns}
          rows={rows}
          autoHeight={!loading}
          rowHeight={rowHeight}
          /* checkboxSelection={checkboxSelection} */ // ? DISABLED TO EXCLUDE FROM PROD
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          pagination
          paginationMode='server'
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={[20, 50, 100]}
          onSelectionModelChange={(keywords) => setSavedDocuments(keywords)}
          onPageSizeChange={async (newPageSize) => {
            setPaginating(true);
            await onPageSizeChange({ pageSize: newPageSize });
            setPaginating(false);

            setPageSize(newPageSize);
          }}
          onPageChange={async (pageNumber) => {
            setPaginating(true);
            await onPageChange({ pageNumber: pageNumber + 1 });
            setPaginating(false);
          }}
          components={{
            LoadingOverlay: LoadingSkeleton,
            ColumnSortedAscendingIcon: () => <KeyboardArrowUpIcon />,
            ColumnSortedDescendingIcon: () => <KeyboardArrowDownIcon />,

            ColumnUnsortedIcon: () => (
              <Stack
                width='20px'
                height='20px'
                alignItems='center'
                justifyContent='center'
              >
                <KeyboardArrowUpIcon
                  fontSize='small'
                  sx={{ marginBottom: '-8px' }}
                />
                <KeyboardArrowDownIcon fontSize='small' />
              </Stack>
            ),
            ...otherComponents,
          }}
          onRowClick={(data) => onRowClick(data)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 10 : 5,
          })}
          sx={{
            minHeight: loading ? '500px' : 'auto',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            marginTop: 'auto',
            backgroundColor: theme === 'dark' ? '#252530' : 'white',
            color: theme === 'dark' ? 'white' : '#505876',
            borderColor: theme === 'dark' ? '#31313F' : '#ebebef',
            borderRadius: '5px',
            opacity: paginating || processing ? 0.7 : 1,
            '& .MuiDataGrid-columnHeaders': {
              // borderBottom: 'none',
              fontSize: { md: '14px', xs: '12px' },
              border: 'none',
              '& .MuiButtonBase-root': {
                width: '10px',
                marginLeft: '10px',
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              paddingLeft: '10px',
              paddingRight: '15px',

              '& .MuiTouchRipple-root': {
                display: 'none',
              },
              '.MuiButtonBase-root:hover': {
                backgroundColor: 'transparent',
              },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-iconButtonContainer': {
              '& .MuiIconButton-root': {
                color: theme === 'dark' && '#ebebef',
              },
            },
            '.MuiDataGrid-columnHeader:first-child': {
              paddingLeft: 0,
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus':
              {
                outline: 'none',
              },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              '& .MuiTablePagination-root': {
                color: theme === 'dark' ? 'white' : '#505876',
                '& .Mui-disabled': {
                  color: theme === 'dark' && '#505876',
                },
              },
            },
            '& .MuiDataGrid-checkboxInput': {
              color: theme === 'dark' ? 'white' : 'black',
            },
            '& .MuiDataGrid-row': {
              border: `1px solid ${theme === 'dark' ? '#31313F' : '#EBEBEF'}`,
              borderRadius: '5px',
              width: 'calc(100% - 28px)',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderColor: theme === 'dark' ? '#31313F' : '#EBEBEF',
            },
            '& .MuiDataGrid-cell': {
              border: 'none',
            },
            '.MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'hidden',
            },
            '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
              padding: 0,
            },
            '& .MuiTablePagination-selectLabel': {
              fontFamily: `'Montserrat', sans-serif`,
            },
            '& .MuiTablePagination-displayedRows': {
              fontFamily: `'Montserrat', sans-serif`,
            },
            '& .MuiSelect-select': {
              fontFamily: `'Montserrat', sans-serif`,
              padding: '5px 0 5px',
            },
          }}
        />
      </Box>
    </>
  );
}

CustomDataGrid.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array,
  checkboxSelection: PropTypes.bool,
  loading: PropTypes.bool,
  btnActions: PropTypes.bool,
  processing: PropTypes.bool,
  rowCount: PropTypes.number,
  noRowsMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  setOpen: PropTypes.func,
};

export default CustomDataGrid;
