class BlockToTextConverter {
  constructor(block) {
    this.block = block;
    this.blockChildren = this.block?.children;
  }

  _isHeading() {
    return /^heading/.test(this.block.type);
  }

  /**
   * Convert block's children array to formatted text
   * @param {Array} children of block
   * @returns {string} formatted text
   */
  _childrenToText(children) {
    const strings = [];
    for (const node of children || []) {
      if (node?.type === 'link') {
        strings.push(node.text);
      } else {
        const text = node?.text;
        if (text) {
          strings.push(text);
        }
        const subChildren = node?.children;
        if (subChildren?.length) {
          strings.push(this._childrenToText(subChildren));
        }
      }
    }
    return strings.join('');
  }

  _getHeading() {
    const heading = this._childrenToText(this.blockChildren);

    return heading;
  }

  /**
   * Generate text for each list item
   *
   * @param {Block[]} mainList list-item block with proper nested children structure
   *
   * @returns {string}
   */
  _generateListText(mainList) {
    return mainList.map((item) => {
      this.nestedListType = item?.listType?.toLowerCase() || 'ul';

      if (item?.nestedChildren?.length) {
        // generate nested text for nested children
        const nestedText = this._generateListText(item?.nestedChildren);

        return this._childrenToText(item.children) + nestedText.join('');
      }
      return this._childrenToText(item.children);
    });
  }

  /**
   * list content getter
   */
  get listContent() {
    // safely copy this.blockChildren with deeply nested objects
    const allListItems = JSON.parse(JSON.stringify(this.blockChildren));

    return allListItems.map(
      (listItem) => `${this._childrenToText(listItem.children)}`
    );
  }

  _getList() {
    return this.listContent.join(' ');
  }

  _getParagraph() {
    const text = this._childrenToText(this.blockChildren);

    return text;
  }

  _getImage() {
    const url = this.block?.file;
    const alt = this.block?.alt || '';
    const width = this.block?.width || '';
    const height = this.block?.height || '';
    const caption = this.block?.caption || '';

    return url + alt + caption + width + height;
  }

  _getQuote() {
    let quote = '';
    let credit = '';

    this.block?.children.forEach((child) => {
      if (child?.type === 'q') quote = this._childrenToText(child?.children);
      else if (child?.type === 'credit')
        credit = this._childrenToText(child?.children);
    });

    return quote + credit;
  }

  _getOtherElement() {
    switch (this.block.type) {
      case 'bulleted-list':
        return this._getList();
      case 'numbered-list':
        return this._getList();
      case 'paragraph':
        return this._getParagraph();
      case 'image':
        return this._getImage();
      case 'quote':
        return this._getQuote();
      default:
        return {};
    }
  }

  getText() {
    if (this._isHeading(this.block.type)) return this._getHeading();
    return this._getOtherElement();
  }
}

export default BlockToTextConverter;
