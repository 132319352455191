import { useState, useEffect } from 'react';

//! REMEMBER TO USE useCallback() FOR ALL FUNCTIONS THAT ARE PASSED TO CHILD COMPONENTS

// COMPONENT FOR MAKING HTTP REQUESTS WITH THE OPTION OF POLLING

const useHTTP = ({
  fetcher,
  options,
  pollingInterval = 1000 * 5,
  pollingCondition,
  enable = true,
  onSuccess,
  onError,
  doPollingEndResponseEmpty,
  onPollingEnd,
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [firstFetched, setFirstFetched] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    if (!enable) return;
    setLoading(true);
    fetcher(options).then(
      async (res) => {
        setResponse(res);
        if (onSuccess) onSuccess(res);
        // setFirstFetched(true);
        setLoading(false);

        if (pollingCondition && pollingCondition(res)) {
          console.log(pollingCondition(res));
          setIsPolling(true);
        } else {
          if (doPollingEndResponseEmpty) setResponse(null);
          if (onPollingEnd) onPollingEnd();
          setIsPolling(false);
        }
      },
      (err) => {
        setError(err);
        if (onError) onError();
        setLoading(false);
      }
    );
  }, [fetcher, JSON.stringify(options), pollingCondition, enable]);

  useEffect(() => {
    if (!enable) return;
    if (isPolling) {
      const timer = setInterval(() => {
        fetcher(options).then((res) => {
          setResponse(res);
          if (onSuccess) onSuccess(res);
          if (pollingCondition && pollingCondition(res)) {
            setIsPolling(true);
          } else {
            if (doPollingEndResponseEmpty) setResponse(null);
            if (onPollingEnd) onPollingEnd();
            setIsPolling(false);
          }
        });
      }, pollingInterval);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [isPolling, pollingInterval, options, fetcher, pollingCondition]);

  // refetch
  function refetch() {
    setError(null);

    fetcher(options).then((res) => {
      setResponse(res);
      // setFirstFetched(true);
      if (onSuccess) onSuccess(res);
      if (pollingCondition && pollingCondition(res)) {
        setIsPolling(true);
      } else {
        setIsPolling(false);
      }
    });
  }

  return {
    response,
    error,
    loading,
    refetch,
    setIsPolling,
    isPolling,
  };
};

export default useHTTP;
