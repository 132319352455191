/**
 * The simplified version of browser_storage.js
 * To wrap localStorage API
 */

const clientStorage = {
  set: (key, data, primitive = false) => {
    if (typeof localStorage !== 'object') return;

    if (!primitive) {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, data);
    }
  },
  get: (key, primitive = false) => {
    if (typeof localStorage !== 'object') return;

    if (!primitive) {
      return JSON.parse(localStorage.getItem(key));
    }
    return localStorage.getItem(key);
  },
  remove: (key) => {
    if (typeof localStorage !== 'object') return;

    localStorage.removeItem(key);
  },
  session: {
    set: (key, data, primitive = false) => {
      if (typeof sessionStorage !== 'object') return;

      if (!primitive) {
        sessionStorage.setItem(key, JSON.stringify(data));
      } else {
        sessionStorage.setItem(key, data);
      }
    },
    get: (key, primitive = false) => {
      if (typeof sessionStorage !== 'object') return;

      if (!primitive) {
        return JSON.parse(sessionStorage.getItem(key));
      }
      return sessionStorage.getItem(key);
    },
    remove: (key) => {
      if (typeof sessionStorage !== 'object') return;

      sessionStorage.removeItem(key);
    },
  },
};

export default clientStorage;
