const colors = {
  posColorRed: '#28B8FE66',
  posColorGreen: '#2BC8DF66',
  posColorBrown: '#9CCF6166',
  posColorAqua: '#FFED5166',
  posColorMagenta: '#1DA19366',
  posColorViolet: '#FFC72166',
  posColorOrange: '#62BA6566',
  posColorPurple: '#784FC266',
  posLighterBlue: '#FFA21A66',
};

export default colors;
