/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import apiObj from '@config/api.service';
import { initializeStore } from '@redux/store';
import {
  setAuthStatus,
  setLoggedUser,
  setSelectedAvatarOption,
  setAuthRefreshNonce,
} from '@redux/actions/user';
import { setCustomAlert } from '@redux/actions/general';

import { isValidObj, getTeam } from '@helpers/utils';
import { getDataFromLocalStorage } from '@helpers/browser_storage';

const api = apiObj.url;
const baseV2ApiUrl = apiObj.baseUrlV2;
const store = initializeStore();

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${api}/users/refresh`, {
      refreshToken: JSON.parse(localStorage.getItem('refreshToken')),
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem(
        'idToken',
        JSON.stringify(tokenRefreshResponse.data.data.IdToken)
      );
      failedRequest.response.config.headers.Authorization =
        tokenRefreshResponse.data.data.IdToken;

      // The refresh nonce will help to get document in editor using refreshed auth token
      store.dispatch(setAuthRefreshNonce(Date.now()));

      return Promise.resolve();
    })
    .catch(() => {
      console.log('failed to refresh token');
      localStorage.removeItem('idToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('avatarSelectedData');
      localStorage.removeItem('userAccounts');
      localStorage.removeItem('inputFields');
      localStorage.removeItem('selectedTool');
      localStorage.removeItem('showExtPopup');
      localStorage.removeItem('inviteCode');
      store.dispatch(setAuthStatus('undecided'));
      store.dispatch(setSelectedAvatarOption(null));
      store.dispatch(setLoggedUser(null));
      return Promise.reject();
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true,
});

export const fetchToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  if (token) {
    localStorage.setItem('idToken', JSON.stringify(token));
    return token;
  }
  return JSON.parse(localStorage.getItem('idToken'));
};

export const request = (
  url,
  data,
  type,
  noHeaders,
  params,
  team,
  otherURL = false,
  customHeaders = null,
  v2 = false
) =>
  new Promise((resolve, reject) => {
    let token = '';
    const apiUrl = v2 ? baseV2ApiUrl : api;
    let requestObj = {
      method: type,
      url: !otherURL ? apiUrl + url : url,
      headers: {},
    };
    noHeaders || (token = fetchToken());
    type !== 'get' && (requestObj.data = data);
    noHeaders || (requestObj.headers = { Authorization: token });
    params && (requestObj.params = params);
    const project = getDataFromLocalStorage('selectedFolder');

    if (project?._id)
      axios.defaults.headers.common['x-ink-project'] = project?._id;
    let teamID = null;
    if (
      store.getState().user?.selectedAvatarOption?.accountType !== 'personal' &&
      url !== '/v2.0/projects/files/images'
    ) {
      teamID = store.getState().user?.selectedAvatarOption?._id;
    }
    if (team) {
      requestObj.headers['x-ink-team'] = team;
    } else if (teamID) {
      requestObj.headers['x-ink-team'] = teamID;
    }
    if (customHeaders) {
      requestObj = { ...requestObj, headers: customHeaders };
    }
    axios({ ...requestObj })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // rate limiting issue
        if (error?.response?.status === 429) {
          store.dispatch(
            setCustomAlert({
              message:
                error.response?.data?.output?.payload?.message ||
                `Text generation limit reached, please retry in ${error?.response.data.data.retryAfter} ${error?.response.data.data.unit}.`,
              state: true,
              type: 'warning',
            })
          );
          // reject(error);
        }
        // rate limiting issue
        const rejectObject = {
          ...(error?.response?.data || {}),
          status: error?.response?.status,
        };
        reject(rejectObject);
      });
  });

export const v2request = async ({
  path,
  url = '',
  method = 'GET',
  data = {},
  headers = {},
  params = {},
}) => {
  const { baseUrlV2 } = apiObj;

  const token = fetchToken();
  const defaultHeaders = { Authorization: token };

  const requestObj = {
    method,
    url: url || baseUrlV2 + path,
    headers: defaultHeaders,
  };

  if (isValidObj(headers)) {
    requestObj.headers = { ...requestObj.headers, ...headers };
  }
  const project = getDataFromLocalStorage('selectedFolder');
  if (project?._id) {
    axios.defaults.headers.common['x-ink-project'] = project?._id;
  }
  const teamId = getTeam()?._id;

  if (teamId && url !== '/v2.0/projects/files/images') {
    requestObj.headers['x-ink-team'] = teamId;
  }

  if (method?.toLowerCase() !== 'get' && isValidObj(data)) {
    requestObj.data = data;
  }

  if (isValidObj(params)) {
    requestObj.params = params;
  }

  try {
    const response = await axios({ ...requestObj });

    return response;
  } catch (error) {
    // rate limiting issue
    if (error?.response?.status === 429) {
      store.dispatch(
        setCustomAlert({
          message:
            error.response?.data?.output?.payload?.message ||
            `Text generation limit reached, please retry in ${error?.response.data.data.retryAfter} ${error?.response.data.data.unit}.`,
          state: true,
          type: 'warning',
        })
      );
    }

    return error?.response;
  }
};

export const requestStreamAPI = async ({ data, path, onReceiveData }) => {
  const controller = new AbortController();
  const { baseUrlV2 } = apiObj;
  const token = fetchToken();

  const requestObj = {
    method: 'POST',
    url: `${baseUrlV2}${path}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };
  // const response = await axios({
  //   ...requestObj,
  //   data,
  //   signal: controller.signal,
  // });
  const response = await fetch(requestObj.url, {
    method: requestObj.method,
    headers: requestObj.headers,
    body: JSON.stringify(data),
    signal: controller.signal,
  });

  if (response?.status !== 200) {
    // return error code
    return {
      status: response?.status,
    };
  }

  const reader = response.body.getReader();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const output = new TextDecoder('utf-8').decode(value);
    onReceiveData(output);
  }

  return {
    status: response?.status,
  };
};
