import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const IsMobileWidth = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('xs', 'sm'));
};

export const IsDesktopWidth = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('lg', 'xl'));
};

export const IsTabletWidth = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'md'));
};

export const IsMd = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
};

export const IsSmallHeight = () => useMediaQuery('(min-height:680px)');
