import apiConfig from '@config/api.service';
import { fetchToken } from '@shared/verb';
import posthog from 'posthog-js';
import { PATH_EDITOR } from '@editor/config';
import moment from 'moment';
import clientStorage from './clientStorage';
import colors from './colors';

const { BILLINK_INKFORALL_URL, imageGenerationURL } = apiConfig;

export const removeQueryParams = (match = '?') => {
  if (window.location.href.includes(match)) {
    const newurl = window.location.href.split(match)[0];
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const routesParser = (routes) => {
  const toolsRoutes = routes.filter((o) => !o.notVisible);
  const subCategoryCollection = [];
  const getCategories = [
    ...new Set(toolsRoutes.map((item) => item.category).flat()),
  ];
  const parseData = [];
  for (let i = 0; i < getCategories.length; i++) {
    let toolsObject = {};
    toolsObject.categoryName = getCategories[i];
    const getSubCategories = toolsRoutes.filter((item) =>
      item.category.find(
        (elem) => elem === getCategories[i] && item.subcategory !== null
      )
    );
    const getUniqueSubCategory = [
      ...new Set(getSubCategories.map((item) => item.subcategory)),
    ];
    const subCategoryArray = [];
    for (let k = 0; k < getUniqueSubCategory.length; k++) {
      const obj = {};
      obj.name = getUniqueSubCategory[k];
      obj.tools = [
        ...toolsRoutes.filter(
          (item) =>
            item.category.find((elem) => elem === getCategories[i]) &&
            item.subcategory === getUniqueSubCategory[k]
        ),
      ];
      subCategoryArray.push(obj);
    }
    subCategoryCollection.push(subCategoryArray);
    const tools = [];
    for (let j = 0; j < toolsRoutes.length; j++) {
      if (
        toolsRoutes[j].subcategory == null &&
        toolsRoutes[j].category.find((elem) => elem === getCategories[i])
      ) {
        tools.push(toolsRoutes[j]);
      }
      toolsObject.tools = [...tools];
    }
    parseData.push(toolsObject);
    toolsObject = {};
  }
  for (let o = 0; o < parseData.length; o++) {
    parseData[o].subCategory = subCategoryCollection[o];
    parseData[o].id = o + 1;
  }
  return parseData;
};

export const getHost = () => window.location.host.split('.')[0];
export const truncate = (numberString, trunk) => {
  const onpoint = numberString.split('.', 2);
  let numberStringTruncated = numberString;
  if (onpoint.length > 1) {
    numberStringTruncated = `${onpoint[0]}.${onpoint[1].substring(0, trunk)}`;
  }
  return parseFloat(numberStringTruncated).toFixed(2);
};

export const intervalCheck = (data) =>
  data === 'year' ? 'Billed Annually:' : 'Billed Monthly:';

export const priceCheck = (data, price, seats) =>
  data === 'year'
    ? ` $${truncate(Number((price.yearly / 12) * seats).toString(), 2)}`
    : ` $${truncate(Number(price.monthly * seats).toString(), 2)}`;

export const SEORocketPriceCheck = (data, price, seats, additonalSeats) => {
  const addedSeatsCost =
    data === 'year' ? additonalSeats * (1062 / 12) : additonalSeats * 118;
  console.log(addedSeatsCost);
  return data === 'year'
    ? ` $${truncate(Number(price.yearly / 12 + addedSeatsCost).toString(), 2)}`
    : ` $${truncate(Number(price.monthly + addedSeatsCost).toString(), 2)}`;
};

export const centsToDollar = (cents) => (cents / 100).toFixed(2);

export const partsOfSpeechColors = {
  red: { color: colors.posColorRed, pos: 'NOUN' },
  green: { color: colors.posColorGreen, pos: 'PRONOUN' },
  brown: { color: colors.posColorBrown, pos: 'PREPOSITION' },
  aqua: { color: colors.posColorAqua, pos: 'CONJUNCTION' },
  magenta: { color: colors.posColorMagenta, pos: 'VERB' },
  violet: { color: colors.posColorViolet, pos: 'DETERMINER' },
  orange: { color: colors.posColorOrange, pos: 'ADJECTIVE' },
  purple: { color: colors.posColorPurple, pos: 'ADVERB' },
  blue: { color: colors.posLighterBlue, pos: 'INTERJECTION' },
  pink: { color: 'pink' },
};

export const selectColor = (pos) => {
  let color = null;
  switch (pos) {
    case 'NOUN':
      color = 'red';
      break;
    case 'PRONOUN':
      color = 'green';
      break;
    case 'PREPOSITION':
      color = 'brown';
      break;
    case 'CONJUNCTION':
      color = 'aqua';
      break;
    case 'VERB':
      color = 'magenta';
      break;
    case 'DETERMINER':
      color = 'violet';
      break;
    case 'ADJECTIVE':
      color = 'orange';
      break;
    case 'ADVERB':
      color = 'purple';
      break;
    case 'INTERJECTION':
      color = 'blue';
      break;
    default:
      color = 'pink';
  }
  return color;
};

export const toolImage = (type, className, width, height) => {
  switch (type) {
    case 'Twitter Ads':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/twitter.svg'
          alt='#twitter'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Google Ads':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/google-icon.svg'
          alt='#google'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Facebook Ads':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/facebook.svg'
          alt='#facebook'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'LinkedIn Ads':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/linkedin.svg'
          alt='#linkedin'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Instagram':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/insta.svg'
          alt='#insta'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'YouTube':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/youtube.svg'
          alt='#youtube'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'AI Writing':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/ai-writing.svg'
          alt='#ai-writing'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Parts of Speech':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/part-of-speech.svg'
          alt='#parts-of-speech'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Counter Tools':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/counter-tools.svg'
          alt='#counter-tools'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Grade Level':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/grade-level.svg'
          alt='#grade-level'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Product':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/product.svg'
          alt='#product'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'E-Commerce':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/ecommerce.svg'
          alt='#ecommerce'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Persuasion':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/persuasion.svg'
          alt='#persuasion'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'E-Mail':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/email.svg'
          alt='#email'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Startup Kit':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/startup.svg'
          alt='#startup-kit'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'SEM':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/sem.svg'
          alt='#sem'
          width={width || 22}
          height={height || 16}
        />
      );
    case 'Readability':
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/readability.svg'
          alt='#readability'
          width={width || 22}
          height={height || 16}
        />
      );
    default:
      return (
        <img
          className={className || ''}
          src='/assets/tools_avatars/generator-tools.svg'
          alt='#generator-tools'
          width={width || 22}
          height={height || 16}
        />
      );
  }
};

export const conciseSentence = (sentence) => {
  const arr = sentence.split(' ');
  const lastWord = arr[arr.length - 1];
  switch (lastWord) {
    case 'Checker':
      return sentence.replace('Checker', '').trim();
    case 'Counter':
      return sentence.replace('Counter', 'Count').trim();
    default:
      return sentence;
  }
};

export const scripts = [
  {
    type: 'external',
    content: '//js.hs-scripts.com/20645624.js',
    name: 'hubspot',
    where: 'everywhere',
    id: 'hs-script-loader',
  },
  {
    type: 'internal',
    content: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      
      ga('create', 'UA-82891932-2', 'auto');
      ga('send', 'pageview');`,
    name: 'googleAnalytics',
    where: 'app',
  },
  // {
  //   type: 'internal',
  //   content: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-PJMQJ4F');`,
  //   name: 'googleTagManager',
  //   where: 'app',
  // },
  {
    type: 'external',
    content: 'https://www.googletagmanager.com/gtag/js?id=G-N2KM4WGM00',
    name: 'googleTagManager',
    where: 'app',
    id: 'googleTagManagerLoader',
  },
  // {
  //   type: 'internal',
  //   content: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-N2KM4WGM00');`,
  //   name: 'googleTagManager',
  //   where: 'app',
  // },
  // {
  //   type: 'external',
  //   content: 'https://storage-hub.inkforall.com/storage.js',
  //   name: 'crossStorage',
  //   where: 'everywhere',
  // },
  {
    type: 'external',
    content: 'https://www.googleoptimize.com/optimize.js?id=OPT-TCZSXWP',
    name: 'googleoptimize',
    where: 'app',
  },
  // {
  //   type: 'internal',
  //   content: `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
  //   posthog.init('phc_Nif3kJQIOo2dgFySR5qOKCOJFjgb6kjfgo1ngaUezzw',{api_host:'https://posthog-test.seo.app'})`,
  //   name: 'posthog',
  //   where: 'everywhere',
  // }
];

const addScript = (payload, type, id = null) => {
  const s = document.createElement(`script`);
  s.type = `text/javascript`;
  s.async = true;
  s.defer = true;
  if (id) {
    s.id = id;
  }
  if (type === 'internal') {
    s.innerHTML = payload;
  } else if (type === 'external') {
    s.src = payload;
  }
  document.getElementsByTagName(`head`)[0].appendChild(s);
};

export const injectScripts = (arr) => {
  const host = window.location.host?.split('.')?.[0];
  arr.forEach((elem) => {
    const { content, type, where, id } = elem;
    if (where === 'everywhere') {
      addScript(content, type, id);
    } else if (host === where) {
      addScript(content, type, id);
    }
  });
};

const routesList = [PATH_EDITOR];

export const addCrisp = (route = null) => {
  const script = document.querySelector('#crisp-chat-script');
  if (routesList?.includes(route)) {
    if (script) {
      script.parentNode.removeChild(script);
    }
    return;
  }
  if (script) {
    return;
  }
  const s = document.createElement(`script`);
  s.setAttribute('id', 'crisp-chat-script');
  s.type = `text/javascript`;
  s.innerHTML = `window.$crisp = []; window.CRISP_WEBSITE_ID = "6e0173b2-d2fb-4cf1-aa48-f08e8e433379"; (function () { d = document; s = d.createElement("script"); s.src = "https://client.crisp.chat/l.js"; s.async = 1; d.getElementsByTagName("head")[0].appendChild(s); })();`;
  document.getElementsByTagName(`head`)[0].appendChild(s);
};

// export const addCrisp = () => {
//   const s = document.createElement(`script`);
//   s.type = `text/javascript`;
//   s.innerHTML = `window.$crisp = []; window.CRISP_WEBSITE_ID = "6e0173b2-d2fb-4cf1-aa48-f08e8e433379"; (function () { d = document; s = d.createElement("script"); s.src = "https://client.crisp.chat/l.js"; s.async = 1; d.getElementsByTagName("head")[0].appendChild(s); })();`;

//   document.getElementsByTagName(`head`)[0].appendChild(s);
// };

// export const addGA = () => {
//   const s = document.createElement(`script`);
//   s.type = `text/javascript`;
//   s.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//     })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

//     ga('create', 'UA-82891932-2', 'auto');
//     ga('send', 'pageview');`;
//   document.getElementsByTagName(`head`)[0].appendChild(s);
// };
// export const addGTAG = () => {
//   const s = document.createElement(`script`);
//   s.type = `text/javascript`;
//   s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//     })(window,document,'script','dataLayer','GTM-PJMQJ4F');`;
//   document.getElementsByTagName(`head`)[0].appendChild(s);
// };
// export const addCrossStorage = () => {
//   const s = document.createElement(`script`);
//   s.type = `text/javascript`;
//   s.src = 'https://storage-hub.inkforall.com/storage.js';
//   document.getElementsByTagName(`head`)[0].appendChild(s);
// };
export const addGoogleOptimizer = () => {
  const s = document.createElement(`script`);
  s.type = `text/javascript`;
  s.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-W5W4TWJ';
  document.getElementsByTagName(`head`)[0].appendChild(s);
};

// const routesList = ['/long-form-writing', '/install'];

// export const addHubSpot = (route = null) => {
//   const script = document.querySelector('#hubspot-chat-script');
//   if (routesList?.includes(route)) {
//     if (script) {
//       script.parentNode.removeChild(script);
//     }
//     return;
//   }
//   if (script) {
//     return;
//   }
//   const s = document.createElement(`script`);
//   s.setAttribute('id', 'hubspot-chat-script');
//   s.type = `text/javascript`;
//   s.async = true;
//   s.defer = true;
//   s.src = '//js.hs-scripts.com/20645624.js';
//   document.getElementsByTagName(`head`)[0].appendChild(s);
// };

export const openNewTab = (utmCode = null) => {
  if (utmCode) {
    window?.open(utmCode);
  } else {
    const chromeLink =
      'https://chrome.google.com/webstore/detail/ink-copy-assistant/fajobjleimkjndphlimdddblbcgfpicl';
    window?.open(chromeLink);
  }
};

export const utmCodeMap = {
  'Pronoun Checker': 'https://seo.app/a282coAyF',
  'Verb Finder': 'https://seo.app/6d0psJv1p',
  'Adverb Checker': 'https://seo.app/Ob1bqn-TS',
  'Preposition Checker': 'https://seo.app/3Lz_NWhnk',
  'Interjection Checker': 'https://seo.app/NQ05SiNYp',
  'Determiner Checker': 'https://seo.app/I7EusEOJx',
  'Conjunction Checker': 'https://seo.app/gc8aOBbT5',
  'Passive Voice Checker': 'https://seo.app/znJ0TcZYe',
  'Noun Checker': 'https://seo.app/W9_7a7FVZ',
  'Space Counter': 'https://seo.app/Ca2ALbofz',
  'Letter Counter': 'https://seo.app/OPqYW6YbK',
  'Word Counter': 'https://seo.app/FvA6p3n_k',
  'Sentence Counter': 'https://seo.app/AvAAUHYA9',
  'Paragraph Counter': 'https://seo.app/Un2hyzr7U',
  'Parts of Speech Checker': 'https://seo.app/RNpKVUmPp',
  'Reading Level Checker': 'https://seo.app/NKx8ywjnj',
  'Number Counter': 'https://seo.app/5efqHm34O',
};

export const debounce = (fn, delay) => {
  let timeoutID;

  return function (...args) {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const userPlanStateChecker = (loggedUser, teams) => {
  const { trialInfo, isPaidUser, subscription } = loggedUser;
  if (trialInfo && trialInfo?.isActivated) {
    return 'popup-trial';
  }
  if (!isPaidUser && !subscription && teams.length) {
    return 'popup-team';
  }
  if (subscription) {
    return 'popup-subscription';
  }
  return 'url';
};

export const capitalizeFirstLetter = (string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1) ?? '';
export const inkPlans = [
  {
    planName: 'Creator',
    layout: 'grid',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'AI Words', value: '∞' },
      { img: '/assets/small.search.png', key: 'Rewrites', value: '∞' },
      { img: '/assets/image-gen.png', key: 'AI Image', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'STUDENT',
    layout: 'flex',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'INK Words', value: '111' },
      { img: '/assets/small.search.png', key: 'Rewrites', value: '∞' },
      { img: '/assets/small.article.png', key: 'Ext. Features', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'AI Writer Pro',
    layout: 'flex',
    features: [
      {
        img: '/assets/unlimited-aiwords.png',
        key: 'Unlimited AI Words',
        value: '',
      },
      { img: '/assets/small.search.png', key: 'Unlimited Keywords', value: '' },
      { img: '/assets/small.article.png', key: 'Long-Form Writing', value: '' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Copy Assistant Student',
    layout: 'flex',
    features: [
      { img: '/assets/plagrism-check.png', key: 'Plagiarism Check', value: '' },
      {
        img: '/assets/ai-rewrites.png',
        key: 'Advanced AI Rewrites',
        value: '',
      },
      { img: '/assets/small.search.png', key: 'Audience Optimizer', value: '' },
      { img: '/assets/small.people.png', key: 'Users:' },
    ],
  },
  {
    planName: 'Copy Assistant PRO',
    layout: 'flex',
    features: [
      { img: '/assets/plagrism-check.png', key: 'Plagiarism Check', value: '' },
      {
        img: '/assets/ai-rewrites.png',
        key: 'Advanced AI Rewrites',
        value: '',
      },
      { img: '/assets/small.search.png', key: 'Audience Optimizer', value: '' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Optimizer PRO',
    layout: 'flex',
    features: [
      {
        img: '/assets/small.article.png',
        key: 'Unlimited SEO Articles',
        value: '',
      },
      { img: '/assets/small.people.png', key: 'Users' },
      { img: '/assets/small.search.png', key: 'Unlimited Keywords', value: '' },
    ],
  },
  {
    planName: 'INK FREE',
    layout: 'grid',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'AI Words', meta: 'words' },
      { img: '/assets/image-gen.png', key: 'AI Image', meta: 'images' },
      { img: '/assets/small.search.png', key: 'SEO Articles', value: '3' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Free',
    layout: 'grid',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'AI Words', meta: 'words' },
      { img: '/assets/image-gen.png', key: 'AI Image', meta: 'images' },
      // { img: '/assets/small.search.png', key: 'SEO Articles', value: '3' },
      { img: '/assets/ink-features/free-user.png', key: 'Users' },
    ],
  },
  {
    planName: 'Bundle',
    layout: 'grid',
    features: [
      {
        img: '/assets/unlimited-aiwords.png',
        key: 'Unlimited AI Words',
        value: '',
      },
      {
        img: '/assets/ai-rewrites.png',
        key: 'Advanced AI Rewrites',
        value: '',
      },
      {
        img: '/assets/keyword-clustering.png',
        key: 'Keyword Clustering',
        value: '',
      },
      {
        img: '/assets/small.search.png',
        key: 'Keyword Credits',
        value: '1,000',
      },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'PRO SEO',
    layout: 'flex',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'INK Words', value: '555' },
      { img: '/assets/small.search.png', key: 'Keywords', value: '∞' },
      { img: '/assets/small.article.png', key: 'Articles', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Team Seo Unlimited',
    layout: 'flex',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'INK Words', value: '∞' },
      { img: '/assets/small.search.png', key: 'Keywords', value: '∞' },
      { img: '/assets/small.article.png', key: 'Articles', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'INK PRO',
    layout: 'flex',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'INK Words', value: '∞' },
      { img: '/assets/small.search.png', key: 'Keywords', value: '∞' },
      { img: '/assets/small.article.png', key: 'Articles', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'INK PRO UNLIMITED',
    layout: 'flex',
    features: [
      { img: '/assets/unlimited-aiwords.png', key: 'INK Words', value: '∞' },
      { img: '/assets/small.search.png', key: 'Keywords', value: '∞' },
      { img: '/assets/small.article.png', key: 'Articles', value: '∞' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Planner PRO',
    layout: 'flex',
    features: [
      {
        img: '/assets/small.search.png',
        key: 'Keyword Credits',
        value: '1,000',
      },
      { img: '/assets/small.people.png', key: 'Users' },
      {
        img: '/assets/keyword-clustering.png',
        key: 'Keyword Clustering',
        value: '',
      },
    ],
  },
  {
    planName: 'Professional',
    layout: 'grid',
    features: [
      {
        img: '/assets/ink-features/ink-credits.png',
        key: 'INK Credits',
      },
      {
        img: '/assets/ink-features/ai-Words.png',
        key: 'AI Words',
        value: '∞',
      },
      { img: '/assets/ink-features/ai-image.png', key: 'AI Image', value: '∞' },
      {
        img: '/assets/ink-features/seo-artilcles.png',
        key: 'SEO Articles',
        value: '∞',
      },
      // { img: '/assets/keyword-clustering.png', key: 'Planner KW' },
      { img: '/assets/ink-features/users.svg', key: 'Users' },
    ],
  },
  {
    planName: 'Enterprise',
    layout: 'grid',
    features: [
      {
        img: '/assets/ink-features/ink-credits.png',
        key: 'INK Credits',
      },
      {
        img: '/assets/ink-features/ai-Words.png',
        key: 'AI Words',
        value: '∞',
      },
      { img: '/assets/ink-features/ai-image.png', key: 'AI Image', value: '∞' },
      {
        img: '/assets/ink-features/seo-artilcles.png',
        key: 'SEO Articles',
        value: '∞',
      },
      // { img: '/assets/keyword-clustering.png', key: 'Planner KW' },
      { img: '/assets/ink-features/users.svg', key: 'Users' },
    ],
  },
  {
    planName: 'Trial',
    layout: 'grid',
    features: [
      {
        img: '/assets/ink-features/ink-credits.png',
        key: 'INK Credits',
      },
      {
        img: '/assets/ink-features/ai-Words.png',
        key: 'AI Words',
        value: '∞',
      },
      { img: '/assets/ink-features/ai-image.png', key: 'AI Image', value: '∞' },
      {
        img: '/assets/ink-features/seo-artilcles.png',
        key: 'SEO Articles',
        value: '∞',
      },
      // { img: '/assets/keyword-clustering.png', key: 'Planner KW' },
      { img: '/assets/ink-features/users.svg', key: 'Users' },
    ],
  },
  {
    planName: 'SEO Rocket',
    layout: 'grid',
    features: [
      {
        img: '/assets/unlimited-aiwords.png',
        key: 'AI Words',
        value: '∞',
      },
      { img: '/assets/small.search.png', key: 'SEO Articles', value: '∞' },
      { img: '/assets/keyword-clustering.png', key: 'Planner KW' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
  {
    planName: 'Professional (Trial)',
    layout: 'grid',
    features: [
      {
        img: '/assets/unlimited-aiwords.png',
        key: 'AI Words',
        value: '∞',
      },
      { img: '/assets/small.search.png', key: 'SEO Articles', value: '∞' },
      { img: '/assets/keyword-clustering.png', key: 'Planner KW' },
      { img: '/assets/small.people.png', key: 'Users' },
    ],
  },
];
export const featureImageMapper = {
  'Plagiarism Check': '/assets/plagrism-check.png',
  'AI Rewrites': '/assets/ai-rewrites.png',
  'Advanced AI Rewrites': '/assets/ai-rewrites.png',
  Rewrites: '/assets/ai-rewrites.png',
  Words: '/assets/ink-features/ai-Words.png',
  'Audience Optimizer': '/assets/small.search.png',
  Users: '/assets/ink-features/users.svg',
  'AI Words': '/assets/ink-features/ai-Words.png',
  Keywords: '/assets/ink-features/ink-credits.png',
  'SEO Articles': '/assets/ink-features/seo-artilcles.png',
  'Unlimited Keywords': '/assets/ink-features/ink-credits.png',
  'Long-Form Writing': '/assets/small.article.png',
  Articles: '/assets/small.article.png',
  'Keyword Clustering': '/assets/keyword-clustering.png',
  'INK Words': '/assets/unlimited-aiwords.png',
  'Unlimited AI Words': '/assets/unlimited-aiwords.png',
  'Keyword Credits': '/assets/ink-features/ink-credits.png',
  'Unlimited SEO Articles': '/assets/small.article.png',
  'Ext. Features': '/assets/small.article.png',
  Images: '/assets/small.article.svg',
  'Planner KW': '/assets/keyword-clustering.png',
  'AI Image': '/assets/ink-features/ai-image.png',
  'AI Images': '/assets/ink-features/ai-image.png',
  'INK Credits': '/assets/ink-features/ink-credits.png',
  'free-user': '/assets/ink-features/free-user.png',
};

export function setIntervalLimitedTimes(callback, delay, repetitions) {
  let x = 0;
  const intervalID = window.setInterval(() => {
    callback();
    x++;
    if (x === repetitions) window.clearInterval(intervalID);
  }, delay);
}

export function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return pattern.test(str);
}

export const extractUTMQueryParams = () => {
  let utmParams = '';
  const url = window.location.href;
  const paramString = url.split('?')[1];
  const queryString = new URLSearchParams(paramString);
  for (const pair of queryString.entries()) {
    if (pair[0].includes('utm_')) {
      const utmString = `&${[pair[0]]}=${pair[1]}`;
      console.log(utmString);
      utmParams += utmString;
    }
  }
  // console.log(utmParams);
  return utmParams;
};

export const pricingOnTheBasisOfkeywords = (
  contentPlannerPricingData,
  keywords,
  yearlyPlan,
  proratedCost
) => {
  let pricePerKeyword = 0;
  let totalPrice = 0;
  let upgradePlanId = '';
  contentPlannerPricingData?.forEach((pricing) => {
    if (keywords >= pricing?.min && keywords <= pricing?.max) {
      totalPrice =
        proratedCost ||
        (yearlyPlan
          ? pricing?.yearly?.price * keywords
          : pricing?.monthly?.price * keywords);
      pricePerKeyword = yearlyPlan
        ? pricing?.yearly?.price
        : pricing?.monthly?.price;
      upgradePlanId = yearlyPlan
        ? pricing?.yearly?.priceId
        : pricing?.monthly?.priceId;
    }
  });
  console.log({
    pricePerKeyword,
    upgradePlanId,
    total: proratedCost
      ? totalPrice
      : yearlyPlan
      ? totalPrice * 12
      : totalPrice,
  });
  return {
    pricePerKeyword,
    upgradePlanId,
    total: proratedCost
      ? totalPrice
      : yearlyPlan
      ? totalPrice * 12
      : totalPrice,
  };
};

export const newPlanURLGenerator = (
  type = 'seo-rocket',
  period = 'annually'
) => {
  const utmParamsQuery = extractUTMQueryParams();
  const token = fetchToken();
  const tokenParams = token ? `&token=${token}` : '';
  const planId = apiConfig.productIds[type];
  return `${BILLINK_INKFORALL_URL}/paymentonboarding/2?planId=${planId}&billing=${period}${utmParamsQuery}${tokenParams}`;
};

export const productPricingData = (
  period,
  type,
  counter,
  contentPlannerPricingData,
  customPlanAssigned
) => {
  let productPropsData = null;
  // eslint-disable-next-line
  let planId = 'notfound';
  if (type === 'custom' && customPlanAssigned) {
    planId = customPlanAssigned._id;
  } else {
    planId = apiConfig.productIds[type];
  }
  let extraParams = '';
  let contentPlannerSlabId = '';
  const utmParamsQuery = extractUTMQueryParams();
  const seats = type === 'planner' ? 1 : counter;
  switch (type) {
    case 'writer':
    case 'optimizer':
      // planId =
      //   type === 'writer'
      //     ? '6299c7462a03ab6ccc7f4931'
      //     : '6299c88a2a03ab6ccc7f4b9a';
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: '25',
            billedPrice: '300',
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $25/mo`,
            periodState: false,
          };
          break;
        default:
          productPropsData = {
            startPrice: '25',
            price: '20',
            billedPrice: '300',
            billedDiscount: '240',
            // description: `${counter} paid user included. Add users for $${
            //   20 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $20/mo`,
            periodState: true,
          };
          break;
      }
      break;
    case 'assistant':
      // planId = '6299c9242a03ab6ccc7f4d1f';
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: '25',
            billedPrice: '300',
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            type,
            description: `${seats} paid user included. Add users for $25/mo`,
            periodState: false,
          };
          break;
        default:
          productPropsData = {
            startPrice: '25',
            price: '10',
            billedPrice: '300',
            billedDiscount: '120',
            // description: `${counter} paid user included. Add users for $${
            //   10 * counter
            // }/mo`,
            type,
            description: `${seats} paid user included. Add users for $10/mo`,
            periodState: true,
          };
          break;
      }
      break;
    case 'planner':
      contentPlannerSlabId = pricingOnTheBasisOfkeywords(
        contentPlannerPricingData,
        counter,
        period !== 'monthly'
      ).upgradePlanId;
      console.log('contentPlannerSlabId', contentPlannerSlabId);
      extraParams = `&keywordsCount=${counter}&contentPlannerId=${contentPlannerSlabId}`;
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: '8',
            billedPrice: '300',
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            type,
            // description: `${counter} shared keywords included. Keywords are sold in increments of 1,000 for $10/mo`,
            description:
              'Keywords are sold in increments of 1,000. Buy more to save.',
            periodState: false,
          };
          break;
        default:
          productPropsData = {
            startPrice: '10',
            price: '8',
            billedPrice: '120',
            billedDiscount: '96',
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            type,
            description:
              'Keywords are sold in increments of 1,000. Buy more to save.',
            periodState: true,
          };
          break;
      }
      break;
    case 'suite':
      // planId = '62a97d98509e59725eb32da1';
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: '60',
            billedPrice: '720',
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $60/mo`,
            periodState: false,
          };
          break;
        default:
          productPropsData = {
            startPrice: '60',
            price: '50',
            billedPrice: '720',
            billedDiscount: '600',
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $50/mo`,
            periodState: true,
          };
          break;
      }
      break;
    case 'assistant-student':
      // planId = '6299c9632a03ab6ccc7f4dd2';
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: '15',
            billedPrice: '180',
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $15/mo`,
            periodState: false,
          };
          break;
        default:
          productPropsData = {
            startPrice: '15',
            price: '5',
            billedPrice: '180',
            billedDiscount: '60',
            // description: `${counter} paid user included. Add users for $${
            //   10 * counter
            // }/mo`,
            description: `${seats} paid user included. Add users for $5/mo`,
            periodState: true,
          };
          break;
      }
      break;
    case 'Creator':
      switch (period) {
        case 'monthly':
          productPropsData = {
            price: '59',
            billedPrice: `${59 * 12}`,
            planId,
            period,
          };
          break;
        default:
          productPropsData = {
            price: '44',
            // billedPrice: `${44 * 12}`,
            billedPrice: '531',
            planId,
            period,
          };
          break;
      }
      break;
    case 'professional':
      switch (period) {
        case 'monthly':
          productPropsData = {
            price: '49',
            billedPrice: `${49 * 12}`,
            planId,
            period,
          };
          break;
        default:
          productPropsData = {
            price: '39',
            // billedPrice: `${88 * 12}`,
            billedPrice: `468`,
            planId,
            period,
          };
          break;
      }
      break;
    case 'seo-rocket':
      switch (period) {
        case 'monthly':
          productPropsData = {
            price: '590',
            billedPrice: `${590 * 12}`,
            planId,
            period,
          };
          break;
        default:
          productPropsData = {
            price: '443',
            // billedPrice: `${88 * 12}`,
            billedPrice: `5310`,
            planId,
            period,
          };
          break;
      }
      break;
    case 'enterprise':
      switch (period) {
        case 'monthly':
          productPropsData = {
            price: '119',
            billedPrice: `${119 * 12}`,
            planId,
            period,
          };
          break;
        default:
          productPropsData = {
            price: '99',
            // billedPrice: `${88 * 12}`,
            billedPrice: `1188`,
            billingSubDetail: 'Billed annually at $1,188, save $240',
            planId,
            period,
          };
          break;
      }
      break;
    case 'custom':
      switch (period) {
        case 'monthly':
          productPropsData = {
            startPrice: null,
            price: `${customPlanAssigned.price.monthly}`,
            billedPrice: `${customPlanAssigned.price.monthly * 12}`,
            billedDiscount: null,
            // description: `${counter} paid user included. Add users for $${
            //   25 * counter
            // }/mo`,
            periodState: false,
            period,
          };
          break;
        default:
          productPropsData = {
            startPrice: null,
            price: `${
              (customPlanAssigned?.price.yearly / 12).toFixed(2) || ''
            }`,
            billedPrice: `${customPlanAssigned?.price.yearly || ''}`,
            billedDiscount: null,
            periodState: true,
            period,
            billingSubDetail: `Billed annually at $${
              customPlanAssigned?.price.yearly || ''
            }, save $${
              customPlanAssigned?.price.monthly * 12 -
              customPlanAssigned?.price.yearly
            }`,
          };
          break;
      }
      break;
    default:
      break;
  }
  if (['Creator', 'seo-rocket', 'professional', 'enterprise'].includes(type)) {
    productPropsData.buttonLink = newPlanURLGenerator(type, period);
  } else {
    const token = fetchToken();
    const tokenParams = token ? `&token=${token}` : '';
    productPropsData.buttonLink = `${BILLINK_INKFORALL_URL}/paymentonboarding/2?planId=${planId}&billing=${period}${
      type === 'custom' ? '' : `&seats=${seats}`
    }&skiptrial=true${extraParams}${utmParamsQuery}${tokenParams}`;
    productPropsData.buttonLinkTrial = `${BILLINK_INKFORALL_URL}/paymentonboarding/3?planId=${planId}&billing=${period}${
      type === 'custom' ? '' : `&seats=${seats}`
    }${extraParams}${utmParamsQuery}${tokenParams}`;
  }
  // console.log(productPropsData);
  if (productPropsData.price.includes('.00')) {
    // eslint-disable-next-line prefer-destructuring
    productPropsData.price = productPropsData.price.split('.', 1)[0];
  }
  return productPropsData;
};

export const floorFigure = (figure, decimals = 2) => {
  if (figure % 1 === 0) {
    return figure;
  }
  // eslint-disable-next-line no-restricted-properties
  const d = Math.pow(10, decimals);
  return (parseInt(figure * d) / d).toFixed(decimals);
};

export const userDetails = (user) => {
  const data = {
    id: user?._id,
    email: user?.email,
    meta: user?.meta,
    name: user?.name,
    oAuthType: user?.oAuthType,
    picture: user?.picture,
    sPlan: user?.sPlan,
    stats: user?.stats,
    subscription: user?.subscription,
    wallet: user?.wallet,
  };
  return data;
};

export const identifyUserHubspot = (userInfo) => {
  const user = userDetails(userInfo);
  window._hsq?.push([
    'identify',
    {
      email: user?.email,
    },
  ]);
};
/**
 * Check if the string is a URL
 *
 * @param {string}
 * @returns {boolean}
 */
export const isURL = (string = '') => {
  try {
    const u = new URL(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const additionalSeatsPrincing = (
  planId,
  seats,
  additionalSeatsPrice,
  isAnnualPlan
) => {
  const pricePerSeat = isAnnualPlan
    ? additionalSeatsPrice?.[planId]?.yearly
    : additionalSeatsPrice?.[planId]?.monthly;
  const total = seats * pricePerSeat;
  return total.toFixed(1);
};

export const onboardingUserTrack = (onboardingAnswers) => {
  const modifiedOnboardingAnswers = { ...onboardingAnswers };
  const question1 = [
    'Write short copy for my emails, banners, ads, and more',
    'Just want to write casual content',
    'Increase my website’s organic traffic and conversion',
    'Optimize my old and new page content',
  ];
  const question2 = ['Content Hobbyist', 'SEO Copywriter', 'Content Marketer'];
  if (
    modifiedOnboardingAnswers.businessOwner ||
    modifiedOnboardingAnswers.purpose === question1[2]
  ) {
    modifiedOnboardingAnswers.track = 'SaaS';
  }
  // if question 1 has other
  else if (
    modifiedOnboardingAnswers.other1 ||
    modifiedOnboardingAnswers.purpose === '-' ||
    !question1.includes(modifiedOnboardingAnswers.purpose)
  ) {
    if (
      modifiedOnboardingAnswers?.role === question2[0] ||
      modifiedOnboardingAnswers?.role === question2[2] ||
      modifiedOnboardingAnswers.other2
    ) {
      modifiedOnboardingAnswers.track = 'AI Writer';
    } else if (modifiedOnboardingAnswers?.role === question2[1]) {
      modifiedOnboardingAnswers.track = 'SEO';
    }
  }
  // if question 1 has 'Write Short Copy' or 'Just want to write'
  else if (
    modifiedOnboardingAnswers.purpose === question1[0] ||
    modifiedOnboardingAnswers.purpose === question1[1]
  ) {
    if (
      modifiedOnboardingAnswers?.role === question2[0] ||
      modifiedOnboardingAnswers?.role === question2[2] ||
      modifiedOnboardingAnswers?.role === question2[1] ||
      modifiedOnboardingAnswers.other2
    ) {
      modifiedOnboardingAnswers.track = 'AI Writer';
    }
  }
  // if question 1 has 'Optimize my old and new page content'
  else if (modifiedOnboardingAnswers.purpose === question1[3]) {
    modifiedOnboardingAnswers.track = 'SEO';
  }
  delete modifiedOnboardingAnswers.businessOwner;
  delete modifiedOnboardingAnswers.other1;
  delete modifiedOnboardingAnswers.other2;
  delete modifiedOnboardingAnswers._id;
  delete modifiedOnboardingAnswers.updatedAt;
  delete modifiedOnboardingAnswers.createdAt;
  delete modifiedOnboardingAnswers.user;
  delete modifiedOnboardingAnswers.__v;
  console.log('user-track-here->>>', modifiedOnboardingAnswers);
  return modifiedOnboardingAnswers;
};

export const plansId = (id) => {
  if (['6343c0de880f330fb75d8688', '633fb9f94241cc0eb0e5e433'].includes(id)) {
    return 'Creator';
  }
  if (['6343bfdbb5c89f0f9d4b30bd', '633442eeb4efba0d9c17b5f3'].includes(id)) {
    return 'Rocket Plan';
  }
  if (['633fbdb34241cc0eb0e5e708', '6343c168b5c89f0f9d4b33ea'].includes(id)) {
    return 'Professional';
  }
  return false;
};

export const downloadImage = (imageUrl) => {
  const url = imageUrl;
  // if (!url.includes('/Images/')) {
  //   const lastSlashIndex = url.lastIndexOf('/');
  //   url = `${url.substring(0, lastSlashIndex + 1)}Images/${url.substring(
  //     lastSlashIndex + 1
  //   )}`;
  // }
  // imageUrl = url;
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const img = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = img;
      // the filename you want
      a.download = 'image';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(img);
    })
    .catch(() => alert('An error sorry'));
};

export function imageToBlob(imageURL) {
  const img = new Image();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  img.crossOrigin = '';
  img.src = imageURL;
  return new Promise((resolve) => {
    img.onload = function () {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0);
      c.toBlob(
        (blob) => {
          // here the image is a blob
          resolve(blob);
        },
        'image/png',
        0.75
      );
    };
  });
}
export const onboardingUserTrackv2 = (
  onboardingAnswers = {
    purpose: [],
    role: [],
  }
) => {
  const { purpose, role } = onboardingAnswers;
  const rocketValues = [
    'Small Company',
    'Large Company',
    'Team Lead',
    'Founder',
  ];
  const performerValues = ['Grow my SEO', 'Engage More', 'Convert More'];
  const agencyValues = ['Keyword Research', 'Manage Client SEO', 'SEO Content'];

  // const performerValues2 = ['It’s just me', 'Content Creator', 'Other'];
  const otherValue = JSON.stringify(['Other']);
  if (role.length === 0 && purpose.length === 0) {
    return '';
  }
  if (purpose.length === 0 && JSON.stringify(role) === otherValue) {
    return 'Other';
  }
  if (role.includes('Agency')) {
    return 'Agency';
  }
  if (role.includes('Student')) {
    return 'Student';
  }
  if (role.some((val) => rocketValues.includes(val))) {
    return 'Rocket';
  }
  if (purpose.some((val) => agencyValues.includes(val))) {
    return 'Agency';
  }
  if (purpose.some((val) => performerValues.includes(val))) {
    return 'Performer';
  }
  // if (purpose.some((val) => performerValues2.includes(val))) {
  //   return 'Performer';
  // }
  return 'Creator';
};

export const getDocIdFromUrl = () => {
  if (typeof window !== 'object') return null;

  const pathname = window?.location?.pathname;

  const docId = pathname.split('/').pop();

  // check if it's the new document page
  if (docId !== 'long-form-writing') return docId;

  return null;
};

export const sanitizeOnboardingData = (data) => {
  // eslint-disable-next-line
  let modifiedOnboardingAnswers = { ...data };
  delete modifiedOnboardingAnswers._id;
  delete modifiedOnboardingAnswers.updatedAt;
  delete modifiedOnboardingAnswers.createdAt;
  delete modifiedOnboardingAnswers.user;
  delete modifiedOnboardingAnswers.__v;
  delete modifiedOnboardingAnswers.track;
  return modifiedOnboardingAnswers;
};

export const isInPWA = () =>
  typeof window === 'object' &&
  window.matchMedia('(display-mode: standalone)').matches;

export const capturingPostHogEvent = (text, data = null) => {
  posthog.capture(text, data);
};

export function parseToDDMMYYYY(date) {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

export function santizeValidUrls(value) {
  // split on each new line
  if (value === '') return [];
  const valid = value?.filter((word) => {
    // validate each url (regex)
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return word !== '' && word !== ' ' && urlRegex.test(word);
  });

  return valid;
}

export function renderSvg({
  rootPath,
  alt = 'icon',
  theme,
  includeThemes = true,
  ...rest
}) {
  /**
   *
   * @param {string} rootPath - path to svg file (don't include .svg)
   * @param {string} alt - alt text for img
   * @param {string} theme - rest of props
   * @param {object} rest - rest of props
   * @returns {JSX.Element} - img element
   */
  //*  RENDERS SVG BASED ON THEME
  // eslint-disable-next-line no-param-reassign

  if (!includeThemes) {
    return <img src={`${rootPath}.svg`} alt={alt} {...rest} />;
  }

  let _theme = theme;
  if (!theme) _theme = localStorage.getItem('theme') ?? 'light';

  const iconPath =
    _theme === 'light'
      ? `${rootPath}-light.svg`
      : _theme === 'dark'
      ? `${rootPath}-dark.svg`
      : `${rootPath}-light.svg`;

  return <img src={iconPath} alt={alt} {...rest} />;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getHighlightedPlagText({ aiCheck, plagiarismCheck }) {
  /**
   * @param {Array} aiCheck - array of objects containing text and isAI
   * @param {Array} plagiarismCheck - array of objects containing text and isAI
   * @returns {Array} - array of paragraphs with highlighted text
   * @description - highlights text that is plagiarized
   */

  const paragraphs = [];

  if (!aiCheck) return [];

  for (const section of aiCheck) {
    const { text, isAI } = section;

    // if there is no plagiarism check, just push the text
    if (!plagiarismCheck) {
      paragraphs.push({
        text: [text],
        highlighted: isAI,
      });
      // eslint-disable-next-line no-continue
      continue;
    }

    const collectedChunks = plagiarismCheck
      ?.map((source) => {
        const { matchedResults } = source;
        const chunks = matchedResults?.map((match) => match.chunk);
        return chunks;
      })
      ?.flat();

    const scanned = text.replace(
      new RegExp(collectedChunks.join('|'), 'gi'),
      (match) => `|${match}|`
    );

    // eslint-disable-next-line no-shadow
    const final = scanned.split('|').map((text, i) => {
      if (collectedChunks && collectedChunks?.includes(text)) {
        return (
          <u
            key={i}
            style={{ fontWeight: '600', cursor: 'pointer' }}
            className='plag-bolded-text'
          >
            {text}
          </u>
        );
      }
      return text;
    });

    paragraphs.push({
      text: final,
      highlighted: Boolean(isAI),
    });
  }

  return paragraphs;
}

export function copyImageToClip(src, dispatch, setCustomAlert) {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');

  function setCanvasImage(path, cb) {
    img.onload = function () {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0);
      c.toBlob((blob) => {
        cb(blob);
      }, 'image/png');
    };
    img.src = src;
  }

  document.body.focus();
  setCanvasImage(document.querySelector('#image')?.src, (imgBlob) => {
    navigator.clipboard
      // eslint-disable-next-line no-undef
      .write([new ClipboardItem({ 'image/png': imgBlob })])
      .then((e) => {
        dispatch(
          setCustomAlert({
            message: 'Image copied to clipboard',
            state: true,
            type: 'success',
            position: 'top-right',
          })
        );
        console.log('Image copied to clipboard');
      })
      .catch((e) => {
        console.log(e);
      });
  });
}

export const restrictCreatingTeam = (plan) => {
  if (plan?.isCustomPlan || plan?.quota?.users === 1) {
    return false;
  }
  return true;
};
export const observeChildEl = (parentEl, className, callback) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation?.type === 'childList') {
        const targetEl =
          Array.from(mutation?.addedNodes)?.find((node) =>
            node?.classList?.contains(className)
          ) || null;

        if (targetEl) {
          callback();
        }
      }
    });
  });
  observer.observe(parentEl, { childList: true });
};

export const isInternalUser = (email) => {
  const internalDomain = 'inkco.co';
  // Extract the domain from the email address
  const emailDomain = email?.split('@')[1];
  // Compare the extracted domain to the desired domain
  if (emailDomain === internalDomain) {
    return true;
  }
  return false;
};

export const getImageGeneratorUrl = () => imageGenerationURL;

export function sortByCreationDate(arr) {
  if (!arr) return [];

  return arr.sort((a, b) => {
    const aDate = new Date(a?.csOutputs?.[0]?.createdAt);
    const bDate = new Date(b?.csOutputs?.[0]?.createdAt);

    return bDate - aDate;
  });
}

// Check if the data is an object and has at least one property
export const isValidObj = (data = {}) => {
  const isObj = Object.prototype.toString.call(data) === '[object Object]';

  if (isObj) {
    const isValid = Object.keys(data).length > 0;

    if (isValid) return true;
  }

  return false;
};

export const getUser = () => {
  const user = clientStorage.get('user');

  if (isValidObj(user)) {
    return user;
  }

  return {};
};

export const getTeam = () => {
  const userAccounts = clientStorage.get('userAccounts');
  const team = userAccounts?.find(
    (item) =>
      (item?.accountType === 'team' || item?.accountType === 'personal-team') &&
      item?.userPlan?.isActive
  );

  if (isValidObj(team)) {
    return team;
  }

  return {};
};

export function hasTeam() {
  const team = getTeam();

  if (isValidObj(team)) {
    return true;
  }

  return false;
}

export const prompts = [
  'espresso machine, coffee bar, coffee beans scattered around, shot glasses, soft light, highly detailed',
  'double layer chocolate drip cake with chocolate bar, nuts, and wafer stick toppers on top of a counter',
  'headset on top of books with window background, soft lights coming from the window, photo realistic',
  'castle, light, surreal, fairy tale, greenery, sunlight, dreamlike, highly detailed, daylight, river',
  'a beautiful floral arrangement in the center of the table, a beautiful table, in a gourmet restaurant, service on a luxury plate, gold cutlery, exceptional products, such as caviar, scallops, langoustine, colorful vegetables, plate that whets the appetite, symphony of flavors textures and colors, dish is well highlighted',
  'coffee packaging, latte, photo realistic, hyper reaslistic',
  'underwater, corals, bottom of the ocean, aquatic life, marine animals, sea turtle, blue ocean, cinematic light, soft light',
  'sunset by the sea, soft waves, white golden sands, cloudy purple sky',
  'inn, bed and breakfast, cozy landscape, bed and breakfast signage, cinematic light',
  'mansion, landscape, hyper realistic, intricate, photo realistic, soft light',
  'caramel machiato, coffee, latte, ultra real',
  'gourmet chocolates, dark chocolate, white chocolate, ferrero rocher, cadbury, hyper realistic, photo realistic, macroshot',
  'airplane, jumbo jet, taking off, clear sky',
  'sneakers, highly detailed, intricate, spotlight, marketing ad',
  'futuristic rocket, rocket ship launching, above earth, up in the night sky, outer space, cinematic light, rocket ship going to space, rocket in the sky above the ocean, night sky, bright light, intricate, highly detailed',
  'laptop computer on top of an office table, photo realistic, hyper realistic, detailed, soft light',
  'Myanmar, 10 thousand temples, sunset, hot air balloons, cinematic light, soft light, night, intricate, darkness, bright lights, soft lights, highly detailed, intricate, elaborate',
];

export function saveInvitationTokenToLocalStorage(token) {
  if (token) {
    localStorage.setItem('invitationToken', token);
  }
}

export function getInvitationTokenFromLocalStorage() {
  return localStorage.getItem('invitationToken');
}

// get all query params from url as object
export function getQueryParams() {
  if (typeof window !== 'object') return {};

  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

export const passedPollingTimeWithoutResults = (createdAt, status) =>
  moment().subtract(30, 'minutes').isAfter(moment(createdAt)) &&
  status !== 'done';
