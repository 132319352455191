import { request } from '@shared/verb';

// export const GET_ALL_PROJECTS = () =>
//   new Promise((resolve, reject) => {
//     request('/v2.0/projects', 'GET')
//       .then((res) => resolve(res.data))
//       .catch((err) => {
//         reject(err);
//       });
//   });
export const GET_ALL_PROJECTS = async () => {
  try {
    const projects = await request('/v2.0/projects', 'GET');
    return projects.data;
  } catch (error) {
    return error;
  }
};

export const ADD_PROJECT = async (e) => {
  try {
    const projects = await request('/v2.0/projects', { name: e.name }, 'POST');
    return projects.data;
  } catch (error) {
    return error;
  }
};

export const UPDATE_PROJECT = (data = {}, id) =>
  new Promise((resolve, reject) => {
    request(`/v2.0/projects/${id}`, data, 'PUT')
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err);
      });
  });
export const sortData = (data = []) =>
  data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

export const GET_USER_ACCESS = async () => {
  try {
    const user = await request('/v2.0/users/me', 'GET');
    return user.data;
  } catch (error) {
    return error;
  }
};
