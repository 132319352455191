import { actions } from '@redux/actions/inkAddon';

const initialState = {
  fileImported: '',
  isAiWriting: {
    isAiWriting: false,
    hasLastAction: false,
    aiShieldValue: { aiShieldValue: 100, remaining: 0 },
  },
  hasAILastAction: 0,
  showAIWordsModal: false,
  aiData: {
    aiCommandText: '',
  },
  rewritingStatus: false,
  rewriteOptionSwitching: null,
  metaDataChanged: '',
  docSaved: '',
  contentShieldStatus: false,
  aiToolSidebarStatus: {},
  resetComposerText: 0,
  seoAddonData: {},
  keyphraseViewReady: false,

  leftSidebarOpen: false,
  leftInkAddonReady: false,
  leftInkAddonFailed: false,
  leftInkAddonFrame: null,
  leftActiveSidebar: '',

  rightSidebarOpen: false,
  rightInkAddonReady: false,
  rightInkAddonFailed: false,
  rightInkAddonFrame: null,
  rightActiveSidebar: '',
};

const inkAddonReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_AI_TOOL_SIDEBAR_STATUS:
      return {
        ...state,
        aiToolSidebarStatus: {
          ...(state.aiToolSidebarStatus || {}),
          ...(data || {}),
        },
      };
    case actions.RESET_COMPOSER_TEXT:
      return {
        ...state,
        resetComposerText: data || 0,
      };
    case actions.SET_FILE_IMPORTED:
      return { ...state, fileImported: data };

    case actions.SET_AI_WRITING_STATUS:
      return { ...state, isAiWriting: data };

    case actions.SET_AI_WORDS_MODAL_STATUS:
      return { ...state, showAIWordsModal: data };

    case actions.SET_REWRITE_STATUS:
      return { ...state, rewritingStatus: data };

    case actions.SET_REWRITE_OPTION_SWITCHING:
      return { ...state, rewriteOptionSwitching: data };

    case actions.SET_AI_HAS_LAST_ACTION:
      return { ...state, hasAILastAction: data };

    case actions.SET_METADATA_CHANGED:
      return { ...state, metaDataChanged: data };

    case actions.SET_DOC_SAVED:
      return { ...state, docSaved: data };

    case actions.SET_CONTENT_SHIELD_STATUS:
      return { ...state, contentShieldStatus: data };

    case actions.SET_SEO_ADDON_DATA:
      return { ...state, seoAddonData: data };

    case actions.SET_KEYPHRASE_VIEW_READY:
      return { ...state, keyphraseViewReady: data };

    // Left sidebar
    case actions.SET_LEFT_SIDEBAR_OPEN:
      return { ...state, leftSidebarOpen: data };

    case actions.SET_LEFT_INK_ADDON_READY:
      return { ...state, leftInkAddonReady: data };

    case actions.SET_LEFT_INK_ADDON_FAILED:
      return { ...state, leftInkAddonFailed: data };

    case actions.SET_LEFT_INK_ADDON_FRAME:
      return { ...state, leftInkAddonFrame: data };

    case actions.SET_LEFT_ACTIVE_SIDEBAR:
      return { ...state, leftActiveSidebar: data };

    // Right sidebar
    case actions.SET_RIGHT_SIDEBAR_OPEN:
      return { ...state, rightSidebarOpen: data };

    case actions.SET_RIGHT_INK_ADDON_READY:
      return { ...state, rightInkAddonReady: data };

    case actions.SET_RIGHT_INK_ADDON_FAILED:
      return { ...state, rightInkAddonFailed: data };

    case actions.SET_RIGHT_INK_ADDON_FRAME:
      return { ...state, rightInkAddonFrame: data };

    case actions.SET_RIGHT_ACTIVE_SIDEBAR:
      return { ...state, rightActiveSidebar: data };
    default:
      return state;
  }
};

export default inkAddonReducer;
