import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
// import classes from 'components/Input/input.module.css';
import { useTheme } from 'next-themes';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { isEditorPage } from '@editor/helpers/';

const StyledTextField = styled(TextField)(
  (props) => `
border-radius: 5px;

.MuiInputBase-input {
  border-radius: 6px;
  background: transparent !important;
  color: ${props.theme === 'dark' ? 'white !important' : 'inherit !important'};
  -webkit-text-fill-color: ${
    props.theme === 'dark' ? 'white !important' : 'inherit !important'
  };
}

label.Mui-focused {
  color: green;
}

.MuiInput-underline:after {
  border-radius: 5px;
  border-bottom-color: #ebebef;
}

.MuiOutlinedInput-root {
  color: ${props?.themeStyles?.color} !important;
  width: 100%;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  border-radius: 5px;
  background-color: ${props?.themeStyles?.background}!important;

  &:not(.Mui-error) fieldset {
    border-color: ${
      props.theme === 'dark' ? '#31313F !important' : '#EBEBEF !important'
    };
    border-radius: 5px;
    border-color: ${props.theme === 'dark' ? '#F5F5F5' : '#505876'};
      }
  
  &:not(.Mui-error):hover fieldset {
    border-color: ${props.theme === 'dark' ? '#F5F5F5' : '#505876'};
  }

  &:not(.Mui-error):hover fieldset {
    border-color: ${
      props.theme === 'dark' ? '#31313F !important' : '#EBEBEF !important'
    };
    border-radius: 5px;
  }
}

.MuiFormControl-root {
  width: 100%;
}

.MuiOutlinedInput-root.Mui-focused:not(.Mui-error) fieldset {
  border-color: ${
    props.theme === 'dark' ? '#505876 !important' : '#bcc2d4 !important'
  };
  border-radius: 5px;
}

&.roundedAI {
  border-radius: 21px;
  margin-top: 1px;
  input {
    padding: 10px 8px;
    margin-top: 1px;
    padding-left: 17px;
    height: calc(100% - 2px);
  }

  fieldset {
    top: -13px;
    width: 604px;
    border: none;
    padding: 0;
    line-height: 42px;
    border-width: 1px;
    margin-right: -150px;
    border-radius: 21px;
  }

  .MuiOutlinedInput-root {
    border-radius: 21px;
  }

  #ai-command-input {
    max-width: 600px;
    line-height: 22px;
  }
}

&.shiftedright {
  input {
    padding-left: 50px;
    padding-right: 150px;
  }
}

&.borderLess {
  color:  ${props.theme === 'dark' ? '#fff' : '#505876'};
  border-radius: 5px;

  .MuiOutlinedInput-root {
    color: ${props.theme === 'dark' ? '#fff' : '#505876'};
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    border-radius: 5px;
  }

  &.MuiInputBase-root:not(.Mui-error) fieldset {
    color: ${props.theme === 'dark' ? '#fff' : '#505876'};
    border: none;
    border-color: none !important;
  }

  &:hover fieldset {
    color: ${props.theme === 'dark' ? '#fff' : '#505876'};
    border: none;
    border-color: none !important;
  }

  &.Mui-focused fieldset {
    color: ${props.theme === 'dark' ? '#fff' : '#505876'};
    border: none;
    border-color: none !important;
  }
}
`
);

function InputField({
  isRoundedAI = false,
  style = {},
  getValue,
  handleFocus,
  value = '',
  placeholder = 'Enter Your Text',
  onKeyDown,
  name,
  maxLength,
  maxWords,
  autoFocus = false,
  disabled = false,
  error = false,
  multiline = false,
  required = false,
  size = 'large',
  rows,
  icon = {},
  borderLess = false,
  variant = 'outlined',
  helperText,
  onBlur,
  topLabel,
  id,
  extraClass = '',
  helperTextClasses = '',
  inputRef,
  type = 'text',
  inputProps = {},
  // "addValueStateFallback" will add a fallback to the state if the value prop is not provided
  addValueStateFallback = true,
  ...rest
}) {
  const [inputValue, setInputValue] = useState('');
  const [currentType, setCurrentType] = useState(type);
  const defaultInputHandler = (event) => {
    setInputValue(event.target.value);
  };

  const defaultFocusHandler = () => null;
  const { theme } = useTheme();

  const isColorblind = useSelector((state) => state.editor?.isColorblind);

  let count;
  if (maxLength) {
    count = value.length;
  } else {
    count = value ? value.trim().split(/\s+/).length : 0;
  }
  const maxCount = maxLength || maxWords;
  const maxWordValidation =
    maxCount === count && inputValue[inputValue.length - 1] === ' '
      ? maxCount
      : null;

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const words = pastedText.trim().split(' ');
    if (maxWords) {
      const underLimitWords = words.slice(
        0,
        maxWords - inputValue?.trim().split(' ').length
      );
      const text = underLimitWords.join(' ');
      // setInputValue(`${inputValue} ${text}`);
      event.target.value = text;
    } else {
      const limitedLetters = pastedText
        .trim()
        .slice(0, maxLength - inputValue.length);
      // setInputValue(`${inputValue} ${limitedLetters}`);
      event.target.value = limitedLetters;
    }
  };

  const togglePasswordVisibility = useCallback(() => {
    setCurrentType((prevType) =>
      prevType === 'password' ? 'text' : 'password'
    );
  }, []);
  // const handleMultiPaste = (event) => {
  //   event.preventDefault();
  //   const pastedText = event.clipboardData.getData('text');
  //   const words = pastedText;
  //   // if (words.length > maxCount) {
  //   //   words.slice(0, maxCount);
  //   // }
  //   setInputValue(words.slice(0, maxCount));
  // };
  // console.log(maxLength, name);

  /*
   * To maintain consistency across the PWA, we cannot use CSS variables in this component. At the moment, CSS variables are only valid for the Editor page.
   */
  const themeStyles = () => {
    // for colorblind mode
    if (isEditorPage() && isColorblind) {
      return { background: '#424242', color: '#ffffff' };
    }
    // for dark theme
    if (theme === 'dark') {
      return { background: '#2D2D39', color: 'green' };
    }

    // default for light theme
    return { background: '#F5F5F7', color: '#505876' };
  };

  return (
    <>
      {topLabel ? (
        <div className='font-bold pb-1 dark:text-white text-custom-font-color'>
          {topLabel}
        </div>
      ) : null}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <StyledTextField
          theme={theme}
          className={`${borderLess ? 'borderLess' : ''}
        ${isRoundedAI ? 'roundedAI' : ''} ${
            extraClass?.indexOf('shiftedright') > -1 ? 'shiftedright' : ''
          }`}
          size={size}
          required={required}
          multiline={multiline}
          error={error}
          autoFocus={autoFocus}
          fullWidth
          type={currentType}
          disabled={disabled}
          variant={variant}
          helperText={helperText}
          // onPaste={handlePaste}
          InputProps={
            inputProps || {
              inputProps: {
                maxLength: maxWords
                  ? maxWordValidation
                  : count === maxCount && maxCount,
                autoComplete: 'off',
                style,
              },
              startAdornment:
                icon?.position === 'start' ? (
                  <InputAdornment position='start'>{icon?.svg}</InputAdornment>
                ) : null,
              endAdornment:
                icon?.position === 'end' ? (
                  <InputAdornment position='end'>{icon?.svg}</InputAdornment>
                ) : null,
              style,
            }
          }
          onChange={
            getValue || inputProps?.onChange
              ? (event) => {
                  getValue?.(event);
                  inputProps?.onChange?.(event);
                  if (addValueStateFallback) {
                    setInputValue(event.target?.value);
                  }
                }
              : (event) => {
                  defaultInputHandler(event);
                }
          }
          onFocus={handleFocus || defaultFocusHandler}
          onBlur={onBlur}
          name={name}
          rows={rows || null}
          onKeyDown={onKeyDown || null}
          placeholder={placeholder}
          sx={style || null}
          // optional fallback to state if value prop is not provided
          {...(addValueStateFallback ? { value: value ?? inputValue } : {})}
          id={id}
          inputRef={inputRef}
          {...rest}
          themeStyles={themeStyles()}
        />

        {type === 'password' && (
          <Box>
            <IconButton
              onClick={togglePasswordVisibility}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#A9ADBC',
              }}
            >
              {currentType === 'password' ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </Box>
        )}
      </Box>

      {(maxLength || maxWords) && helperText && (
        <div
          className={`text-right text-xs pt-2 ${helperTextClasses}`}
          style={{ color: '#A9ADBC' }}
        >
          {count}/{maxCount}
        </div>
      )}
    </>
  );
}

InputField.propTypes = {
  isRoundedAI: PropTypes.bool,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  maxWords: PropTypes.number,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  borderLess: PropTypes.bool,
  handleFocus: PropTypes.func,
  multiline: PropTypes.bool,
  icon: PropTypes.object,
  variant: PropTypes.string,
  helperText: PropTypes.bool,
  onBlur: PropTypes.func,
  topLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  id: PropTypes.string,
  extraClass: PropTypes.string,
  helperTextClasses: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  type: PropTypes.string,
  inputProps: PropTypes.object,
  addValueStateFallback: PropTypes.bool,
};

export default InputField;
