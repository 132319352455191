let host = [];
if (typeof window !== 'undefined') {
  host = window.location.host.split('.');
}
const [APP_CLIENT] = host;
let state = '';
let url = '';
let baseUrlV2 = '';
let env = '';
let webLoginUrl = '';
let isDeployed = true;
let signupState = '';
let stripeKey = '';
let tracesSampleRate = 1.0;
let loginPageUrl = '';
let GAKey = '';
let INK_PRO_ID = '';
let INK_PRO_UNLIMITED_ID = '';
let INK_STARTER_ID = '';
let INK_PRO_SEO_ID = '';
let INK_TEAM_SEO_UNLIMTED_ID = '';
let INK_STUDENT_ID = '';
let INKFORALLURL = '';
let APP_VERSION = '';
let lambdaEventURL = '';
let BILLINK_INKFORALL_URL = '';
let analyticsURL = '';
let s3KeywordClusterBucketURL = '';
let productIds = {};
let imageGenerationURL = '';
let posthogTestKey = '';
let posthogProdKey = '';

switch (APP_CLIENT) {
  case 'app-test':
    webLoginUrl = 'https://login-test.ink.seo.app';
    url = 'https://api-test.ink.seo.app/core/api';
    baseUrlV2 = 'https://api-v2-test.ink.seo.app';
    loginPageUrl = 'https://auth-test.inkforall.com';
    imageGenerationURL = 'https://project-assets-test.ink.seo.app';
    state = 'ink-test';
    env = 'test';
    signupState = 'ink-test-signup';
    isDeployed = true;
    stripeKey = 'pk_test_1qLVaVpCqI5j8mY0bf6G0Jdi00KyJtqewK'; // test
    tracesSampleRate = 1.0;
    GAKey = 'UA-82891932-5';
    INK_PRO_ID = '6090ff93b585c27fd2fd681a';
    INK_PRO_UNLIMITED_ID = '6090fb473bfd4368ee992f1c';
    INK_STARTER_ID = '61765bd50637c20ef26fb133';
    INK_PRO_SEO_ID = '61765cca0637c20ef26fb158';
    INK_TEAM_SEO_UNLIMTED_ID = '61765d940637c20ef26fb17f';
    INK_STUDENT_ID = '61765e4b0637c20ef26fb1a0';
    INKFORALLURL = 'https://app-test.inkforall.com';
    APP_VERSION = '1.0.0';
    lambdaEventURL = 'https://analytics-test.seo.app/api';
    BILLINK_INKFORALL_URL = 'https://billing-test.inkforall.com';
    analyticsURL = 'https://assets-cdn-test.ink.seo.app/das/index.min.js';
    s3KeywordClusterBucketURL = 'https://assets-cdn-test.ink.seo.app';
    posthogTestKey = 'phc_PU3WBx5p1PT15K1fbRwLH8BDrVhItJLI2BRUShRcDAh';
    productIds = {
      writer: '6299c7462a03ab6ccc7f4931',
      optimizer: '6299c88a2a03ab6ccc7f4b9a',
      assistant: '6299c9242a03ab6ccc7f4d1f',
      suite: '62a97d98509e59725eb32da1',
      'assistant-student': '6299c9632a03ab6ccc7f4dd2',
      planner: '62c57cb0d0ad4543bc19c6d8',
      'seo-rocket': '633442eeb4efba0d9c17b5f3',
      Creator: '633fb9f94241cc0eb0e5e433',
      professional: '63b662eaede8690ebc48de92',
      enterprise: '63b66373ede8690ebc48deb0',
      agency: '63da0722ef18f90eedd47285',
    };
    // stripeKey = 'pk_live_YEcl09llJtYDVw80pUJ6VCfu0073CbUGZb'; // live
    break;

  case 'app-stage':
    webLoginUrl = 'https://login-stage.ink.seo.app';
    url = 'https://api-stage.ink.seo.app/core/api';
    baseUrlV2 = 'https://api-v2-stage.ink.seo.app';
    loginPageUrl = 'https://auth-stage.inkforall.com';
    state = 'ink-stage';
    env = 'stage';
    imageGenerationURL = 'https://project-assets-test.ink.seo.app';
    signupState = 'ink-stage-signup';
    isDeployed = true;
    stripeKey = 'pk_test_u8OWdWFBmVTsk1pX55u2bRIv002IvPPGV4Iv002IvPPGV4';
    tracesSampleRate = 1.0;
    GAKey = 'UA-82891932-5';
    INK_PRO_ID = '6090ff93b585c27fd2fd681a';
    INK_PRO_UNLIMITED_ID = '6090fb473bfd4368ee992f1c';
    INK_STARTER_ID = '61765bd50637c20ef26fb133';
    INK_PRO_SEO_ID = '61765cca0637c20ef26fb158';
    INK_TEAM_SEO_UNLIMTED_ID = '61765d940637c20ef26fb17f';
    INK_STUDENT_ID = '61765e4b0637c20ef26fb1a0';
    INKFORALLURL = 'https://app-stage.inkforall.com';
    APP_VERSION = '1.0.0';
    lambdaEventURL = 'https://analytics-stage.seo.app/api';
    BILLINK_INKFORALL_URL = 'https://billing-stage.inkforall.com';
    analyticsURL = 'https://assets-cdn-stage.ink.seo.app/das/index.min.js';
    s3KeywordClusterBucketURL = 'https://assets-cdn-stage.ink.seo.app';
    posthogTestKey = 'phc_PU3WBx5p1PT15K1fbRwLH8BDrVhItJLI2BRUShRcDAh';
    productIds = {
      writer: '62c290c55136a50f9d2cc20c',
      optimizer: '62c29040cade3f0f99d47b3f',
      assistant: '62c291965136a50f9d2cc39e',
      suite: '62c29132cade3f0f99d47c89',
      'assistant-student': '6299c9632a03ab6ccc7f4dd2',
      planner: '62c57cb0d0ad4543bc19c6d8',
      'seo-rocket': '633442eeb4efba0d9c17b5f3',
      Creator: '633fb9f94241cc0eb0e5e433',
      professional: '63b662eaede8690ebc48de92',
      enterprise: '63b66373ede8690ebc48deb0',
      agency: '63da0722ef18f90eedd47285',
    };
    break;

  case 'app':
    webLoginUrl = 'https://login.ink.seo.app';
    url = 'https://api.ink.seo.app/core/api';
    baseUrlV2 = 'https://api-v2.ink.seo.app';
    loginPageUrl = 'https://auth.inkforall.com';
    state = 'ink';
    env = 'prod';
    signupState = 'ink-signup';
    imageGenerationURL = 'https://project-assets.ink.seo.app';
    isDeployed = true;
    stripeKey = 'pk_live_YEcl09llJtYDVw80pUJ6VCfu0073CbUGZb';
    tracesSampleRate = 0.25;
    GAKey = 'UA-82891932-2';
    INK_PRO_ID = '60a63fdf1f2fcb3cdf72ec08';
    INK_PRO_UNLIMITED_ID = '60a63e47b13b0808d915e37d';
    INK_STARTER_ID = '61765bd50637c20ef26fb133';
    INK_PRO_SEO_ID = '61765cca0637c20ef26fb158';
    INK_TEAM_SEO_UNLIMTED_ID = '61765d940637c20ef26fb17f';
    INK_STUDENT_ID = '61765e4b0637c20ef26fb1a0';
    INKFORALLURL = 'https://app.inkforall.com';
    APP_VERSION = '1.0.0';
    lambdaEventURL = 'https://analytics.seo.app/api';
    BILLINK_INKFORALL_URL = 'https://billing.inkforall.com';
    analyticsURL = 'https://assets-cdn.ink.seo.app/das/index.min.js';
    s3KeywordClusterBucketURL = 'https://assets-cdn.ink.seo.app';
    posthogProdKey = 'phc_Di0JgopDz0T0I9xVgh3ggHxJ14fHr0fnbaRO2tFVMq';
    productIds = {
      writer: '62c290c55136a50f9d2cc20c',
      optimizer: '62c29040cade3f0f99d47b3f',
      assistant: '62c291965136a50f9d2cc39e',
      suite: '62c29132cade3f0f99d47c89',
      'assistant-student': '6299c9632a03ab6ccc7f4dd2',
      planner: '6306224d7367b80f51b5d78b',
      'seo-rocket': '6343bfdbb5c89f0f9d4b30bd',
      Creator: '6343c0de880f330fb75d8688',
      professional: '63b6673407dee40f3e702837',
      enterprise: '63b66792d0f26f0f79dc2474',
      agency: '63dcb1e5de5e440f65f44625',
    };
    break;

  default:
    webLoginUrl = 'https://login-test.ink.seo.app';
    url = 'https://api-test.ink.seo.app/core/api';
    baseUrlV2 = 'https://api-v2-test.ink.seo.app';
    // url = 'https://ink-api.seo.app/api'; //prod api
    // url = 'http://farazs-mbp.sudofy.com:3001/api'; // local api faraz
    // url = 'http://192.168.250.121:3001/api'; // local api rohail
    // url = 'http://f1b341a8b74e.ngrok.io/api'; // work from home api faraz
    state = 'localhost-app';
    env = 'local';
    imageGenerationURL = 'https://project-assets-test.ink.seo.app';
    loginPageUrl = 'https://auth-test.inkforall.com';
    signupState = 'localhost-app-signup';
    isDeployed = false;
    // isDeployed = true;
    stripeKey = 'pk_test_1qLVaVpCqI5j8mY0bf6G0Jdi00KyJtqewK';
    tracesSampleRate = 1.0;
    GAKey = 'UA-82891932-5';
    INK_PRO_ID = '6090ff93b585c27fd2fd681a';
    INK_PRO_UNLIMITED_ID = '6090fb473bfd4368ee992f1c';
    INK_STARTER_ID = '61765bd50637c20ef26fb133';
    INK_PRO_SEO_ID = '61765cca0637c20ef26fb158';
    INK_TEAM_SEO_UNLIMTED_ID = '61765d940637c20ef26fb17f';
    INK_STUDENT_ID = '61765e4b0637c20ef26fb1a0';
    INKFORALLURL = 'https://app-test.inkforall.com';
    APP_VERSION = '1.0.0';
    lambdaEventURL = 'https://analytics-test.seo.app/api';
    BILLINK_INKFORALL_URL = 'https://billing-test.inkforall.com';
    // analyticsURL = 'https://assets-cdn-test.ink.seo.app/das/index.min.js';
    s3KeywordClusterBucketURL = 'https://assets-cdn-test.ink.seo.app';
    posthogTestKey = 'phc_PU3WBx5p1PT15K1fbRwLH8BDrVhItJLI2BRUShRcDAh';
    productIds = {
      writer: '6299c7462a03ab6ccc7f4931',
      optimizer: '6299c88a2a03ab6ccc7f4b9a',
      assistant: '6299c9242a03ab6ccc7f4d1f',
      suite: '62a97d98509e59725eb32da1',
      'assistant-student': '6299c9632a03ab6ccc7f4dd2',
      planner: '62c57cb0d0ad4543bc19c6d8',
      'seo-rocket': '633442eeb4efba0d9c17b5f3',
      Creator: '633fb9f94241cc0eb0e5e433',
      professional: '63b662eaede8690ebc48de92',
      enterprise: '63b66373ede8690ebc48deb0',
      agency: '63da0722ef18f90eedd47285',
    };
  // stripeKey = 'pk_live_YEcl09llJtYDVw80pUJ6VCfu0073CbUGZb'; // live
}

const apiConfig = {
  url,
  baseUrlV2,
  webLoginUrl,
  state,
  env,
  isDeployed,
  loginPageUrl,
  signupState,
  tracesSampleRate,
  stripeKey,
  imageGenerationURL,
  APP_CLIENT,
  GAKey,
  INK_PRO_ID,
  INK_PRO_UNLIMITED_ID,
  INK_STARTER_ID,
  INK_PRO_SEO_ID,
  INK_TEAM_SEO_UNLIMTED_ID,
  INK_STUDENT_ID,
  INKFORALLURL,
  APP_VERSION,
  lambdaEventURL,
  BILLINK_INKFORALL_URL,
  analyticsURL,
  s3KeywordClusterBucketURL,
  productIds,
  posthogTestKey,
  posthogProdKey,
};

export default apiConfig;
