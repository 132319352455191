import { request, v2request } from '@shared/verb';
import { setCustomPlanAssigned, setLoggedUser } from '@redux/actions/user';
import {
  setPlans,
  setIsMobile,
  setDrawerActive,
  setProductQueryParams,
} from '../actions/general';

let stripeKey = null;

// export const activateTrialForUsers = (id) => (dispatch) => {
//   request(`/v2.0/users/free-trial`, null, 'post').then(
//     ({ data }) => {
//       dispatch(setCustomPlanAssigned(data.data.customPlans));
//       return data;
//     },
//     (err) => err
//   );
// };

export const activateTrialForUsers = (data) =>
  request(`/v2.0/users/free-trial`, data, 'post').then((res) => res);

export const saveOnboardingData = (data) =>
  request(`/v2.0/users/onboarding-feedback`, { ...data }, 'post').then(
    (res) => res
  );

export const getStripeKey = () =>
  request(`/v2.0/configs/stripe/keys`, null, 'get').then((res) => {
    stripeKey = res.data.data.publish;
  });

export const setStripeKey = () => stripeKey;

export const paymentInvoiceAPI = (id) =>
  request(`/v2.0/paymenthistory/${id}/pdf`, null, 'get').then((res) => res);

export const paymentCheckoutDetails = () => (dispatch) =>
  request(`/v2.0/user-plans/plans/active`, null, 'get').then(
    ({ data }) => {
      dispatch(setCustomPlanAssigned(data.data.customPlans));
      return data;
    },
    (err) => err
  );

// export const setUserDetail = data => {
//   return dispatch => {
//     dispatch(setLoggedUser({ ...data }));
//   };
// };

export const setUserDetail = (data) => (dispatch) => {
  dispatch(setLoggedUser({ ...data }));
};

export const setPricingForContentPlanner = async () => {
  const response = await request(
    `/v2.0/user-plans/content-planner/prices`,
    null,
    'get'
  );
  return response?.data?.data;
};

export const getAdditionalSeatsAndKWPricing = async () => {
  const response = await request(
    `/v2.0/user-plans/additional-items/pricing`,
    null,
    'get'
  );
  return response?.data?.data;
};

export const getFeedbackQuestions = (data = 'UserPlan') =>
  request(`/subscriptions/feedback/questions`, null, 'get', null, {
    qType: data,
  }).then((res) => res);

export const postFeedbackApi = (
  data,
  // otherInput,
  // otherRating,
  subId = null,
  fType
) => {
  const obj = { questions: data, fType };
  if (subId) {
    obj.subscription = subId;
  }
  // if (otherInput) {
  //   obj = { ...obj, other: otherInput };
  // }
  // if (otherRating) {
  //   obj = { ...obj, ratings: otherRating };
  // }
  return request(`/users/add-feedback`, { ...obj }, 'post').then((res) => res);
};

export const postExtensionFeedbackApi = (data) =>
  request(`/v2.0/feedback/extension`, { ...data }, 'post').then((res) => res);

export const temporaryResetToFree = () =>
  request(`/subscriptions/reset`, null, 'get').then((res) => res);

export const deleteAccount = () =>
  request(`/v2.0/users/me`, null, 'delete').then((res) => res);

export const getChangedAmount = (currentPlanId, newPlanSId) =>
  request(
    `/subscriptions/${currentPlanId}/status?planId=${newPlanSId}`,
    null,
    'get'
  ).then((res) => res.data.data);

export const getPlanDetails = (planId) =>
  request(`/user-plans/plans/${planId}/detail`, null, 'get').then(
    (res) => res.data.data
  );

export const getDownloadLink = (platform, queryParams = false) =>
  request(
    `/v2.0/downloads/generate-link?platform=${platform}${
      queryParams ? `&${queryParams}` : ''
    }`,
    null,
    'get'
  ).then((res) => res.data);

export const getPlans = () => async (dispatch) => {
  const response = await request(`/v2.0/user-plans/plans/active`, null, 'get');
  dispatch(setPlans(response.data.data));
  return response;
};

export const presignedURL = (data) =>
  request(
    `/v2.0/ink-score/ink-keyword-clustering/upload-url`,
    { ...data },
    'post'
  ).then((res) => res.data);

export const analyzeFile = (data) =>
  request(
    '/v2.0/ink-score/ink-keyword-clustering/analyze-file',
    { ...data },
    'post'
  ).then((res) => res.data);
export const keywordClustering = (data) =>
  v2request({ path: '/kw-cluster', data: { ...data }, method: 'post' }).then(
    (res) => res.data
  );
export const getKeywordClusteringHistory = () =>
  request('/v2.0/content-planning/keyword-clustering', null, 'get').then(
    (res) => res.data
  );

export const uploadFileToS3 = (url, data) =>
  request(url, { ...data }, 'put', true, null, null, true, {
    'Content-Type': 'text/plain',
  }).then((res) => res.data);

export const getTermsAndConditions = () =>
  request(`/v2.0/terms-conditions/latest`, null, 'get', true).then(
    (res) => res.data
  );

export const setTutorialStatus = () =>
  request(`/v2.0/users/extension-tutorial/done`, null, 'post').then(
    (res) => res
  );

//  Invite Verify and Accept

export const verifyInvite = (code) =>
  request(`/v2.0/invitations/${code}/verify`, null, 'get').then((res) => res);

export const acceptInvite = (code) =>
  request(`/v2.0/invitations/${code}/accept`, null, 'get').then((res) => res);

export const sendDownloadLinkvieEmail = () =>
  request(`/v2.0/users/welcome-email`, null, 'get').then((res) => res);

// general functions
export const setIsMobileRedux = () => (dispatch) => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    dispatch(setIsMobile(true));
  } else {
    dispatch(setIsMobile(false));
  }
};

export const handleDrawerState = (state) => (dispatch) => {
  dispatch(setDrawerActive(state));
};

export const handleProductQueryParams = (state) => (dispatch) => {
  dispatch(setProductQueryParams(state));
};

//* AI AUDIT REQUESTS
export const getAuditResults = async (data = null) => {
  const staticBody = {
    folder: 'Content Shield Outputs',
    sort: {
      key: 'updatedAt',
      order: 'desc',
    },
  };
  return v2request({
    path: `/drive/files`,
    data: {
      ...staticBody,
      ...data,
    },
    method: 'POST',
  }).then((res) => res);
};

export const getContentAuditByID = async (id) =>
  v2request({
    path: `/drive/files/${id}`,
    method: 'GET',
  }).then((res) => res);

export const addAudit = async (data) => {
  const staticBody = {};

  return v2request({
    path: `/content-shield`,
    data: {
      ...data,
      ...staticBody,
    },

    method: 'POST',
  });
};

//* RECIPES REQUESTS
export const saveRecipe = (data) =>
  v2request({
    path: '/drive/files/new',
    method: 'POST',
    data,
  }).then((res) => res);

export const getRecipes = (data) =>
  v2request({
    path: '/drive/files',
    method: 'POST',
    data,
  }).then((res) => res);

export const getRecipesV2 = (data) =>
  v2request({
    path: '/drive/files',
    method: 'POST',
    data,
  }).then((res) => res);

export const getRecipe = (id = '') =>
  v2request({
    path: `/drive/files/${id}`,
    method: 'GET',
  }).then((res) => res);

export const updateRecipe = (id = '', data = {}) =>
  v2request({
    path: `/drive/files/${id}`,
    method: 'PUT',
    data,
  }).then((res) => res);

export const deleteRecipe = (data = {}) =>
  v2request({
    path: '/drive/files',
    method: 'DELETE',
    data,
  }).then((res) => res);

export const likeRecipe = (id = '') =>
  v2request({
    path: `/recipes/like/${id}`,
    method: 'POST',
  }).then((res) => res);

export const unlikeRecipe = (id = '') =>
  v2request({
    path: `/recipes/unlike/${id}`,
    method: 'POST',
  }).then((res) => res);

export const getUserProfile = () =>
  request(`/v2.0/projects/profile`, null, 'get').then((res) => res);

export const updatePublicProfile = (data = {}) =>
  request(`/v2.0/projects/profile`, data, 'put').then((res) => res);

//* API DASHBOARD REQUESTS
export const getApiKeys = () =>
  request(`/v2.0/user-api-keys`, null, 'get').then((res) => res);

export const createApiKey = (data) =>
  request(`/v2.0/user-api-keys`, data, 'post').then((res) => res);

export const deleteApiKey = (id) =>
  request(`/v2.0/user-api-keys/${id}`, null, 'delete').then((res) => res);

export const getFileDocuments = (data) => {
  const staticBody = {
    folder: 'Keyword Research',
    sort: {
      key: 'updatedAt',
      order: 'desc',
    },
  };
  return v2request({
    path: `/drive/files`,
    data: {
      ...staticBody,
      ...data,
    },
    method: 'POST',
  }).then((res) => res);
};

export const getFileDocumentById = (id) =>
  v2request({
    path: `/drive/files/${id}`,
    method: 'GET',
  }).then((res) => res);

export const deleteFiles = (filesIdsArr) =>
  v2request({
    path: `/drive/files`,
    data: {
      ids: filesIdsArr,
    },
    method: 'DELETE',
  });

export const verifyEmail = ({ email, code }) =>
  request(`/v2.0/auth/verify-email`, { email, code }, 'post').then(
    (res) => res
  );
