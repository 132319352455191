import apiConfig from '@config/api.service';
import { userDetails } from '@helpers/utils';
import { initializeStore } from '@redux/store';
import posthog from 'posthog-js';

let userData = null;
const store = initializeStore();
export const initDAS = () => {
  if (window && window.DAS) {
    window.DAS.init({
      url: apiConfig.lambdaEventURL,
      APP_NAME: window.origin.includes('inkforall.com')
        ? 'TEXT_GENERATION'
        : 'USER_PANEL',
      APP_VERSION: apiConfig.APP_VERSION,
    });
  }
};

export const captureOutboundLink = (e, url) => {
  if (window && window.DAS) {
    window.DAS.captureEvent({
      action: 'outbound-link',
      category: 'click',
      label: url,
    });
  }
};

export const captureManualEvent = (data, batch) => {
  if (window && window.DAS) {
    if (batch) {
      window.DAS.captureBatchEvent(data);
    } else {
      window.DAS.captureEvent(data);
    }
  }
};

export const setAppSectionName = (sectionName) => {
  if (window && window.DAS) {
    window.DAS.setAppSection(sectionName);
  }
};

export const identifyUser = (user) => {
  if (window.DAS) {
    userData = {
      id: user._id,
      name: user.name,
      active: user.active,
      admin: user.admin,
      email: user.email,
      cognitoIdentities: user.cognitoIdentities.map((o) => {
        const { name, lastLogin, registeredOn, picture, oAuthType } = o;
        return {
          name,
          lastLogin,
          registeredOn,
          picture,
          oAuthType,
        };
      }),
      urlShortCode: user.urlShortCode,
      lastLogin: user.lastLogin,
      registeredOn: user.registeredOn,
      editorVersion: user.editorVersion || '',
      editorPlatform: user.editorPlatform || '',
      influencerScore: user.influencerScore || 0,
      inkfluencedBy: {
        id: user.inkfluencedBy?._id || '',
        name: user.inkfluencedBy?.name || '',
        active: user.inkfluencedBy?.active || 1,
        admin: user.inkfluencedBy?.admin || false,
        email: user.inkfluencedBy?.email || '',
        userPlan: {
          id: user.inkfluencedBy?.userPlan?._id || '',
          name: user.inkfluencedBy?.userPlan?.name || '',
          yearlyPrice: parseInt(
            user.inkfluencedBy?.userPlan?.price.yearly || 0
          ),
          monthlyPrice: parseInt(
            user.inkfluencedBy?.userPlan?.price.monthly || 0
          ),
          isDefault: user.inkfluencedBy?.userPlan?.isDefault || false,
          quota: {
            articles: user.inkfluencedBy?.userPlan?.quota?.articles || {
              amount: 0,
              unlimited: false,
            },
            users: user.inkfluencedBy?.userPlan?.quota?.users || 0,
            keywords: user.inkfluencedBy?.userPlan?.quota?.keywords || {
              amount: 0,
              unlimited: false,
            },
            inkPoints: user.inkfluencedBy?.userPlan?.quota?.inkPoints || {
              amount: 0,
              topupCriteria: 'daily',
              unlimited: false,
            },
            words: user.inkfluencedBy?.userPlan?.quota?.words || {
              amount: 0,
              topupCriteria: 'daily',
              unlimited: false,
            },
          },
        },
        specialPlan: {
          id: user.inkfluencedBy?.specialPlan?._id || '',
          name: user.inkfluencedBy?.specialPlan?.name || '',
          yearlyPrice: parseInt(
            user.inkfluencedBy?.specialPlan?.price.yearly || 0
          ),
          monthlyPrice: parseInt(
            user.inkfluencedBy?.specialPlan?.price.monthly || 0
          ),
          isDefault: user.inkfluencedBy?.specialPlan?.isDefault || false,
          quota: {
            articles: user.inkfluencedBy?.specialPlan?.quota?.articles || {
              amount: 0,
              unlimited: false,
            },
            users: user.inkfluencedBy?.specialPlan?.quota?.users || 0,
            keywords: user.inkfluencedBy?.specialPlan?.quota?.keywords || {
              amount: 0,
              unlimited: false,
            },
            inkPoints: user.inkfluencedBy?.specialPlan?.quota?.inkPoints || {
              amount: 0,
              topupCriteria: 'daily',
              unlimited: false,
            },
            words: user.inkfluencedBy?.userPlan?.quota?.words || {
              amount: 0,
              topupCriteria: 'daily',
              unlimited: false,
            },
          },
        },
        signupParams: {
          trafficSource: {
            id: user.inkfluencedBy?.signupParams?.trafficSource?._id || '',
            utm_campaign:
              user.inkfluencedBy?.signupParams?.trafficSource?.utm_campaign ||
              '',
            utm_medium:
              user.inkfluencedBy?.signupParams?.trafficSource?.utm_medium || '',
            utm_source:
              user.inkfluencedBy?.signupParams?.trafficSource?.utm_source || '',
            utm_content:
              user.inkfluencedBy?.signupParams?.trafficSource?.utm_content ||
              '',
            utm_term:
              user.inkfluencedBy?.signupParams?.trafficSource?.utm_term || '',
          },
          additionalParams: {
            platform:
              user.inkfluencedBy?.signupParams?.additionalParams?.platform ||
              '',
            ink_visit_id:
              user.inkfluencedBy?.signupParams?.additionalParams
                ?.ink_visit_id || '',
            referrer:
              user.inkfluencedBy?.signupParams?.additionalParams?.referrer ||
              '',
            session_id:
              user.inkfluencedBy?.signupParams?.additionalParams?.session_id ||
              '',
          },
        },
      },
      inkfluencedDate: user.inkfluencedDate || '',
      userPlan: {
        id: user.userPlan._id,
        name: user.userPlan.name,
        yearlyPrice: parseInt(user.userPlan.price.yearly || 0),
        monthlyPrice: parseInt(user.userPlan.price.monthly || 0),
        isDefault: user.userPlan.isDefault,
        quota: {
          articles: user.userPlan?.quota?.articles || {
            amount: 0,
            unlimited: false,
          },
          users: user.userPlan?.quota?.users || 0,
          keywords: user.userPlan?.quota?.keywords || {
            amount: 0,
            unlimited: false,
          },
          inkPoints: user.userPlan?.quota?.inkPoints || {
            amount: 0,
            topupCriteria: 'daily',
            unlimited: false,
          },
          words: user.userPlan?.quota?.words || {
            amount: 0,
            topupCriteria: 'daily',
            unlimited: false,
          },
        },
      },
      specialPlan: {
        id: user.specialPlan?._id || '',
        name: user.specialPlan?.name || '',
        yearlyPrice: parseInt(user.specialPlan?.price.yearly || 0),
        monthlyPrice: parseInt(user.specialPlan?.price.monthly || 0),
        isDefault: user.specialPlan?.isDefault || false,
        quota: {
          articles: user.specialPlan?.quota?.articles || {
            amount: 0,
            unlimited: false,
          },
          users: user.specialPlan?.quota?.users || 0,
          keywords: user.specialPlan?.quota?.keywords || {
            amount: 0,
            unlimited: false,
          },
          inkPoints: user.specialPlan?.quota?.inkPoints || {
            amount: 0,
            topupCriteria: 'daily',
            unlimited: false,
          },
          words: user.specialPlan?.quota?.words || {
            amount: 0,
            topupCriteria: 'daily',
            unlimited: false,
          },
        },
      },
      subscription: {
        id: user.subscription?._id || '',
        status: user.subscription?.status || '',
        canceledAt: user.subscription?.canceledAt || '',
        currentPeriodStart: user.subscription?.currentPeriodStart || '',
        currentPeriodEnd: user.subscription?.currentPeriodEnd || '',
        interval: user.subscription?.interval || '',
        sPlanId: user.subscription?.sPlanId || '',
        amount: user.subscription?.amount || 0,
      },
      activation: user.activationV2 || false,
      activationDate: user.activationDateV2 || '',
      // toolsActivation: user.toolsActivation || false,
      // toolsActivationDate: user.toolsActivationDate ? Date.now(new Date(user.toolsActivationDate)) : null,
      // editorActivation: user.editorActivation || false,
      // editorActivationDate: user.editorActivationDate ? Date.now(new Date(user.editorActivationDate)) : null,
      timeToActivate: user.timeToActivate || 0,
      inkPoints: user.inkPoints,
      sessions: user.metrices.sessions || 0,
      contentScore: user.metrices.contentScore || 0,
      contentScoreImproved: user.metrices.contentScoreImproved || 0,
      signupParams: {
        trafficSource: {
          id: user.signupParams?.trafficSource?._id || '',
          utm_campaign: user.signupParams?.trafficSource?.utm_campaign || '',
          utm_medium: user.signupParams?.trafficSource?.utm_medium || '',
          utm_source: user.signupParams?.trafficSource?.utm_source || '',
          utm_content: user.signupParams?.trafficSource?.utm_content || '',
          utm_term: user.signupParams?.trafficSource?.utm_term || '',
        },
        additionalParams: {
          platform: user.signupParams?.additionalParams?.platform || '',
          ink_visit_id: user.signupParams?.additionalParams?.ink_visit_id || '',
          referrer: user.signupParams?.additionalParams?.referrer || '',
          session_id: user.signupParams?.additionalParams?.session_id || '',
        },
      },
      stats: {
        wordChecked: parseInt(user.stats?.overall?.wordChecked || 0),
        documentTaskCompleted: parseInt(
          user.stats?.overall?.documentTaskCompleted || 0
        ),
        wordTaskCompleted: parseInt(
          user.stats?.overall?.wordTaskCompleted || 0
        ),
        timeSpent: parseInt(user.stats?.overall?.timeSpent || 0),
        clarity: parseInt(user.stats?.overall?.clarity || 0),
        inkScore: parseInt(user.stats?.overall?.inkScore || 0),
        topicalRelevance: parseInt(user.stats?.overall?.topicalRelevance || 0),
        vocabulary: parseInt(user.stats?.overall?.vocabulary || 0),
        topicsCompleted: parseInt(user.stats?.overall?.topicsCompleted || 0),
        totalKeywords: parseInt(user.stats?.overall?.totalKeywords || 0),
      },
      activeTimeInterval: {
        days: user.activeTimeInterval.days,
        weeks: user.activeTimeInterval.weeks,
        months: user.activeTimeInterval.months,
        currentDays: user.activeTimeInterval.currentDays,
        maxDays: user.activeTimeInterval.maxDays,
        currentWeeks: user.activeTimeInterval.currentWeeks,
        maxWeeks: user.activeTimeInterval.maxWeeks,
        currentMonths: user.activeTimeInterval.currentMonths,
        dayStreakMaintained: user.activeTimeInterval.dayStreakMaintained,
        maxMonths: user.activeTimeInterval.maxMonths,
        monthStreakMaintained: user.activeTimeInterval.monthStreakMaintained,
        weekStreakMaintained: user.activeTimeInterval.weekStreakMaintained,
      },
      ip: user.ip || '',
      os: user.os
        ? {
            name: user.os.name,
            version: user.os.version,
            type: user.os.type,
            architecture: user.os.architecture,
          }
        : null,
      hardware: user.hardware
        ? {
            model: user.hardware.model,
            ram: user.hardware.ram,
          }
        : null,
      location: user.location
        ? {
            range: user.location.range.map((o) => parseFloat(o)),
            country: user.location.country,
            region: user.location.region,
            eu: user.location.eu,
            timezone: user.location.timezone,
            city: user.location.city,
            ll: user.location.ll,
            metro: user.location.metro,
            area: user.location.area,
          }
        : null,
    };
    window.DAS.identifyUser(userData);
    if (user['custom:newSignup'] === '1') {
      const obj = {
        category: 'signup',
        action: 'user-signup',
        label: document.title,
      };
      captureManualEvent(obj);
    }
  }
};

export const identifyTeam = (teamId) => {
  if (window && window.DAS) {
    window.DAS.identifyTeam(teamId);
  }
};

export const initializePosthog = (loggedUser) => {
  if (typeof window !== 'undefined') {
    window.posthog = posthog;
    const user = userDetails(loggedUser);
    if (apiConfig?.env === 'prod') {
      posthog.init(apiConfig?.posthogProdKey, {
        api_host: 'https://post-hog.seo.app',
      });
    }
    // ! DISABLED FOR TEST ENV
    /*  else {
      posthog.init(apiConfig?.posthogTestKey, {
        api_host: 'https://posthog-test.seo.app',
        disable_session_recording: true,
      });
    } */
    posthog.capture('identify-user', {
      $set: { email: user?.email },
      userDetails: user,
    });
  }
};
