import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useTheme } from 'next-themes';
import { isEditorPage } from '@editor/helpers/';

const ButtonWrapper = styled('div')`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  .btn-custom-pink-linear {
    background-color: #e45c96 !important;
    color: white !important;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 11px 30px;
  }
  .btn-custom-pink-linear:hover {
    // background: linear-gradient(
    //   92.79deg,
    //   #c562b8 -4.67%,
    //   rgba(228, 92, 150, 0.91) 33.09%,
    //   #9c6edf 94.63%
    // );
    background: #cd5387 !important;
  }
  .btn-custom-pink {
    background-color: ${(props) =>
      props?.themeStyles?.bgPink || '#e45c96'}!important;
    color: white !important;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 11px 30px;
  }
  .btn-custom-purple {
    background-color: #5361f1 !important;
    color: white !important;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 11px 30px;
  }
  .gradient-btn {
    background: linear-gradient(
          90.19deg,
          #9c6edf 3.95%,
          #c562b8 34.53%,
          #d25c8e 69.82%,
          #cc535b 94.28%
        )
        padding-box,
      border-box;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(
        90.19deg,
        #9c6edf 3.95%,
        #c562b8 34.53%,
        #d25c8e 69.82%,
        #cc535b 94.28%
      )
      1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .btn-custom-pink:hover {
    background-color: ${(props) =>
      props?.themeStyles?.hoverBgPink || '#cd5387'} !important;
    color: white;
  }
  .btn-custom-outlined-blue {
    border: 1px solid #2499e7;
    color: #2499e7;
    font-weight: 600;
    padding: 9px 25px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
  }

  .btn-custom-gradient {
    background: linear-gradient(
      90.19deg,
      #9c6edf 3.95%,
      #c562b8 34%,
      #d25c8e 67.3%,
      #cc535b 94.28%
    );
    font-weight: 700;
    font-size: 16px;
    padding: 6px 10px;
    color: white;
    border-radius: 5px;
  }

  .btn-custom-blue {
    background-color: ${(props) =>
      props?.themeStyles?.bgBlue || '#2499e7'} !important;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px 25px;
  }
  .btn-custom-blue:hover {
    background-color: ${(props) =>
      props?.themeStyles?.hoverBgBlue || 'rgba(32, 138, 208, 0.8)'} !important;
    color: white;
  }
  .btn-custom-outlined-pink {
    border: 1px solid #e45c96;
    color: #e45c96 !important;
    font-weight: 600;
    padding: 9px 25px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
  }
  .btn-custom-dark {
    font-weight: 600;
    padding: 9px 25px;
    font-size: 15px;
  }
  .btn-custom-outlined {
    border: ${(props) =>
      props.theme === 'dark' ? ' 1px solid #31313f' : '1px solid #EBEBEF'};
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    color: ${(props) => (props.theme === 'dark' ? 'white' : ' #505876')};
    width: 100%;
    padding: 8px 15px;
    min-height: 45px;
  }
  .btn-custom-outlined:hover {
    background-color: #ebebef;
    color: #505876;
  }
  .btn-custom-outlined-dark {
    border: ${(props) =>
      props.theme === 'dark' ? ' 1px solid  #272C3A' : '1px solid #505876'};
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    color: ${(props) => (props.theme === 'dark' ? 'white' : ' #505876')};
    width: 100%;
    padding: 8px 10px;
    min-height: 45px;
    min-width: 0px;
  }
  .btn-custom-outlined-dark:hover {
    // background-color: #ebebef;
    // color: #505876;
  }
  .Mui-disabled {
    opacity: ${(props) => (props.theme === 'dark' ? '0.4' : '0.5')};
    color: ${(props) => (props.theme === 'dark' ? 'white' : ' #505876')};
  }
`;
function CustomButton({
  formSubmit = 'submit',
  // backgroundColor = `blue`,
  id,
  children,
  size,
  style = null,
  onClick = () => {},
  onMouseDown = () => {},
  // type = 'basic',
  type,
  Icon,
  iconPosition = 'end',
  // color,
  loading = false,
  disabled = false,
  reference,
  fullWidth = false,
  variant,
  hoverState = false,
  btnColor = '',
  hoverStateContent,
  className,
  classes,
  href = null,
  textAlign = '',
  customStyleVariant = null,
  wrapperClasses = '',
  target = '_self',
}) {
  const [isHovered, setIsHovered] = useState(false);

  const isColorblind = useSelector((state) => state.editor?.isColorblind);

  const handleMouseEnter = () => {
    if (!hoverState) return;
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    if (!hoverState) return;
    setIsHovered(false);
  };
  const handleClick = (event) => {
    if (isHovered) {
      setIsHovered(false);
    }
    if (onClick) {
      onClick(event);
    }
  };
  const handleMouseDown = (e) => {
    if (isHovered) {
      setIsHovered(false);
    }
    onMouseDown(e);
  };

  const { theme } = useTheme();

  //* COMMON USED CUSTOM STYLES
  //* exmaple to match: pass prop customStyleVariant = 'outlined' to use outlined style, etc
  const useCustomStyles = makeStyles({
    outlined: {
      border: theme === 'dark' ? ' 1px solid #77777754' : '1px solid #EBEBEF',
      fontWeight: 'bold',
      fontSize: '12px',
      borderRadius: '5px',
      color: theme === 'dark' ? 'white' : ' #505876',
      padding: '8px 15px',
      '&:hover': {
        color: '#505876',
        background: '#EBEBEF',
      },
    },
  });

  const customStyles = useCustomStyles();
  //* get the custom style match from the customStyles object
  const customStyleMatch = customStyles[customStyleVariant];

  /*
   * To maintain consistency across the PWA, we cannot use CSS variables in this component. At the moment, CSS variables are only valid for the Editor page.
   */
  const themeStyles = () => {
    // for colorblind mode
    if (isEditorPage() && isColorblind) {
      return {
        bgPink: '#191919',
        hoverBgPink: '#0f0f0f',
        bgBlue: '#191919',
        hoverBgBlue: '#0f0f0f',
      };
    }
    // for dark theme
    if (theme === 'dark') {
      return {
        bgPink: '#e45c96',
        hoverBgPink: '#cd5387',
        bgBlue: '#2499e7',
        hoverBgBlue: 'rgba(32, 138, 208, 0.8)',
      };
    }

    // default for light theme
    return {
      bgPink: '#e45c96',
      hoverBgPink: '#cd5387',
      bgBlue: '#2499e7',
      hoverBgBlue: 'rgba(32, 138, 208, 0.8)',
    };
  };

  return (
    <ButtonWrapper
      className={`${textAlign} ${wrapperClasses}`}
      theme={theme}
      fullWidth={fullWidth}
      themeStyles={themeStyles()}
    >
      <Button
        sx={{
          // disabled
          '&.Mui-disabled': {
            color:
              btnColor === 'blue' && theme !== 'dark'
                ? 'white !important'
                : 'inherit',
          },
        }}
        href={href}
        className={`btn-custom-${btnColor} py-2 ${className} ${customStyleMatch}`}
        id={id}
        disabled={disabled || loading}
        fullWidth={!!fullWidth}
        onClick={handleClick}
        ref={reference || null}
        size={size}
        style={style}
        type={formSubmit}
        variant={variant}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        classes={classes}
        target={target}
      >
        {loading ? (
          <div className='flex'>
            <CircularProgress size={20} sx={{ color: 'white' }} />
          </div>
        ) : (
          <>
            {Icon && iconPosition === 'start' && !loading ? (
              <span className='mr-2 flex items-center justify-center'>
                <Icon />
              </span>
            ) : null}
            {isHovered ? hoverStateContent : children}
            {Icon && iconPosition === 'end' && !loading ? (
              <span className='ml-2  flex items-center justify-center'>
                <Icon />
              </span>
            ) : null}
          </>
        )}
      </Button>
    </ButtonWrapper>
  );
}

CustomButton.propTypes = {
  Icon: PropTypes.any,
  // backgroundColor: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string,
  // color: PropTypes.string,
  disabled: PropTypes.bool,
  formSubmit: PropTypes.string,
  fullWidth: PropTypes.bool,
  onMouseDown: PropTypes.func,
  iconPosition: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  reference: PropTypes.any,
  size: PropTypes.string,
  style: PropTypes.object,
  hoverState: PropTypes.bool,
  variant: PropTypes.string,
  btnColor: PropTypes.string,
  hoverStateContent: PropTypes.any,
  className: PropTypes.string,
  classes: PropTypes.object,
  href: PropTypes.string,
  textAlign: PropTypes.string,
  customStyleVariant: PropTypes.string,
  wrapperClasses: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank', '_new']),
  type: PropTypes.string,
};

export default CustomButton;
