import { setDataInLocalStorage } from '@helpers/browser_storage';
import { actions } from '@redux/actions/project';

const initialState = {
  selectedFolder: {},
  projects: [],
};
const sortData = (data = []) =>
  data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
const ProjectReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_SELECTED_FOLDER:
      // ? DISABLED TO EXCLUDE FROM PROD
      /* setDataInLocalStorage(
        'selectedFolder',
        data?._id
          ? {
              _id: data._id,
              default: data.isDefault,
              name: data.name,
            }
          : null
      ); */
      return { ...state, selectedFolder: data };
    case actions.SET_PROJECTS:
      return { ...state, projects: sortData(data) };
    case actions.ADD_PROJECT:
      return {
        ...state,
        projects: sortData([
          ...state.projects.filter((obj) => obj._id !== data._id),
          data,
        ]),
      };
    case actions.DELETE_PROJECT:
      return {
        ...state,
        projects: sortData(
          state.projects.filter((obj) => obj._id !== data._id)
        ),
      };
    default:
      return state;
  }
};

export default ProjectReducer;
