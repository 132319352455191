export const isEditorPage = () => {
  if (typeof window !== 'object') return false;

  let pathArr = window.location.pathname?.split('/');
  pathArr = pathArr.filter((item) => item);
  return pathArr[0] === 'long-form-writing';
};
export const isNewDoc = () => {
  let pathArr = window.location.pathname?.split('/');
  pathArr = pathArr.filter((item) => item);

  return pathArr[0] === 'long-form-writing' && pathArr.length === 1;
};

export const isNewDocWithParam = (param = '') => {
  const url = new URL(window.location);
  const hasParam = url.searchParams.has(param);

  return isNewDoc() && hasParam;
};

export const isDocV1 = () => {
  const url = new URL(window.location);
  const version = parseInt(url.searchParams.get('v'));

  return version === 1;
};

/**
 * Retry operations based on a condition and for a specific time
 * @param {Object}
 * @returns interval ID
 */
// ! Please don't forget to clear the interval, when possible
export function withRetry({
  conditionCheck,
  time,
  retryCB,
  successCB = () => {},
  failureCB = () => {},
}) {
  let waitTime = time;
  const itvTime = 99;

  const interval = setInterval(() => {
    waitTime -= itvTime;

    if (conditionCheck()) {
      clearInterval(interval);
      if (typeof successCB === 'function') successCB();
    } else if (typeof retryCB === 'function') {
      retryCB();
    }

    if (waitTime <= 0) {
      clearInterval(interval);
      if (typeof failureCB === 'function') failureCB();
    }
  }, itvTime);

  return interval;
}

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== 'string') return;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTempDocId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tempId = searchParams.get('tempId') || '';

  return tempId;
};
