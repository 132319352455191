// ! TEMPORARY: doc migration
// This is a temporary implementation, which will only valid during transition period
import apiObj from '@config/api.service';

const apiUrl = apiObj.url;

function buildDocument(id, doc, kpDoc) {
  if (!doc?.result) return {};

  const kpData = kpDoc?.result?.data;
  const { title } = doc.result;
  const { text } = doc.result;
  const { document } = doc.result;
  const { user } = doc;

  const meta = kpData
    ? JSON.parse(
        kpData['ink:ink-app-pwa:doc:meta'] ||
          kpData['ink:ink-app-pwa:doc:meta:test'] ||
          '{}'
      )
    : {};
  const kp = kpData
    ? JSON.parse(
        kpData['ink:ink-app-pwa:doc:kpdata'] ||
          kpData['ink:ink-app-pwa:doc:kpdata:test'] ||
          '{}'
      )
    : {};

  const metadata = {
    ...meta,
    title,
    text,
    kp: kp?.keyphrases?.map((e) => ({
      id: e.id,
      phrase: e.phrase,
      inkscore: e.inkscore,
      relevance: e.relevance,
    })),
  };
  const data = {
    document,
    kpdata: kp.keyphrases,
  };

  return { id, user, metadata, data };
}

export async function getDocument(id) {
  const docurl = `${apiUrl}/v2.0/tools/results/${id}`;
  const kpurl = `${apiUrl}/v2.0/tools/results?toolId=${window.location.host}-${id}`;

  const idToken = JSON.parse(localStorage.getItem('idToken'));

  const promises = [];
  promises.push(
    fetch(docurl, {
      method: 'GET',
      headers: {
        Authorization: idToken,
      },
    }).then((res) => res.json())
  );
  promises.push(
    fetch(kpurl, {
      method: 'GET',
      headers: {
        Authorization: idToken,
      },
    }).then((res) => res.json())
  );

  const results = await Promise.all(promises);

  const doc = results[0]?.body;
  const kpDoc = results[1]?.body?.filter(
    (e) =>
      (e.result.data['ink:ink-app-pwa:doc:kpdata'] &&
        e.result.data['ink:ink-app-pwa:doc:kpdata']?.indexOf(
          '"keyphrases":[]'
        ) < 0) ||
      (e.result.data['ink:ink-app-pwa:doc:kpdata:test'] &&
        e.result.data['ink:ink-app-pwa:doc:kpdata:test']?.indexOf(
          '"keyphrases":[]'
        ) < 0)
  )[0];

  return buildDocument(id, doc, kpDoc);
}

// usage example
/* const idToken = JSON.parse(localStorage.getItem('idToken'));

getDocument('6336f70f513d702d1f662408', idToken).then((res) =>
  console.log(res)
);
 */
