import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';

import CustomButton from '@components/Button/Button';

import Modal from '@components/Modal/Modal';

function ConfirmationModal({ text, btnText, handleBtnClick }) {
  const { theme } = useTheme();
  const router = useRouter();
  return (
    <Modal
      bodyStyle={{
        padding: '0px',
        paddingBottom: '8px',
      }}
      paperStyle={{
        width: '416px',
      }}
      setOpen={() => {}}
      open
      disableDivider
    >
      <div className='text-custom-font-color dark:text-white flex align-center justify-center flex-col'>
        <div style={{ marginBottom: '12px' }}>{text}</div>
      </div>

      <div className='flex align-center justify-center gap-4 mt-[16px]'>
        <CustomButton
          className='h-[42px] font-semibold'
          style={{
            color: '#fff',
            background: '#2499E7',
            padding: '0 16px',
          }}
          onClick={handleBtnClick}
        >
          {btnText}
        </CustomButton>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  text: PropTypes.string,
  btnText: PropTypes.string,
  handleBtnClick: PropTypes.func,
};
