/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import PropTypes from 'prop-types';
import { useStore } from '@redux/store';
import { injectScripts, scripts, userDetails, isInPWA } from '@helpers/utils';
import '@styles/globals.css';
import '@styles/footer.css';
import Head from 'next/head';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import Mqtt from '@components/Mqtt/Mqtt';
import Snackbar from '@components/Snackbar/Snackbar';
import apiConfig from '@config/api.service';
import { useRouter } from 'next/router';
import { initDAS } from '@services/analytics';
// TODO: remove package - @sentry/react
// import * as Sentry from '@sentry/react';
import { setShowInstallInkBtn } from '@redux/actions/general';
import AuthenticationProvider from '@components/providers/auth-provider/';
import theme from 'theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const dependencyArray = typeof window !== `undefined` ? [window.DAS] : [];

function App({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);
  const { pathname, replace } = useRouter();
  const router = useRouter();

  /*
    In editor CSS variables takes some time to properly load in html tag
    We see some unnecessary colors blink effect in that time.
    So initially reset those colors
  */
  useEffect(() => {
    const htmlElm = document.querySelector('html');
    htmlElm?.style?.setProperty('--theme-color-3', 'transparent');
    htmlElm?.style?.setProperty('--sidebar-border-color', 'transparent');
    htmlElm?.style?.setProperty('--page-bg', 'transparent');
  }, []);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    injectScripts(scripts);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      initDAS();
    }
    // eslint-disable-next-line
  }, [...dependencyArray, pathname])

  // useEffect(() => {
  //   if (routesList?.includes(pathname)) {
  //     window?.HubSpotConversations?.widget?.remove();
  //   }
  // }, [pathname]);

  useEffect(() => {
    if (router.pathname.includes('/plans/assistant')) {
      router.push('/404');
    }
    const qualificationType =
      store?.getState()?.user?.loggedUser?.qualificationType;
    if (qualificationType === 'Academic' && pathname === '/plans') {
      replace('/plans/education');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sentry work tentative

  // useEffect(() => {
  //   if (apiConfig.isDeployed) {
  //     const data = JSON.parse(localStorage.getItem('user'));
  //     Sentry.init({
  //       dsn: 'https://e8d655ea817d4adbb3ba2e8e97f8ec26@o446370.ingest.sentry.io/6323053',
  //       environment: apiConfig.env,
  //       release: `ink-pwa`,
  //       // this is to suppress regex related issue start
  //       beforeSend(event) {
  //         if (
  //           event.description ===
  //           'SyntaxError: Invalid regular expression: invalid group specifier name'
  //         ) {
  //           return null;
  //         }
  //         return event;
  //       },
  //       // this is to suppress regex related issue end
  //       tracesSampleRate: apiConfig.tracesSampleRate, // Be sure to lower this in production
  //     });
  //     if (data) {
  //       Sentry.configureScope((scope) => {
  //         scope.setUser({
  //           email: data?.email,
  //           id: data?._id,
  //           sub: data?.sub,
  //         });
  //       });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (isInPWA()) return;

    // 'beforeinstallprompt' listener ensure that it's an installable app

    window?.addEventListener('beforeinstallprompt', (event) => {
      // store the event for later use, i.e when click on the 'Install INK' button
      // in components/editor.header/top.menu/useInstallINK.js
      window.deferredPrompt = event;

      store.dispatch(setShowInstallInkBtn(true));
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('unregister', () => {
        store.dispatch(setShowInstallInkBtn(true));
      });
    }
  }, []);

  return (
    <>
      <Head>
        <title>INK</title>
        <link rel='icon' href='/favicon.jpg' />
        <script src={apiConfig.analyticsURL} />
      </Head>
      <ReduxStoreProvider store={store}>
        {/* <INKInstallModal /> */}

        {/* <Mqtt> */}
        <NextThemeProvider enableSystem attribute='class'>
          <MuiThemeProvider theme={theme}>
            <AuthenticationProvider>
              <Snackbar />
              {!router.pathname.includes('/plans/assistant') ? (
                <Component {...pageProps} />
              ) : null}
            </AuthenticationProvider>
          </MuiThemeProvider>
        </NextThemeProvider>
        {/* </Mqtt> */}
      </ReduxStoreProvider>
    </>
  );
}

export default App;

// export default Sentry.withProfiler(App);
