export function formatCountDown(seconds) {
  //   conver seconds to minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

export function isEmailVerified(err) {
  if (err.message === 'Email not verified, Please verify email to continue') {
    return false;
  }
  return true;
}

export const PASSWORD_VALIDATIONS = [
  {
    message: 'Minimum of 8 characters',
    pattern: /(?=.{8,})/,
  },
  {
    message: 'At least 1 uppercase letter (A-Z)',
    pattern: /(?=.*[A-Z])/,
  },
  {
    message: 'At least 1 number (0-9)',
    pattern: /(?=.*[0-9])/,
  },
  {
    message: 'At least 1 special character (e.g., !@#%^&*)',
    pattern: /(?=.*[!@#%^&*])/,
  },
];

export const addQueryParamsToPath = (pathWithParams, params = {}) => {
  if (!pathWithParams) {
    return undefined;
  }
  const decodedPath = decodeURIComponent(pathWithParams);
  const [path, preDefinedParams] = decodedPath.split('?');
  console.log('path', path);
  console.log('preDefinedParams', preDefinedParams);
  const queryParams = new URLSearchParams(preDefinedParams);
  Object.keys(params).forEach((key) => {
    queryParams.append(key, params[key]);
  });
  return `${path}?${queryParams.toString()}`;
};
