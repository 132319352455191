import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from 'next-themes';
import CustomButton from '@components/Button/Button';
import { styled } from '@mui/material/styles';
import { isEditorPage } from '@editor/helpers/';

const StyledDialogTitle = styled(DialogTitle)`
  &.noPadding {
    padding: 0px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
  }
`;

const StyledDialogActions = styled(DialogActions)(
  (props) =>
    `
  background-color: ${props?.theme === 'dark' ? '#252530' : '#ffffff'};
  border-top: ${
    props?.theme === 'dark' ? '1px solid #1E2628' : '1px solid #EBEBEF'
  };
`
);

function Modal({
  open,
  children,
  setOpen,
  titleHeading,
  CustomTitleComp = null,
  bodyStyle,
  disableBackdropClick = false,
  maxWidth,
  modalStyle,
  titleBorder = true,
  custom = true,
  closeIcon,
  dialogAction = null,
  disableDivider = false,
  paperStyle = {},
  quickLaunchCursorPlacement = null,
  contStyle = {},
  dividerStyles = {},
}) {
  const [modalActive, setModalActive] = useState(false);

  const isColorblind = useSelector((state) => state.editor?.isColorblind);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setModalActive(true);
      }, 200);
    } else {
      setModalActive(false);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    if (quickLaunchCursorPlacement) quickLaunchCursorPlacement();
  };
  const { theme } = useTheme();

  // * Moved to CSS file, because makeStyles() creates lots of <style> tag in each content change
  // * even a single click or key press
  /* const useStyles = makeStyles({
    noPadding: {
      padding: '0px !important',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
    },
    dialogContent: {
      backgroundColor: theme === 'dark' ? '#071116' : '',
    },
    actionStyles: {
      backgroundColor: theme === 'dark' ? '#071116' : '',
      borderTop: theme === 'dark' ? '1px solid #1E2628' : '1px solid #EBEBEF',
    },
  });
  const classes = useStyles(); */

  /*
   * To maintain consistency across the PWA, we cannot use CSS variables in this component. At the moment, CSS variables are only valid for the Editor page.
   */
  const themStyles = (() => {
    // for colorblind mode
    if (isEditorPage() && isColorblind) {
      return { background: '#303030' };
    }
    // for dark theme
    if (theme === 'dark') {
      return { background: '#252530' };
    }

    // default for light theme
    return { background: '#ffffff' };
  })();

  return (
    <Dialog
      PaperProps={{
        sx: {
          background: themStyles?.background,
          ...modalStyle,
          ...paperStyle,
        },
      }}
      aria-describedby='scroll-dialog-description'
      aria-labelledby='scroll-dialog-title'
      maxWidth={maxWidth}
      fullWidth
      /* disableBackdropClick={disableBackdropClick} */
      onClose={handleClose}
      open={modalActive}
    >
      {titleHeading || titleHeading === '' ? (
        <StyledDialogTitle
          id='scroll-dialog-title'
          classes={{ root: !titleBorder ? 'no-border ' : 'noPadding' }}
        >
          <div>
            <div className='px-4 pt-3 flex justify-between items-center'>
              <div className='dark text-md dark:text-white font-bold text-custom-font-color'>
                {CustomTitleComp ? <CustomTitleComp /> : titleHeading}
              </div>
              <div>
                <ClearIcon
                  onClick={handleClose}
                  style={{
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    color: theme === 'dark' ? 'white' : '#505876',
                  }}
                />
              </div>
            </div>

            {!custom ? (
              <div className='crossSection'>
                <ClearIcon
                  onClick={handleClose}
                  style={{
                    cursor: 'pointer',
                    color: '#505876',
                  }}
                />
              </div>
            ) : null}
          </div>
        </StyledDialogTitle>
      ) : null}
      {closeIcon ? (
        <DialogTitle
          className={!titleBorder ? 'no-border' : ''}
          id='scroll-dialog-title'
        >
          {titleHeading}
          <ClearIcon
            onClick={handleClose}
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
              color: '#505876',
            }}
          />
        </DialogTitle>
      ) : null}
      <div className='py-3' style={dividerStyles}>
        {!disableDivider ? (
          <Divider sx={{ borderColor: theme === 'dark' ? '#31313f' : '' }} />
        ) : null}
      </div>
      <DialogContent
        sx={{
          ...bodyStyle,
        }}
      >
        <div style={contStyle} className='py-2 px-4 text-white'>
          {children}
        </div>
      </DialogContent>
      {dialogAction ? (
        <StyledDialogActions theme={theme}>
          {dialogAction?.buttons?.map((btn, index) => (
            <CustomButton
              key={index}
              variant={btn?.variant}
              btnColor='blue'
              color='primary'
              style={{ minWidth: 120 }}
              onClick={btn?.onClick}
            >
              {btn.text}
            </CustomButton>
          ))}
        </StyledDialogActions>
      ) : null}
    </Dialog>
  );
}

Modal.propTypes = {
  // autoWidth: PropTypes.any,
  bodyStyle: PropTypes.object,
  children: PropTypes.element,
  // classes: PropTypes.object,
  closeIcon: PropTypes.bool,
  custom: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  maxWidth: PropTypes.string,
  modalStyle: PropTypes.object,
  dialogAction: PropTypes.object,
  contStyle: PropTypes.object,
  dividerStyles: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  titleBorder: PropTypes.bool,
  titleHeading: PropTypes.string,
  disableDivider: PropTypes.bool,
  CustomTitleComp: PropTypes.func,
  paperStyle: PropTypes.object,
  quickLaunchCursorPlacement: PropTypes.func,
};

export default Modal;
