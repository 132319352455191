import CustomButton from '@components/Button/Button';
import InputField from '@components/Input/Input';
import { ConfirmationModal } from '@library/components/Molecules';
import { Box, FormControl, Stack, Divider, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useState, useCallback, useEffect } from 'react';
import { formatCountDown } from '@components/auth/_helper';
import LogoBlankLayout from '@library/components/Molecules/Layouts/LogoBlankLayout';
import { setCustomAlert } from '@redux/actions/general';
import useMutate from 'hooks/useMutate';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';

let countDownInterval;
let firstTimeSent = false;

const OPTIONS = {
  sendInterval: 150_000,
};

const EmailVerifyPopup = ({ email, onClose, initialCooldown }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [canResend, setCanResend] = useState(!initialCooldown);
  const [countDown, setCountDown] = useState(initialCooldown ?? 0);

  const { isLoading, isError, response, mutate } = useMutate(
    '/v2.0/auth/verification-email/resend',
    {
      onError: (error) => {
        dispatch(
          setCustomAlert({
            message: 'Something went wrong!',
            state: true,

            type: 'error',
          })
        );
      },

      onSuccess: (data) => {
        console.log(data);
        dispatch(
          setCustomAlert({
            message: `Email sent to ${email} successfully`,
            state: true,

            type: 'success',
          })
        );

        setCanResend(false);
      },
    }
  );

  //  counter
  useEffect(() => {
    if (canResend) return;
    setCountDown(OPTIONS.sendInterval / 1000);

    countDownInterval = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(countDownInterval);
    };
  }, [canResend]);

  useEffect(() => {
    if (countDown === 0) {
      setCanResend(true);
      clearInterval(countDownInterval);
    }
  }, [countDown]);

  const handleResendEmail = useCallback(() => {
    if (!canResend) return;
    mutate({
      email,
    });
  }, [mutate, email, canResend]);

  useEffect(() => {
    if (firstTimeSent || !canResend) return;

    handleResendEmail();
    firstTimeSent = true;
  }, [handleResendEmail, canResend]);

  return (
    <>
      <ConfirmationModal
        title='Verify Email'
        close={onClose}
        bodyComponent={
          <Stack
            spacing={2}
            alignItems='center'
            justifyContent='center'
            marginTop='30px'
          >
            <Image
              src='/assets/auth/check.svg'
              width={100}
              height={100}
              alt='Completed'
            />
            <Typography variant='h5'>Check Your Email</Typography>

            <Typography variant='body1' textAlign='center'>
              You’re almost there! We’ve sent an email to
              <br />
              <b>{email}</b>
            </Typography>

            <Divider sx={{ marginTop: 2, marginBottom: 2, width: '100%' }} />

            <Stack
              spacing={2}
              width='100%'
              alignItems='center'
              justifyContent='center'
            >
              <Typography variant='body1' textAlign='center'>
                Didn’t receive the email?
              </Typography>
              <CustomButton
                type='submit'
                btnColor='blue'
                disabled={!canResend || isLoading}
                loading={isLoading}
                style={{
                  height: '50px',
                }}
                onClick={handleResendEmail}
              >
                Resend Email
              </CustomButton>
              {countDown > 0 && (
                <Typography variant='body1' textAlign='center'>
                  You can resend after {formatCountDown(countDown)} minutes
                </Typography>
              )}
              <Typography variant='body1' textAlign='center'>
                Need help?{' '}
                <span className='text-secondary-blue font-semibold'>
                  <a href='mailto:support@inkco.co'>Contact Us</a>
                </span>
              </Typography>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

export default EmailVerifyPopup;

EmailVerifyPopup.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func,
  initialCooldown: PropTypes.number,
};
