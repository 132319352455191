export const PATH_EDITOR = '/long-form-writing';

export const PATH_ALL_DOCUMENTS = '/ink-documents';

export const EDITOR_WIDTH = '779px';

export const LEFT_SIDEBARS = {
  aiAssistant: 'aiAssistant',
  recipes: 'recipes',
  imageFinder: 'imageFinder',
};

export const RIGHT_SIDEBARS = {
  contentShield: 'contentShield',
  help: 'help',
  grammarly: 'grammarly',
  rewrite: 'rewrite',
  seo: 'seo',
};
