import { actions } from '@redux/actions/general';

const initialState = {
  alertMsg: '',
  snackbar: {
    message: 'Mock Snackbar',
    position: 'top-right',
    state: false,
    type: 'success',
    timeInterval: 3000,
  },
  isLoading: true,
  userPlans: {},
  drawerActive: null,
  modalOpen: false,
  inkPointModalOpen: false,
  result: null,
  selectedTool: null,
  searchNotFound: false,
  dataFetchedFrom: null,
  invitedMembers: [],
  implicitLogout: false,
  KCMQTTTopic: null,
  kcHistory: [],
  isUploading: false,
  productBilling: null,
  productSeats: null,
  aiResultFilter: { 0: 0, 1: 0, 2: 0, 3: 0 },
  contentPlannerPricing: null,
  additonalSeatsAndKW: null,
  isDocSaving: false,
  isDrawerOpen: true,
  showInstallInkBtn: false,
  imageResult: [],
  imageGenerationLoading: false,
  inputFields: {
    imageValue: 'FreeStyle',
    inputValue: '',
  },
  groupImages: false,
  selectedPrompt: false,
};

const general = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_RESULT:
      return { ...state, result: data };
    case actions.SET_ALERT_MSG:
      return { ...state, alertMsg: data };
    case actions.SET_SELECTED_TOOL:
      return { ...state, selectedTool: data };
    case actions.SET_SEARCH_NOT_FOUND:
      return { ...state, searchNotFound: data };
    case actions.SET_CUSTOM_ALERT:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          message: data.message || '',
          state: data.state || false,
          type: data.type || 'info',
          position: data.position || 'top-right',
          timeInterval: data.timeInterval || 3000,
        },
      };
    case actions.SET_PLANS_DATA:
      return {
        ...state,
        userPlans: data,
      };
    case actions.SET_AI_RESULT_FILTER:
      return {
        ...state,
        aiResultFilter: data,
      };
    case actions.SET_KCMQTT_TOPIC:
      return {
        ...state,
        KCMQTTTopic: data,
      };
    case actions.SET_KC_HISTORY:
      return {
        ...state,
        kcHistory: [...data],
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: data ?? !state.isLoading,
      };
    case actions.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: data,
      };
    case actions.SET_DRAWER_ACTIVE:
      return {
        ...state,
        drawerActive: data,
      };
    case actions.SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };
    case actions.SET_IMPLICIT_LOGOUT:
      return {
        ...state,
        implicitLogout: true,
      };
    case actions.SET_INK_POINT_MODAL_OPEN:
      return {
        ...state,
        inkPointModalOpen: !state.inkPointModalOpen,
      };
    case actions.SET_DATA_FETCHED_FROM:
      return {
        ...state,
        dataFetchedFrom: data,
      };
    case actions.SET_INVITED_MEMBERS:
      if (data?.type === 'add') {
        return {
          ...state,
          invitedMembers: [...state.invitedMembers, data?.data],
        };
      }
      if (data?.type === 'remove') {
        return {
          ...state,
          invitedMembers: [...data?.data],
        };
      }
      if (data?.type === 'clear') {
        return {
          ...state,
          invitedMembers: [],
        };
      }
      break;
    case actions.SET_IS_UPLOADING:
      return {
        ...state,
        isUploading: data,
      };
    case actions.SET_PRODUCT_QUERY_PARAMS:
      return {
        ...state,
        ...data,
      };
    case actions.SET_CONTENT_PLANNER_PRICING:
      return {
        ...state,
        contentPlannerPricing: data,
      };
    case actions.SET_ADDITIONAL_SEATS_KW_PRICING:
      return {
        ...state,
        additonalSeatsAndKW: data,
      };
    case actions.SET_IS_DOC_SAVING:
      return {
        ...state,
        isDocSaving: data,
      };
    case actions.SET_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: data,
      };
    case actions.SET_SHOW_INSTALL_INK_BTN:
      return {
        ...state,
        showInstallInkBtn: data,
      };
    case actions.SET_IMAGE_RESULT:
      return {
        ...state,
        imageResult: data,
      };
    case actions.SET_IMAGE_GENERATION_LOADING:
      return {
        ...state,
        imageGenerationLoading: data,
      };
    case actions.SET_IMAGE_FILTER_INPUTS:
      return {
        ...state,
        inputFields: data,
      };
    case actions.SET_GROUP_IMAGES:
      return {
        ...state,
        groupImages: data,
      };
    case actions.SET_SELECTED_PROMPT:
      return {
        ...state,
        selectedPrompt: data,
      };
    default:
      return state;
  }
};

export default general;
