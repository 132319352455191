import { v2request } from '@shared/verb';
import { getDocument as getDocumentFromOldSystem } from './doc-transition';

export const getDocuments = async ({
  pageSize = 20,
  pageNumber = 1,
  filterBy = '',
} = {}) => {
  const data = {
    folder: 'Documents',
    pageSize,
    pageNumber,
    sort: {
      key: 'updatedAt',
      order: 'desc',
    },
  };

  if (filterBy && typeof filterBy === 'string') {
    data.filter = {
      name: filterBy,
    };
  }

  const response = await v2request({
    path: '/drive/files?type=doc',
    method: 'POST',
    data,
  });

  if (response?.status === 200) {
    return {
      totalCount: response?.data?.totalCount,
      docs: response?.data?.files,
    };
  }

  return [];
};

export const getDocument = async (id) => {
  const response = await v2request({
    path: `/drive/files/${id}?type=doc`,
  });

  if (response?.status === 200) {
    localStorage.setItem('isDocFromNewStorage', true); // ! TEMPORARY: doc migration

    return response?.data;
  }

  // !TEMPORARY: will remove after complete migration of documents storage system
  localStorage.setItem('isDocFromNewStorage', false);

  const doc = await getDocumentFromOldSystem(id);
  return doc;
};

export const createDocument = async (data) => {
  const response = await v2request({
    path: '/drive/files/new?type=doc',
    method: 'POST',
    data,
  });

  return response;
};

export const updateDocument = async (id, data) => {
  const response = await v2request({
    path: `/drive/files/${id}?type=doc`,
    method: 'PUT',
    data,
  });

  return response;
};

export const deleteDocuments = async (ids) => {
  const response = await v2request({
    path: '/drive/files?type=doc',
    method: 'DELETE',
    data: {
      ids,
    },
  });

  return response;
};
