import { isURL } from '@helpers/utils';
import { getCurrentEnvironment } from '@helpers/envUtils';
import clientStorage from '@helpers/clientStorage';

const nestable = ['numbered-list', 'bulleted-list', 'quote'];

// ! Need to be careful when using reGenerateBlockKeys function
// Regenerating block keys will break tasks navigation in inkjs addon
// Especially when we remove/add blocks in top of the document

/* export function reGenerateBlockKeys(editorBlocks) {
  let index = 0;
  const content = editorBlocks.map((block) => {
    if (nestable.includes(block.type)) {
      // Without following two lines we have duplicate keys in blocks

      block.key = index++;
      block.pathStr = block.path.join('');

      block.children = block.children.map((child) => {
        child.key = index++;
        child.pathStr = child.path.join('');
        return child;
      });
      return block;
    }
    block.key = index++;
    block.pathStr = block.path.join('');
    return block;
  });
  return content;
} */

/**
 * Get the Slate editor instance
 * @returns {Object} Slate editor instance
 */
const getEditor = async () => {
  const { default: editor } = await import('@stacktools/editor');
  return editor;
};

// TODO: replace all blocks parameter with following function
/**
 * Get all blocks from editor
 * @returns {Object[]} Array of all blocks
 */
const getBlocks = async () => {
  const editor = await getEditor();
  const blocks = editor?.getDocument();

  return blocks;
};

export function getLastPathOfEditorBlocks(editorBlocks) {
  const lastBlock = editorBlocks[editorBlocks.length - 2];

  return (lastBlock && lastBlock?.path) || [1];
}

export function findBlockByPath(blocks = [], path = []) {
  return blocks
    .map((block) => {
      if (nestable.includes(block.type)) {
        // to make the .find easier
        return block.children;
      }
      return block;
    })
    .flat()
    .find((block) => block?.path?.join('') === path?.join(''));
}

/* if a block has links and bold text then they are added as children
when we get selection each children is treated as seperate path
and offset is with reference to the child itself and not block 
therefore to get offset with reference to block we need to take into account all the children before current path
hence the following function */
export function adjustOffsetByPath(targetPath, block, offset) {
  let retOffset = 0;
  for (let i = 0; i < block?.children?.length; i++) {
    if (block?.children?.[i]?.path[1] < targetPath[1]) {
      retOffset += block?.children?.[i]?.text?.length;
    } else if (block?.children?.[i]?.path[1] === targetPath[1]) {
      retOffset += offset;
    }
  }
  return retOffset;
}

// inkjs suitable selection format from selected editor text object
export function selectionFormat(selectedTextObj, editor) {
  // ! Selection Only applicable in a single block. Multiple block still not supported
  // TODO: implement multiple line selection
  // const editorBlocks = reGenerateBlockKeys(editor?.getDocument());
  const editorBlocks = editor?.getDocument();

  // selectedTextObj?.global is required during selection
  // selectedTextObj?.global is undefined when click on 'Rewrite' button in inkjs addon (SEO)
  const path = selectedTextObj?.global?.path || selectedTextObj?.path;

  const currentBlockPath =
    path.length > 0 ? path : getLastPathOfEditorBlocks(editorBlocks);

  const key = findBlockByPath(editorBlocks, currentBlockPath)?.key;

  // * anchor and focus offset vary based on cursor direction when select
  let anchorOffset = selectedTextObj?.range?.anchor?.offset;
  let focusOffset = selectedTextObj?.range?.focus?.offset;

  // * So always make 'anchor offset' less than 'focus offset'
  if (anchorOffset > focusOffset) {
    anchorOffset = focusOffset;
    focusOffset = selectedTextObj?.range?.anchor?.offset;
  }

  const position = {
    anchor: {
      key,
      offset: anchorOffset || 0,
    },
    focus: {
      key,
      offset: focusOffset || 0,
    },
  };

  return position;
}

export function getBlockByKey(blocks, blockKey) {
  for (const eachBlock of blocks) {
    if (nestable.includes(eachBlock.type)) {
      for (const childBlock of eachBlock.children) {
        if (childBlock.key === blockKey) return childBlock;
      }
    }

    if (eachBlock.key === blockKey) return eachBlock;
  }
}

export function getBlockKeyByPath(editorBlocks, path) {
  return editorBlocks[path[0]];
}

export function getInsertedBlockKey(editorBlocks) {
  const highestKey = Math.max(...editorBlocks.map((block) => block.key));
  return highestKey + 1;
}

/**
 * Extract filename from URL
 *
 * @param {string} url
 * @returns {string} name of the file
 */
function getFilenameFromUrl(url) {
  // remove trailing slash(s) if exists
  const cleanUrl = url.replace(/\/+$/, '');

  const index = cleanUrl.lastIndexOf('/') + 1;
  const filename = cleanUrl.substr(index);
  return filename;
}

/**
 * Set block.file with URL for image block if there are resources
 * otherwise just return the input blocks
 *
 * @param {object[]} blocks array of block objects
 * @param {object[]} resources array of resources
 *
 * @returns {object[]} blocks
 */
export function blocksWithResources(blocks, resources) {
  // if resources empty then simply return blocks
  if (Object.keys(resources)?.length === 0) return blocks;

  // Set image URL
  return blocks.map((block) => {
    const file = block?.file;

    const filename = isURL(file) ? getFilenameFromUrl(file) : file;

    if (block?.type === 'image' && resources[filename]) {
      block.file = resources[filename];
    }

    return block;
  });
}

export async function insertBlocks({ editor, content, resources = {}, path }) {
  if (content?.length === 0) return;

  // * with 'text' property, insertNodes does not work, so delete it from block object
  let blocks = content.map((block) => {
    delete block.text;
    return block;
  });

  // manage resources
  blocks = blocksWithResources(blocks, resources);

  // Insert the block
  await editor?.insertNodes(blocks, path);

  return '';
}

/**
 * Get formatted storage keys for INK addons to get/store data in localStorage
 * @returns {string} Auth key
 */
export async function getStorageKeys() {
  try {
    const { getINKStorageTypes } = await import('@inkcontent/inkjs');

    const storeKeys = getINKStorageTypes('ink-app-pwa');

    const appEnv = getCurrentEnvironment();

    if (appEnv === 'test' && !/:test$/.test(storeKeys.AUTH)) {
      storeKeys.AUTH += ':test';
    }

    if (appEnv === 'test' && !/:test$/.test(storeKeys.USER)) {
      storeKeys.USER += ':test';
    }

    return {
      auth: storeKeys.AUTH,
      user: storeKeys.USER,
    };
  } catch (error) {
    return error;
  }
}

export function getAuthData() {
  const accessToken = clientStorage.get('accessToken');
  const idToken = clientStorage.get('idToken');
  const refreshToken = clientStorage.get('refreshToken');
  const tokenType = clientStorage.get('tokenType') || 'Bearer';
  const expiresIn = 3600;
  const expireTime = Date.now() + expiresIn * 1000;

  return {
    authTokens: {
      access_token: accessToken,
      id_token: idToken,
      refresh_token: refreshToken,
      token_type: tokenType,
      expire_time: expireTime,
    },
  };
}

export function getLastBlockKey(allBlocks) {
  const lastBlock = allBlocks[allBlocks?.length - 1];

  return lastBlock.key + 1;
}

/**
 * Scroll into nth position child of the editor
 *
 * @param {number} position child position
 */
export function scrollIntoNthChild(position, mode = 'normal') {
  const editorElm = document.querySelector('#editor .editor-editable-area');

  const highlightedElm = document.querySelector(
    `#editor .editor-editable-area > :nth-child(${position + 1})`
  );

  let offset = null;

  if (mode === 'streaming') {
    offset = highlightedElm?.offsetTop + highlightedElm?.offsetHeight;
  } else {
    offset = highlightedElm?.offsetTop;
  }

  if (!offset) return;

  editorElm?.scrollTo({
    top: highlightedElm?.offsetTop,
    behavior: 'smooth',
  });
}

function getHighlightPathsMeta(highlight, blocks) {
  const meta = highlight?.section.map((blockKey) => {
    const { type: blockType, path, children } = getBlockByKey(blocks, blockKey);

    // list item must be string of path like '16,0', '16,1', '16,2' etc.
    if (nestable.includes(blockType)) {
      return children.map((child) => child.path.join(','));
    }

    // each block id inside section array must be string, otherwise it doesn't work
    return path.length === 1 ? `${path.toString()}` : path.join(',');
  });

  return meta.flat();
}

/**
 * Make appropriate options for highlight text and section
 *
 * @param {Object[] | number[]} highlights
 * @param {number} activeBlockKey
 * @param {string} type
 * @returns
 */
export function adaptHighlightOptions({
  highlights,
  activeBlockKey,
  type,
  blocks,
}) {
  const highlightsOptions = [];

  // common properties of highlights option
  const commonProps = {
    decorType: type,
    needPosition: false,
    activeOnCursor: false,
    highlight: true,
  };

  if (type !== 'block') {
    for (const highlight of highlights) {
      const { anchor, focus } = highlight.position;
      const { underlineColor, bgColor, color } = highlight.style;

      const { path = null } = getBlockByKey(blocks, anchor.key);

      if (!path) return;

      const option = {
        block: path,
        highlights: [
          {
            offset: anchor.offset,
            length: focus.offset - anchor.offset,
            style: {
              backgroundColor: bgColor || 'initial',
              ...(highlight?.style?.style ? highlight.style.style : {}),
              color: color || 'initial',
            },
            bgColor,
            underlineColor,
            underlineStyle: 'solid',
            ...commonProps,
            ...(highlight?.style ? highlight.style : {}),
          },
        ],
      };

      highlightsOptions.push(option);
    }
  } else {
    for (const highlight of highlights) {
      const { path: activeBlockPath } = getBlockByKey(blocks, activeBlockKey);

      const meta = getHighlightPathsMeta(highlight, blocks);

      const option = {
        block: activeBlockPath,
        highlights: [
          {
            bgColor: highlight?.style?.bgColor,
            offset: 0,
            meta,
            ...commonProps,
          },
        ],
      };

      highlightsOptions.push(option);
    }
  }

  return highlightsOptions;
}

/**
 * If provided object by `inkjs` is greater than length of the block text
 * Then return the block text length to avoid editor break in doc_setSelection method
 * @param {Object[]} blocks all blocks in the editor
 * @param {number} blockKey key of the block
 * @param {number} offset typically offset of focus object
 * @returns
 */
export const getSafeFocusOffset = (blocks, blockKey, offset) => {
  const block = getBlockByKey(blocks, blockKey);

  const length = block?.text?.length;

  return length < offset ? length : offset;
};

export function createLayerElm(id) {
  const layerElm = document.createElement('div');
  layerElm.setAttribute('id', id);
  layerElm.setAttribute(
    'style',
    'position: absolute; height: 100%; width: 100%; left: 0; top: 0;z-index: 10'
  );
  return layerElm;
}
