import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getClient } from '@redux/services/mqtt';
import {
  // setLoggedUser,
  setSelectedAvatarOption,
  setTeam,
} from '@redux/actions/user';
import { setDataInLocalStorage } from '@helpers/browser_storage';
import {
  setCustomAlert,
  setKCHistory,
  setLoading,
} from '@redux/actions/general';

const Mqtt = ({ children }) => {
  const [mqttData, setMqttData] = useState(null);
  const {
    user: authentication,
    general: { KCMQTTTopic, kcHistory },
  } = useSelector((state) => state);
  const { selectedAvatarOption, loggedUser: user, team } = authentication;
  const dispatch = useDispatch();
  const client = getClient();

  const updateInkPointsByMqtt = (points, topic, personal = false) => {
    if (topic !== 'words') {
      return;
    }
    const tempAvatarOption = { ...selectedAvatarOption };
    if (tempAvatarOption?.wallet) {
      tempAvatarOption.wallet.words.amount = points;
      setDataInLocalStorage('avatarSelectedData', tempAvatarOption);
    }
    dispatch(setSelectedAvatarOption(tempAvatarOption));
    // if (personal) {
    //   const tempUser = { ...user };
    //   tempUser.wallet?.words.amount = points;
    //   dispatch(setLoggedUser(tempUser));
    // } else {
    //   const tempAvatarOption = { ...selectedAvatarOption };
    //   if (tempAvatarOption?.wallet) {
    //     tempAvatarOption.wallet?.words.amount = points;
    //     setDataInLocalStorage('avatarSelectedData', tempAvatarOption);
    //   }
    //   dispatch(setSelectedAvatarOption(tempAvatarOption));
    // }
  };

  const updateTeamByMqtt = (teamData) => {
    const filterTeam = team.filter(({ _id }) => _id !== teamData._id);
    const getTeam = team.find(({ _id }) => _id === teamData._id);
    dispatch(setTeam([...filterTeam, { ...getTeam, title: teamData.title }]));
  };

  useEffect(() => {
    if (client && user) {
      client.on('message', (topic, message) => {
        const data = JSON.parse(message.toString());
        if (data?.data) {
          setMqttData(data);
        } else if (data?.primaryKeyword) {
          dispatch(setKCHistory([data, ...kcHistory]));
          dispatch(setLoading(false));
          dispatch(
            setCustomAlert({
              message: ' Your Content Plan is now ready for download.',
              state: true,
              type: 'success',
              position: 'bottom-center',
            })
          );
        }
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, KCMQTTTopic]);

  useEffect(() => {
    if (mqttData?.data) {
      if (mqttData?.meta?.id !== 'inkTeamUpdate') {
        updateInkPointsByMqtt(
          mqttData.data.words,
          mqttData?.meta?.id,
          selectedAvatarOption?.accountType === 'personal'
        );
      } else {
        updateTeamByMqtt(mqttData.data);
      }
      // setToppedOff(mqttData?.isIncremented && mqttData?.pointsIncremented)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttData]);

  return <>{children}</>;
};

Mqtt.propTypes = {
  children: PropTypes.node,
};

export default Mqtt;
