export const actions = {
  SET_ALERT_MSG: 'SET_ALERT_MSG',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_CUSTOM_ALERT: 'SET_CUSTOM_ALERT',
  SET_PLANS_DATA: 'SET_PLANS_DATA',
  SET_AI_RESULT_FILTER: 'SET_AI_RESULT_FILTER',
  SET_LOADING: 'SET_LOADING',
  SET_DRAWER_ACTIVE: 'SET_DRAWER_ACTIVE',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_RESULT: 'SET_RESULT',
  SET_SELECTED_TOOL: 'SET_SELECTED_TOOL',
  SET_SEARCH_NOT_FOUND: 'SET_SEARCH_NOT_FOUND',
  SET_INK_POINT_MODAL_OPEN: 'SET_INK_POINT_MODAL_OPEN',
  SET_DATA_FETCHED_FROM: 'SET_DATA_FETCHED_FROM',
  SET_INVITED_MEMBERS: 'SET_INVITED_MEMBERS',
  SET_IMPLICIT_LOGOUT: 'SET_IMPLICIT_LOGOUT',
  SET_KCMQTT_TOPIC: 'SET_KCMQTT_TOPIC',
  SET_KC_HISTORY: 'SET_KC_HISTORY',
  SET_IS_UPLOADING: 'SET_IS_UPLOADING',
  SET_PRODUCT_QUERY_PARAMS: 'SET_PRODUCT_QUERY_PARAMS',
  SET_CONTENT_PLANNER_PRICING: 'SET_CONTENT_PLANNER_PRICING',
  SET_ADDITIONAL_SEATS_KW_PRICING: 'SET_ADDITIONAL_SEATS_KW_PRICING',
  SET_IS_DOC_SAVING: 'SET_IS_DOC_SAVING',
  SET_DRAWER_OPEN: 'SET_DRAWER_OPEN',
  SET_SHOW_INSTALL_INK_BTN: 'SET_SHOW_INSTALL_INK_BTN',
  SET_IMAGE_RESULT: 'SET_IMAGE_RESULT',
  SET_IMAGE_GENERATION_LOADING: 'SET_IMAGE_GENERATION_LOADING',
  SET_IMAGE_FILTER_INPUTS: 'SET_IMAGE_FILTER_INPUTS',
  SET_GROUP_IMAGES: 'SET_GROUP_IMAGES',
  SET_SELECTED_PROMPT: 'SET_SELECTED_PROMPT',
};

export const setAlertMsg = (data) => ({
  type: actions.SET_ALERT_MSG,
  data,
});

export const setCustomAlert = (data) => ({
  type: actions.SET_CUSTOM_ALERT,
  data,
});

export const setPlans = (data) => ({
  type: actions.SET_PLANS_DATA,
  data,
});

export const setAIResultFilter = (data) => ({
  type: actions.SET_AI_RESULT_FILTER,
  data,
});

export const setLoading = (data = null) => ({
  type: actions.SET_LOADING,
  data,
});

export const setIsMobile = (data) => ({
  type: actions.SET_IS_MOBILE,
  data,
});

export const setDrawerActive = (data) => ({
  type: actions.SET_DRAWER_ACTIVE,
  data,
});

export const setModalOpen = () => ({
  type: actions.SET_MODAL_OPEN,
});

export const setInkPointModalOpen = () => ({
  type: actions.SET_INK_POINT_MODAL_OPEN,
});

export const setDataFetchedFrom = (data) => ({
  type: actions.SET_DATA_FETCHED_FROM,
  data,
});

export const setResult = (data) => ({
  type: actions.SET_RESULT,
  data,
});

export const setSearchNotFound = (data) => ({
  type: actions.SET_SEARCH_NOT_FOUND,
  data,
});

export const setSelectedTool = (data) => ({
  type: actions.SET_SELECTED_TOOL,
  data,
});

export const setInvitedMembers = (data) => ({
  type: actions.SET_INVITED_MEMBERS,
  data,
});
export const setImplicitLogout = () => ({
  type: actions.SET_IMPLICIT_LOGOUT,
});
export const setKCMQTTTopic = (data) => ({
  type: actions.SET_KCMQTT_TOPIC,
  data,
});

export const setKCHistory = (data) => ({
  type: actions.SET_KC_HISTORY,
  data,
});

export const setIsUploading = (data) => ({
  type: actions.SET_IS_UPLOADING,
  data,
});

export const setProductQueryParams = (data) => ({
  type: actions.SET_PRODUCT_QUERY_PARAMS,
  data,
});

export const setContentPlannerPricing = (data) => ({
  type: actions.SET_CONTENT_PLANNER_PRICING,
  data,
});

export const setAdditionalSeatsAndKWPricing = (data) => ({
  type: actions.SET_ADDITIONAL_SEATS_KW_PRICING,
  data,
});

export const setIsDocSaving = (data) => ({
  type: actions.SET_IS_DOC_SAVING,
  data,
});

export const setDrawerOption = (data) => ({
  type: actions.SET_DRAWER_OPEN,
  data,
});

export const setShowInstallInkBtn = (data) => ({
  type: actions.SET_SHOW_INSTALL_INK_BTN,
  data,
});

export const setImageResult = (data) => ({
  type: actions.SET_IMAGE_RESULT,
  data,
});

export const setImageGenerationLoading = (data) => ({
  type: actions.SET_IMAGE_GENERATION_LOADING,
  data,
});
export const setInputFields = (data) => ({
  type: actions.SET_IMAGE_FILTER_INPUTS,
  data,
});
export const setgroupImages = (data) => ({
  type: actions.SET_GROUP_IMAGES,
  data,
});
export const setSelectedPrompt = (data) => ({
  type: actions.SET_SELECTED_PROMPT,
  data,
});
