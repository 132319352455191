import { actions } from '@redux/actions/keywords';

const initialState = {
  list: [],
};

const keywordsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_KEYWORD_LIST:
      return { list: data };
    default:
      return state;
  }
};

export default keywordsReducer;
