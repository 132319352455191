import { request } from '@shared/verb';
import { setAllPaymentMethods } from '@redux/actions/paymentMethod';

export const getAllPaymentMethods = () => async (dispatch) => {
  try {
    const response = await request('/v2.0/payment-method/cards', null, 'get');
    dispatch(
      setAllPaymentMethods(
        response.data.data.sort((a, b) => b?.isDefault - a?.isDefault)
      )
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const makeDefaultPaymentMethod = (id, allPaymentMethods) => (dispatch) =>
  request(`/v2.0/payment-method/cards/${id}/default`, null, 'get').then(() => {
    dispatch(
      setAllPaymentMethods(
        allPaymentMethods
          .map((p) => {
            if (p._id === id) {
              return { ...p, isDefault: true };
            }
            return { ...p, isDefault: false };
          })
          .sort((a, b) => b.isDefault - a.isDefault)
      )
    );
  });

export const addPaymentMethod =
  (body, allPaymentMethods) => async (dispatch) => {
    const mappedAllPaymentMethods = allPaymentMethods.map((method) => ({
      ...method,
      isDefault: false,
    }));
    try {
      const response = await request(
        '/v2.0/payment-method/cards',
        body,
        'post'
      );
      dispatch(
        setAllPaymentMethods([
          { ...response.data.data, isDefault: true },
          ...mappedAllPaymentMethods,
        ])
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

export const deletePaymentMethod = (id, allPaymentMethods) => (dispatch) =>
  request(`/v2.0/payment-method/cards/${id}`, null, 'delete').then((res) => {
    dispatch(
      setAllPaymentMethods(allPaymentMethods.filter((p) => p._id !== id))
    );
    return res;
  });

export const confirmPayment = (
  planid,
  cardId,
  otherParams,
  userPlanId,
  couponId,
  trial = false,
  keywordsCount,
  isContentPlanner
) => {
  const apiData = {
    planId: planid,
    cardId,
    otherParams,
  };

  if (couponId) {
    apiData.coupon = couponId;
  }
  if (userPlanId) {
    apiData.userPlanId = userPlanId;
  }
  if (trial) {
    apiData.trial = trial;
  }
  if (keywordsCount) {
    apiData.keywordsCount = keywordsCount;
  }
  if (isContentPlanner) {
    apiData.isContentPlanner = isContentPlanner;
  }
  // if (userPlanId) {
  //   return request(`/v2.0/subscriptions`, {
  //     planId: planid, cardId: cardId, otherParams: otherParams, userPlanId: userPlanId, coupon: couponId
  //   }, 'post').then(res => {
  //     return res;
  //   }).catch(err => {
  //     return err.response.data;
  //   });
  // }
  console.log('api data for buy->', apiData);
  return request(`/v2.0/subscriptions`, apiData, 'post')
    .then((res) => res)
    .catch((err) => err.response.data);
};

export const confirmInvoicePayment = (invoiceId, cardId, otherParams) =>
  request(
    `/subscriptions/invoice/pay`,
    { invoiceId, cardId, otherParams },
    'post'
  )
    .then((res) => res)
    .catch((err) => err.response.data);

// export const getContentPlannerPrices=()=>{
//  return request(`/v2.0/user-plans/content-planner/prices`, null, 'get').then((res)=> res);
// }
export const buyKeywordsCredit = (defaultCardID, requirekeywords) => {
  const apiData = {
    cardId: defaultCardID,
    additionalKeywords: requirekeywords,
  };
  return request(
    `/v2.0/subscriptions/buy-keywords-credits`,
    apiData,
    'post'
  ).then((res) => res);
};

export const getCurrentPlanData = (
  subscriptionId,
  planId,
  isContentPlanner,
  keywordsCount,
  additionalSeats
) =>
  request(
    `/v2.0/subscriptions/${subscriptionId}/status?planId=${planId}&isContentPlanner=${isContentPlanner}&keywordsCount=${keywordsCount}&additionalSeats=${additionalSeats}`,
    null,
    'get'
  ).then((res) => res);

export const getNewSubscription = (subscriptionId) =>
  request(`/subscriptions/${subscriptionId}`, null, 'get').then((res) => res);

export const proratedConfirmPayment = (
  subscriptionId,
  planId,
  token,
  cost,
  cardId,
  keywordsCount,
  additionalSeats,
  otherParams
) =>
  request(
    `/subscriptions/${subscriptionId}`,
    {
      planId,
      token,
      cardId,
      cost,
      keywordsCount,
      isContentPlanner: true,
      additionalSeats,
      otherParams,
    },
    'put'
  ).then((res) => res);

export const deleteSubscription = (subId) =>
  request(`/subscriptions/${subId}`, null, 'delete').then((res) => res);

export const upgradeSubscription = (subId, data) =>
  request(`/subscriptions/${subId}`, data, 'put').then((res) => res);

export const restartSubscription = (subId) =>
  request(`/subscriptions/${subId}/restart`, null, 'get').then((res) => res);

export const cancelDowngradeAPI = (subId) =>
  request(`/subscriptions/${subId}/release/schedule`, null, 'get').then(
    (res) => res
  );

export const cancelUpgradeAPI = (subId) =>
  request(`/subscriptions/${subId}/invoice/void`, null, 'get').then(
    (res) => res
  );

export const verifyCoupon = (couponId, id) =>
  request(
    `/v2.0/coupons/validate`,
    {
      coupon: couponId,
      planId: id,
    },
    'post'
  ).then((res) => res);
export const getCoupons = () =>
  request(`/v2.0/coupons`, null, 'get').then((res) => res);
