import { useEffect, useState } from 'react';
import { authenticate, getUserDetails } from '@redux/services/user';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchToken } from '@shared/verb';
import { removeQueryParams } from '@helpers/utils';
import { setAuthStatus } from '@redux/actions/user';
import { setDataInLocalStorage } from '@helpers/browser_storage';
import { useRouter } from 'next/router';
import EmailVerifyPopup from '@components/auth/email/email.verify-popup';

let checkedBefore = false;

const AuthProvider = ({ children }) => {
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
  const authentication = useSelector((state) => state.user);
  const { team } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const router = useRouter();

  /*
   ! Not sure why it was implemented by someone, it redirects to home page when someone assign an administrator role to a user in their team
   ? That's why commenting it out
   */
  /* useEffect(() => {
      console.log('team', team);
      if (team[0]?.accountType === 'personal-team') {
        router.replace('/');
      }
    }, [team]);
  */
  const handleOnboardingRedirection = (type, res) => {
    if (window.location.pathname !== '/') {
      return;
    }

    if (
      window.location.pathname.includes('/verify/invite') ||
      window.location.pathname.includes('/welcome')
    ) {
      console.log('exclude redirection');
      return;
    }
    if (
      res.onboardingFeedback &&
      res.onboardingFeedback.onboarded === true &&
      res.userPlan.isDefault &&
      !router.asPath.includes('?billingRedirect=') &&
      type !== 'refresh'
    ) {
      router.replace('/plans');
    }
    // onboarding-work
    else if (
      !res.onboardingFeedback &&
      !router.asPath.includes('?billingRedirect=') &&
      !window.location.href.includes('input=ai-tools-widget')
    ) {
      if (
        !res.subscription &&
        !window.location.pathname.includes('/verify/invite') &&
        type !== 'refresh'
      ) {
        // router.replace('/trial');

        if (
          res?.newSignupCheck &&
          res.userPlan.isDefault &&
          router.asPath.includes('?trial=activate')
        ) {
          router.replace('/welcome-v2');
        } else {
          if (router.asPath.includes('checkout/')) return;
          console.log('here is the res1', res);
          router.replace('/plans');
        }
        // if (!res.isWidgetSignup && res?.newSignupCheck) {
        //   router.replace('/plans'); // when new onboarding is implemented
        // }
      }
    } else if (
      !router.asPath.includes('?billingRedirect=') &&
      res.onboardingFeedback &&
      res.onboardingFeedback.onboarded === false
    ) {
      console.log('here is the res2', res);

      if (res?.newSignupCheck) {
        router.replace('/welcome-v2');
      } else {
        console.log('here 3');
        router.replace('/plans');
        console.log('redirect to plans 3');
      }
    }
    // onboarding-work
  };

  useEffect(() => {
    const shouldReCheckAuthStatus = Boolean(router.query.authenticate);
    if (checkedBefore && !shouldReCheckAuthStatus) {
      return;
    }

    dispatch(setAuthStatus('deciding'));
    const urlParams = new URLSearchParams(window.location.search);
    const idToken = fetchToken();
    const code = urlParams.get('code');
    const token = urlParams.get('token');
    const referrer = urlParams.get('referrer');
    const fpr = urlParams.get('fpr');
    const refreshToken = urlParams.get('refreshToken');

    if (token && refreshToken) {
      setDataInLocalStorage('refreshToken', refreshToken);
      dispatch(getUserDetails());
    } else if (code && authentication.authStatus !== 'authenticated') {
      dispatch(authenticate(code)).then((res) => {
        dispatch(getUserDetails());
        if (fpr) {
          removeQueryParams('?fpr');
        }
        if (referrer) {
          removeQueryParams('?referrer');
        } else {
          removeQueryParams('?code');
        }
        handleOnboardingRedirection('login', res?.data);
      });
    } else if (idToken && !authentication?.loggedUser?._id) {
      dispatch(getUserDetails(true)).then((res) => {
        console.log('DETAILS', res);

        if (res?.data) {
          handleOnboardingRedirection('refresh', res.data);
        }
      });
    } else if (!idToken && !authentication?.loggedUser?._id) {
      dispatch(setAuthStatus('undecided'));
    } else {
      dispatch(setAuthStatus('authenticated'));
    }

    checkedBefore = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.authenticate]);

  // EMAIL VERIFICATION POPUP LOGIC
  useEffect(() => {
    console.log('EMAIL VERIFIED', authentication?.loggedUser?.emailVerified);
    if (
      authentication?.loggedUser &&
      authentication?.loggedUser?.emailVerified === false
    ) {
      console.log('SHOW POPUP');
      setShowVerifyEmailPopup(true);
    }
  }, [authentication?.loggedUser]);

  // activate trial for email authentication
  useEffect(() => {
    if (authentication?.authStatus !== 'authenticated') return;

    const isEmailAuth = router.asPath.includes('email_auth=true');

    const shouldActivateTrial = router.asPath.includes('trial=activate');

    if (isEmailAuth && shouldActivateTrial) {
      router.replace('/welcome-v2');
    }
  }, [router, authentication]);

  return (
    <>
      <div>{children}</div>

      {/* {showVerifyEmailPopup && (
        <EmailVerifyPopup
          email={authentication?.loggedUser?.email}
          show={showVerifyEmailPopup}
          onClose={() => setShowVerifyEmailPopup(false)}
        />
      )} */}
    </>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
