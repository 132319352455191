let key = 0;

class BlockToJsxConverter {
  constructor(block) {
    this.block = block;
    this.blockChildren = this.block?.children;
  }

  _isHeading() {
    return /^heading/.test(this.block.type);
  }

  _linkJsx(child) {
    return (
      <a href={child?.href} target='_blank' rel='noreferrer'>
        {this._childrenToJsx(child?.children)}
      </a>
    );
  }

  /**
   * Markup of child text format
   *
   * @param {Child} child
   * @returns {string} HTML markup
   */
  _textJsx = (child) => {
    let markup = child?.text;

    if (child?.bold) markup = <strong>{markup}</strong>;

    if (child?.italic) markup = <em>{markup}</em>;
    if (child?.underlined) markup = <u>{markup}</u>;

    return markup;
  };

  /**
   * Convert block's children array to JSX
   * @param {Array} children of block
   * @returns {JSX} JSX
   */
  _childrenToJsx(children) {
    const content = [];

    for (const child of children || []) {
      if (child?.type === 'link') {
        content.push(this._linkJsx(child));
      } else {
        if (child?.children?.length) {
          content.push(this._childrenToJsx(child.children));
        }

        if (child?.text) {
          content.push(this._textJsx(child));
        }
      }
    }

    return content;
  }

  /**
   * Make nested structure for list item
   *
   * @param {Block[]} parentListItems
   * @param {number} level
   *
   * @returns {Block} `list-item` block with nested children
   */
  _makeNestedListChildren = (parentListItems, level) => {
    const listItem = [];
    let lastIndex = 0;

    parentListItems.forEach((item) => {
      const currentLevel = item?.nestedLevel || 0;

      if (currentLevel === level) {
        // current level is equal to provided level means list items are sibling of others
        item.nestedChildren = [];
        listItem.push(item);
        lastIndex = listItem.length - 1;
      } else {
        // push all others item as nested children
        listItem[lastIndex]?.nestedChildren?.push(item);
      }
    });

    return listItem;
  };

  /**
   * list content getter
   */
  get listContent() {
    // safely copy this.blockChildren with deeply nested objects
    const allListItems = JSON.parse(JSON.stringify(this.blockChildren));

    return allListItems.map((item) => (
      <li>{this._childrenToJsx(item.children)}</li>
    ));
  }

  _getHeading(childJsx = null) {
    const heading = childJsx || this._childrenToJsx(this.blockChildren);

    switch (this?.block?.type) {
      case 'heading-one':
        return <h1 key={key}>{heading}</h1>;
      case 'heading-two':
        return <h2 key={key}>{heading}</h2>;
      case 'heading-three':
        return <h3 key={key}>{heading}</h3>;
      case 'heading-four':
        return <h4 key={key}>{heading}</h4>;
      default:
        return <h3 key={key}>{heading}</h3>;
    }
  }

  _getList(type = 'ul') {
    if (type === 'ul') {
      return <ul key={key}>{this.listContent}</ul>;
    }
    if (type === 'ol') {
      return <ol key={key}>{this.listContent}</ol>;
    }
  }

  _getParagraph(childJsx = null) {
    const paragraph = this._childrenToJsx(this.blockChildren);

    return <p key={key}>{childJsx || paragraph}</p>;
  }

  _getImage() {
    const url = this.block?.file;
    const alt = this.block?.alt || '';
    const size = this.block?.size || 100;
    const caption = this.block?.caption || '';

    // copy markup and styles from the editor
    return (
      <div
        class='float-none'
        style={{
          textAlign: 'left',
          borderLeft: '3px solid transparent',
          padding: '10px',
        }}
      >
        <figure
          class='editor-section block-image'
          style={{
            maxWidth: `${size}%`,
            opacity: 1,
            float: 'none',
          }}
        >
          <img src={url} alt={alt} />
          <figcaption>{caption}</figcaption>
        </figure>
      </div>
    );
  }

  _getQuote() {
    let quote = '';
    let credit = '';

    this.block?.children.forEach((child) => {
      if (child?.type === 'q') quote = this._childrenToJsx(child?.children);
      else if (child?.type === 'credit')
        credit = this._childrenToJsx(child?.children);
    });

    // copy markup and styles from the editor
    return (
      <blockquote
        class='editor-section block-quote current'
        style={{ textAlign: 'left', borderLeft: '3px solid transparent' }}
      >
        <div class='quote'>
          <span>
            <span style={{ borderBottom: '2px solid transparent' }}>
              <span>{quote}</span>
            </span>
          </span>
        </div>
        <div class='credit current'>
          <span>
            <span style={{ borderBottom: '2px solid transparent' }}>
              <span>{credit}</span>
            </span>
          </span>
        </div>
      </blockquote>
    );
  }

  _getOtherElement(childJsx) {
    switch (this.block.type) {
      case 'paragraph':
        return this._getParagraph(childJsx);
      case 'bulleted-list':
        return this._getList('ul');
      case 'numbered-list':
        return this._getList('ol');
      case 'image':
        return this._getImage();
      case 'quote':
        return this._getQuote();
      default:
        return {};
    }
  }

  getJsx(childJsx = null) {
    // to prevent react warning provide unique key to all elements
    key++;

    if (this._isHeading(this.block.type)) return this._getHeading(childJsx);
    return this._getOtherElement(childJsx);
  }
}

export default BlockToJsxConverter;
