import { actions } from '@redux/actions/paymentMethod';

const initialState = {
  paymentMethods: [],
  addPaymentModal: false,
  loading: false,
};

const paymentMethod = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_ADD_PAYMENT_MODAL:
      return { ...state, addPaymentModal: data };
    case actions.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: data };
    case actions.SET_LOADING:
      return { ...state, loading: data };
    default:
      return state;
  }
};

export default paymentMethod;
