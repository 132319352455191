const env = {
  test: 'test',
  stage: 'stage',
  prod: 'prod',
};

export function getCurrentEnvironment() {
  if (typeof window !== 'undefined') {
    switch (window?.location?.host?.split('.')[0]) {
      case 'app':
        return env.prod;

      case 'app-test':
      case 'app-editor':
        return env.test;

      case 'app-stage':
        return env.stage;

      default:
        return env.test;
    }
  }
}
