import Route from '@components/Route/Route';
import { IsMobileWidth } from '@components/util/MediaQueryUtils';
import { Box, Container } from '@mui/material';
import Image from 'next/image';
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const LogoBlankLayout = ({ children, fullHeight }) => {
  const mobileWidth = IsMobileWidth();

  const logoHeight = '79px';

  return (
    <Container
      maxWidth='lg'
      style={{
        height: fullHeight ? '100vh' : 'auto',
        minHeight: fullHeight ? '100vh' : 'auto',
      }}
    >
      <div className=' relative flex flex-col h-full'>
        <div
          className={
            mobileWidth
              ? ' w-full  flex flex-col h-full'
              : ' w-[95%] mx-auto flex flex-col h-full'
          }
        >
          <Box
            sx={{
              margin: '40px auto',
            }}
          >
            <Link href='/'>
              <button type='button'>
                <Image
                  src='/assets/ink-icon.svg'
                  alt='ink'
                  width='72px'
                  height='72px'
                />
              </button>
            </Link>
          </Box>
          <Box
            sx={
              mobileWidth
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                  }
                : {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                  }
            }
          >
            {children}
          </Box>
        </div>
      </div>
    </Container>
  );
};

export default LogoBlankLayout;

LogoBlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullHeight: PropTypes.bool,
};
