export const actions = {
  SET_AUTH_STATUS: 'SET_AUTH_STATUS',
  SET_LOGGED_USER: 'SET_LOGGED_USER',
  SET_AVATAR_OPTIONS: 'SET_AVATAR_OPTIONS',
  SET_USER_STATS: 'SET_USER_STATS',
  SET_SELECTED_AVATAR_OPTION: 'SET_SELECTED_AVATAR_OPTION',
  SET_TEAM: 'SET_TEAM',
  SET_TEAM_MEMBERS: 'SET_TEAM_MEMBERS',
  SET_USER_ACCOUNTS: 'SET_USER_ACCOUNTS',
  SET_SAVED_RESULTS: 'SET_SAVED_RESULTS',
  SET_COPIED_RESULTS: 'SET_COPIED_RESULTS',
  SET_COPIED_SAVED_RESULTS: 'SET_COPIED_SAVED_RESULTS',
  SET_CUSTOM_PLAN_ASSIGNED: 'SET_CUSTOM_PLAN_ASSIGNED',
  SET_AUTH_REFRESH_NONCE: 'SET_AUTH_REFRESH_NONCE',
};

export const setAuthStatus = (data) => ({
  type: actions.SET_AUTH_STATUS,
  data,
});

export const setLoggedUser = (data) => ({
  type: actions.SET_LOGGED_USER,
  data,
});

export const setUserStats = (data) => ({
  type: actions.SET_USER_STATS,
  data,
});

export const setAvatarOptions = (data) => ({
  type: actions.SET_AVATAR_OPTIONS,
  data,
});
export const setSelectedAvatarOption = (data) => ({
  type: actions.SET_SELECTED_AVATAR_OPTION,
  data,
});
export const setTeam = (data) => ({
  type: actions.SET_TEAM,
  data,
});
export const setTeamMembers = (data) => ({
  type: actions.SET_TEAM_MEMBERS,
  data,
});

export const setUserAccounts = (data) => ({
  type: actions.SET_USER_ACCOUNTS,
  data,
});

export const setSavedResults = (data) => ({
  type: actions.SET_SAVED_RESULTS,
  data,
});
export const setCopiedResults = (data) => ({
  type: actions.SET_COPIED_RESULTS,
  data,
});
export const setCopiedSavedResults = (data) => ({
  type: actions.SET_COPIED_SAVED_RESULTS,
  data,
});

export const setCustomPlanAssigned = (data) => ({
  type: actions.SET_CUSTOM_PLAN_ASSIGNED,
  data,
});

export const setAuthRefreshNonce = (data) => ({
  type: actions.SET_AUTH_REFRESH_NONCE,
  data,
});
