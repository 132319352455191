import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function SectionTitle({ title }) {
  return (
    <Typography
      variant='h2'
      sx={{
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '24px',
        marginBottom: '15px',
      }}
    >
      {title}
    </Typography>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionTitle;
