import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataInLocalStorage,
} from '@helpers/browser_storage';
import LoadingScreen from '@components/Loader/LoadingScreen';
import axios from 'axios';
import useHTTP from 'hooks/useHttp';
import {
  GET_ALL_PROJECTS,
  sortData,
} from '@components/project.dashboard/helper';
import { setProjects, setSelectedFolder } from '@redux/actions/project';
import { getCurrentEnvironment } from '@helpers/envUtils';

const Route = ({ children, route = null, id = null, recipeId = null }) => {
  const authentication = useSelector((state) => state.user);
  const router = useRouter();
  const [isProjectHandled, setIsProjectHandled] = useState(false);

  let pathname = route || router.pathname.replace('/', '');
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search).toString();
    if (!searchParams) {
      searchParams = null;
    }
    // let searchParamsObj = {};
    // if (searchParams?.toString()) {
    //   searchParamsObj = searchParams
    //     .toString()
    //     .split('&')
    //     .reduce((acc, curr) => {
    //       const [key, value] = curr.split('=');

    //       return { ...acc, [key]: value };
    //     }, {});
    // }

    const asPathInLC = !route && getDataFromLocalStorage('currentAsPath');
    console.log(
      `${asPathInLC || pathname}${searchParams ? `?${searchParams}` : ''}`
    );
    if (authentication?.authStatus === 'undecided') {
      if (pathname === '[tool]') {
        router.replace({
          pathname: `/login`,
          query: {
            pr: `ai-assistant`,
            // ...searchParamsObj,
          },
        });
      } else if (pathname) {
        if (id) pathname = pathname.replace('[id]', id);
        if (recipeId) pathname = pathname.replace('[recipeId]', recipeId);
        router.replace({
          pathname: `/login`,
          query: {
            pr: `${asPathInLC || pathname}${
              searchParams ? `?${searchParams}` : ''
            }`,
            // ...searchParamsObj,
          },
        });
      } else {
        router.replace({
          pathname: '/login',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication?.authStatus, id, recipeId]);

  // useEffect(() => {
  //   // console.log(
  //   //   'router',
  //   //   router.asPath,
  //   //   user?.authStatus === 'authenticated',
  //   //   user?.loggedUser?.onboardingFeedback,
  //   //   router.asPath.includes('?billingRedirect=')
  //   // );
  //   // console.log('here', authentication);
  //   // if (
  //   //   authentication.authStatus === 'authenticated' &&
  //   //   !authentication.loggedUser.onboardingFeedback &&
  //   //   !router.asPath.includes('?billingRedirect=')
  //   // ) {
  //   //   console.log(
  //   //     'look here',
  //   //     authentication.authStatus === 'authenticated',
  //   //     !authentication.loggedUser.onboardingFeedback,
  //   //     !router.asPath.includes('?billingRedirect=')
  //   //   );
  //   //   router.replace('/new-onboarding');
  //   //   // router.replace('/trial');
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authentication]);
  const {
    user: { loggedUser },
    project: { projects = [] },
  } = useSelector((state) => state);
  const appEnv = getCurrentEnvironment();

  // ? DISABLED TO EXCLUDE FROM PROD
  // because (appEnv === 'test') checking is not enough, it provides enable as true for first time

  /* const { response: data, loading } = useHTTP({
    fetcher: GET_ALL_PROJECTS,
    enable: loggedUser?.userPlan?.quota?.projectsAccess && appEnv === 'test',
  }); */

  useEffect(() => {
    if (
      ['/[tool]/history'].includes(router.pathname) &&
      !router.pathname.includes('new-onboarding')
    )
      setDataInLocalStorage('currentAsPath', router?.asPath);
  }, [router]);
  const dispatch = useDispatch();

  // ? DISABLED TO EXCLUDE FROM PROD
  /*   useEffect(() => {
    if (
      data?.data &&
      loggedUser?.userPlan?.quota?.projectsAccess &&
      appEnv === 'test'
    ) {
      dispatch(setProjects(data?.data));
      const project = getDataFromLocalStorage('selectedFolder');
      if (project?._id && data?.data.some((obj) => obj._id === project?._id)) {
        axios.defaults.headers.common['x-ink-project'] = project?._id;
        const folder = data?.data?.find((item) => item._id === project?._id);
        dispatch(setSelectedFolder(folder));
      } else {
        const [firstProject = {}] = sortData(data?.data || []);
        if (firstProject?._id) {
          axios.defaults.headers.common['x-ink-project'] = firstProject?._id;
          const folder = data?.data?.find(
            (item) => item._id === firstProject?._id
          );
          dispatch(setSelectedFolder(folder));
        }
      }
    }
    setIsProjectHandled(true);
  }, [loading, JSON.stringify(data), loggedUser]); */

  useEffect(() => () => removeDataFromLocalStorage('currentAsPath'), []);

  // ? DISABLED TO EXCLUDE project implementation FROM PROD
  /*   return (
    <div>
      {(['deciding', 'undecided'].includes(authentication?.authStatus) &&
        !isProjectHandled) ||
      (loading && !projects?.length) ? (
        <LoadingScreen />
      ) : (
        children
      )}
    </div>
  ); */

  return (
    <div>
      {['deciding', 'undecided'].includes(authentication?.authStatus) ? (
        <LoadingScreen />
      ) : (
        children
      )}
    </div>
  );
};

Route.propTypes = {
  children: PropTypes.node,
  route: PropTypes.any,
  id: PropTypes.string,
  recipeId: PropTypes.string,
};

export default Route;
