import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  // change family to Montserrat
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },

  // TODO: change theme
});

export default theme;
