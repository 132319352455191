import { actions } from '@redux/actions/paymentHistory';

const initialState = {
  paymentHistory: null,
};

const paymentHistory = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_PAYMENT_HISTORY:
      return { ...state, paymentHistory: data };
    default:
      return state;
  }
};

export default paymentHistory;
